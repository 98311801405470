export const LOGIN = 'login/LOGIN';
export const LOGIN_FAILED = 'login/LOGIN_FAILED';
export const LOGIN_INITIATED = 'login/LOGIN_INITIATED';
export const LOGIN_SUCCESS = 'login/LOGIN_SUCCESS';

export const LOGOUT = 'login/LOGOUT';
export const LOGOUT_CALL = 'login/LOGOUT_CALL'

export const REHYDRATE_REDUCER = 'REHYDRATE_REDUCER'
export const REHYDRATE_REDUCER_WITH_LOCAL_DATA = 'REHYDRATE_REDUCER_WITH_LOCAL_DATA'

export const REHYDRATE_SIDEBAR_PERMISSIONS = 'REHYDRATE_SIDEBAR_PERMISSIONS'
export const REHYDRATE_DASHBOARD_PERMISSIONS = 'REHYDRATE_DASHBOARD_PERMISSIONS'


export const CREATE_OFFER = 'dashboard/CREATE_OFFER'

export const ADD_DASHBOARD_PERMISSIONS = 'dashboard/ADD_DASHBOARD_PERMISSIONS'

export const GET_MENU_BY_ID = 'dashboard/GET_MENU_BY_ID'
export const GET_MENU_BY_ID_FAILED = 'dashboard/GET_MENU_BY_ID_FAILED';
export const GET_MENU_BY_ID_INITIATED = 'dashboard/GET_MENU_BY_ID_INITIATED';

export const ADD_SIDEBAR_PERMISSIONS = 'dashboard/ADD_SIDEBAR_PERMISSIONS'

export const FIND_DISTRIBUTOR_LIST = 'distributor/FIND_DISTRIBUTOR_LIST'

