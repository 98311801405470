import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
//import { getValue } from "../../../../common/helpers/validations";
import ReactTable from "react-table";
import { Link } from 'react-router-dom'
import { findFreezePeriodList } from "./api.js";
import { tableColumHeaders } from "./tableConstants";
import { DisappearedLoading } from 'react-loadingg';


let FreezingOfData = props => {
    const [freezingList, setFreezingList] = useState([]);
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        findFreezePeriodList().then(data => {
            setLoading(false);
            setFreezingList(data[0])
        })

    }, []);

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-12 mt-20">
                    <div className="panel panel-default">
                        <div className="panel-body">
                            <div className="panel-title text-left">
                                Freezing Of Data
                                <Link to={`/freezingOfData/addFreezingOfData/?id=`}>
                                    <button className="btn btn-info btn-add" type="button">
                                        New
                                    </button>
                                </Link>
                            </div>
                            {loading ? <DisappearedLoading color="#5f6973" /> :
                                <ReactTable
                                    data={freezingList || []}
                                    columns={tableColumHeaders()}
                                    filterable
                                    minRows={0}
                                    defaultPageSize={10}
                                    noDataText={"No Data"}
                                    showPageSizeOptions={true}
                                    defaultSorted={[{ id: "fpid", desc: true }]}
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
const mapStateToProps = state => {
};

export default FreezingOfData = connect(mapStateToProps)(FreezingOfData);


