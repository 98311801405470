import { post } from 'axios';
import { BASE_URL } from "../../../../common/helpers/urlsConstants";



export const getCustomerList = async (distId, tokenId) => {
    tokenId = sessionStorage.getItem("tokenId")
    var token = tokenId.replace(/['"]+/g, '');
    const authData = {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${token}`
        }
    };
    const url = `${BASE_URL}/customer/getCustomerDetailsByCustDistId?distId=${distId}`;
    return fetch(url, authData).then(response => response.json()).then(resp => {
        
        if (!resp) {
            return [];
        } else {
            return resp;
        }
    })
};


export const findProductList = async (id, tokenId) => {
    tokenId = sessionStorage.getItem("tokenId")
    var token = tokenId.replace(/['"]+/g, '');
    const authData = {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${token}`
        }
    };
    const url = `${BASE_URL}/product/findProductList?size=20000&companyId=${id}`;
    return fetch(url, authData).then(response => response.json()).then(resp => {
        if (!resp.content) {
            return [];
        } else {
            return resp.content;
        }
    })
};


export const findDeviationList = async (userId, tokenId) => {
    tokenId = sessionStorage.getItem("tokenId")
    var token = tokenId.replace(/['"]+/g, '');
    const authData = {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${token}`
        }
    };
    const url = `${BASE_URL}/deviation/findStDeviationList?page=0&size=20000&sort=stdId&userId=${userId}`;
    return fetch(url, authData).then(response => response.json()).then(resp => {
        if (!resp.content) {
            return [];
        } else {
            return resp.content;
        }
    })
};


export const findDeviationById = async (id, tokenId) => {
    tokenId = sessionStorage.getItem("tokenId")
    var token = tokenId.replace(/['"]+/g, '');
    const authData = {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${token}`
        }
    };
    const url = `${BASE_URL}/deviation/findStDeviationById?id=${id}`;
    return fetch(url, authData).then(response => response.json()).then(resp => {
        if (!resp) {
            return [];
        } else {
            return [resp];
        }
    })
};


export const deleteDeviationById = async (id, tokenId) => {
    tokenId = sessionStorage.getItem("tokenId")
    var token = tokenId.replace(/['"]+/g, '');
    const authData = {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${token}`
        }
    };
    const url = `${BASE_URL}/deviation/deleteStDeviationById?id=${id}`;
    return fetch(url, authData).then(response => response.json()).then(resp => {
        if (resp.status_code = 200) {
            return true;
        } else {
            return false;
        }
    })
};


export const saveOrUpdateDeviation = async (data, tokenId) => {
    tokenId = sessionStorage.getItem("tokenId")
    var token = tokenId.replace(/['"]+/g, '');
    const authData = {
        method: "POST",
        headers: {
            "Authorization": `Bearer ${token}`
        }
    };
    const url = `${BASE_URL}/deviation/saveOrUpdateStDeviation`;
    return post(url, data, authData);
};


export const fileUpload = async (file, inputJson, tokenId) => {
    tokenId = sessionStorage.getItem("tokenId")
    var token = tokenId.replace(/['"]+/g, '');
    const authData = {
        method: "POST",
        headers: {
            "Authorization": `Bearer ${token}`,
            "content-type": "multipart/form-data"
        }
    };
    const url = `${BASE_URL}/uploadFile`;

    const formData = new FormData();
    formData.append("file", file);
    formData.append("inputParamJson", inputJson);

    return post(url, formData, authData);
};


//export const getCustomerList = async distId => {
//    const response = await fetch(`${BASE_URL}/customer/getCustomerDetailsByCustDistId?distId=${distId}`);
//    const resp = await response.json();
//    if (!resp) {
//        return [];
//    } else {
//        return resp;
//    }
//};



//export const findProductList = async id => {
//    const response = await fetch(`${BASE_URL}/product/findProductList?size=20000&companyId=${id}`);
//    const resp = await response.json();
//    if (!resp) {
//        return [];
//    } else {
//        return resp;
//    }
//};


//export const findDeviationList = async userId => {
//    const response = await fetch(`${BASE_URL}/deviation/findStDeviationList?page=0&size=20000&sort=stdId&userId=${userId}`);
//    const resp = await response.json();
//    if (!resp) {
//        return [];
//    } else {
//        return resp;
//    }
//};

//export const findDeviationById = async id => {
//    const response = await fetch(`${BASE_URL}/deviation/findStDeviationById?id=${id}`);
//    const resp = await response.json();
//    if (!resp) {
//        return [];
//    } else {
//        return [resp];
//    }
//};


//export const deleteDeviationById = async id => {
//    const response = await fetch(`${BASE_URL}/deviation/deleteStDeviationById?id=${id}`);
//    const resp = await response.json();
//    if (resp.status_code = 200) {
//        return true;
//    } else {
//        return false;
//    }
//};



//export const saveOrUpdateDeviation = async data => {
//    return post(`${BASE_URL}/deviation/saveOrUpdateStDeviation`, data)
//};


//export const fileUpload = (file, inputJson) => {
//    const authData = {
//        method: "POST",
//        headers: new Headers()
//    };
//    const url = `${BASE_URL}/uploadFile`;
//    const formData = new FormData();
//    formData.append("file", file);
//    const config = {
//        headers: {
//            "content-type": "multipart/form-data"
//        }
//    };
//    formData.append("inputParamJson", inputJson);
//    return post(url, formData, config, authData);
//};

