import {
  REHYDRATE_REDUCER_WITH_LOCAL_DATA,
  REHYDRATE_REDUCER,
  REHYDRATE_DASHBOARD_PERMISSIONS
} from "../actions/actionTypes";
import { takeLatest, call, put } from "redux-saga/effects";

export function* rehydrateSaga() {
  yield takeLatest(REHYDRATE_REDUCER, workerSaga);
}

function* workerSaga(action) {
    
  try {
    yield put({
      type: REHYDRATE_REDUCER_WITH_LOCAL_DATA,
      payload: action.payload
    });
    yield put({
      type: REHYDRATE_DASHBOARD_PERMISSIONS,
      payload: action.payload.userModuleList
    });
  } catch (error) {
    //console.log("err", error);
  }
}
