import React, { Component } from "react";
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect
} from "react-router-dom";
import { connect } from "react-redux";
import { PrivateRoutes } from "./PrivateRoutes";
import PrivateRoutesWithSideBar from "./PrivateRoutesWithSideBar";

import Login from '../../pages/login';
import Dashboard from '../../pages/dashboard'
import PageNotFound from '../../pages/pageNotFound'
import Dashboard1 from '../../pages/userDashboard1'
import Dashboard2 from '../../pages/userDashboard2'
import Dashboard3 from '../../pages/userDashboard3'
import Distributor from '../../pages/sideBarNavPages/master/distributor'
import EditDistributorView from '../../pages/sideBarNavPages/master/distributor/editDistributorView'
import DistributorsCustomer from '../../pages/sideBarNavPages/master/distributorsCustomer'
import EditView from '../../pages/sideBarNavPages/master/distributorsCustomer/EditView'
import Product from '../../pages/sideBarNavPages/master/product'
import EditProductView from '../../pages/sideBarNavPages/master/product/editProductView'
import Deviation from '../../pages/sideBarNavPages/master/deviation'
import EditDeviationView from '../../pages/sideBarNavPages/master/deviation/editDeviationView'
import ProductDRP from '../../pages/sideBarNavPages/master/productDRP'
import EditProductDRPView from '../../pages/sideBarNavPages/master/productDRP/editProductDRPView'
import UploadSalesData from '../../pages/sideBarNavPages/upload/uploadSalesData/UploadSalesData'
import FileUploadStatus from '../../pages/sideBarNavPages/fileUploadStatus'
import UploadPrimarySalesData from '../../pages/sideBarNavPages/upload/uploadPrimarySalesData'
import User from '../../pages/sideBarNavPages/admin/user'
import EditUserView from '../../pages/sideBarNavPages/admin/user/editUserView'
import Ticket from '../../pages/ticket'
import EditTicketView from '../../pages/ticket/editTicketView'
import FreezingOfData from '../../pages/sideBarNavPages/admin/freezingOfData'
import EditFreezingOfDataView from '../../pages/sideBarNavPages/admin/freezingOfData/editFreezingOfDataView'
import Role from '../../pages/sideBarNavPages/admin/role'
import EditRoleView from '../../pages/sideBarNavPages/admin/role/editRoleView'
import SUMapping from '../../pages/sideBarNavPages/admin/suMapping'
import SalesReprocess from '../../pages/sideBarNavPages/admin/salesReprocess'
import { rehydrateReducer } from "../../pages/login/actionCreator"
import EditSuMappingView from "../../pages/sideBarNavPages/admin/suMapping/editSuMappingDataView"; 
//import ChangePassword from '../../pages/sideBarNavPages/master/changePassword'


class AppRouterComponent extends Component {
    render() {
        
        var isLoggedIn = this.props.isLoggedIn;
       
        if (sessionStorage.getItem("userData") != null) {
            this.props.rehydrateReducer(JSON.parse(sessionStorage.getItem("userData")));
            isLoggedIn = true;
        }
        return (
            <Router>
                <Switch>
                    <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
                    <Route exact path="/login" name="login" component={Login} />


                    {/*Support*/}

                    <PrivateRoutes exact path="/ticket" name="Ticket" component={Ticket} isLoggedIn={isLoggedIn} />
                    <PrivateRoutes exact path="/ticket/editTicket" name="Edit Ticket" component={EditTicketView} isLoggedIn={isLoggedIn} />
                    <PrivateRoutes exact path="/ticket/addTicket" name="Add Ticket" component={EditTicketView} isLoggedIn={isLoggedIn} />


                    {/*SSA Menu*/}

                    <PrivateRoutes
                        exact
                        path="/dashboard"
                        component={Dashboard}
                        isLoggedIn={isLoggedIn}
                    />

                    <PrivateRoutesWithSideBar
                        exact
                        path="/dashboard1"
                        name="dashboard1"
                        component={Dashboard1}
                        isLoggedIn={isLoggedIn}
                    />

                    <PrivateRoutesWithSideBar
                        exact
                        path="/dashboardSE"
                        name="Dashboard2"
                        component={Dashboard2}
                        isLoggedIn={isLoggedIn}
                    />

                    <PrivateRoutesWithSideBar
                        exact
                        path="/dashboard3"
                        name="Dashboard3"
                        component={Dashboard3}
                        isLoggedIn={isLoggedIn}
                    />

                    <PrivateRoutesWithSideBar
                        exact
                        path="/distributor"
                        name="Distributor"
                        component={Distributor}
                        isLoggedIn={isLoggedIn}
                    />

                    <PrivateRoutesWithSideBar
                        path="/distributor/editDistributor"
                        name="Edit Distributor"
                        component={EditDistributorView}
                        isLoggedIn={isLoggedIn}
                    />

                    <PrivateRoutesWithSideBar
                        path="/distributor/addDistributor"
                        name="Add Distributor"
                        component={EditDistributorView}
                        isLoggedIn={isLoggedIn}
                    />

                    <PrivateRoutesWithSideBar
                        exact
                        path="/distributorscustomer"
                        name="Distributors Customer"
                        component={DistributorsCustomer}
                        isLoggedIn={isLoggedIn}
                    />

                    <PrivateRoutesWithSideBar
                        path="/distributorscustomer/editCustomer"
                        name="Edit Customer"
                        component={EditView}
                        isLoggedIn={isLoggedIn}
                    />
                    <PrivateRoutesWithSideBar
                        path="/distributorscustomer/addCustomer"
                        name="Add Customer"
                        component={EditView}
                        isLoggedIn={isLoggedIn}
                    />

                    <PrivateRoutesWithSideBar
                        exact
                        path="/product"
                        name="Product"
                        component={Product}
                        isLoggedIn={isLoggedIn}
                    />

                    <PrivateRoutesWithSideBar
                        path="/product/editProduct"
                        name="Edit Product"
                        component={EditProductView}
                        isLoggedIn={isLoggedIn}
                    />

                    <PrivateRoutesWithSideBar
                        path="/product/addProduct"
                        name="Add Product"
                        component={EditProductView}
                        isLoggedIn={isLoggedIn}
                    />

                    <PrivateRoutesWithSideBar
                        exact
                        path="/deviation"
                        name="Deviation"
                        component={Deviation}
                        isLoggedIn={isLoggedIn}
                    />

                    <PrivateRoutesWithSideBar
                        path="/deviation/editDeviation"
                        name="Edit Deviation"
                        component={EditDeviationView}
                        isLoggedIn={isLoggedIn}
                    />

                    <PrivateRoutesWithSideBar
                        path="/deviation/addDeviation"
                        name="Add Deviation"
                        component={EditDeviationView}
                        isLoggedIn={isLoggedIn}
                    />

                    <PrivateRoutesWithSideBar
                        path="/productDRP"
                        name="ProductDRP"
                        component={ProductDRP}
                        isLoggedIn={isLoggedIn}
                    />

                    <PrivateRoutesWithSideBar
                        path="/editProductDRP"
                        name="Edit ProductDRP"
                        component={EditProductDRPView}
                        isLoggedIn={isLoggedIn}
                    />

                    <PrivateRoutesWithSideBar
                        path="/addProductDRP"
                        name="Add ProductDRP"
                        component={EditProductDRPView}
                        isLoggedIn={isLoggedIn}
                    />


                    <PrivateRoutesWithSideBar
                        exact
                        path="/uploadedSalesData"
                        name="Upload Sales Data"
                        component={UploadSalesData}
                        isLoggedIn={isLoggedIn}
                    />

                    <PrivateRoutesWithSideBar
                        exact
                        path="/uploaded-dist-product-purchase-data"
                        name="Upload Primary Sales Data"
                        component={UploadPrimarySalesData}
                        isLoggedIn={isLoggedIn}
                    />

                    <PrivateRoutesWithSideBar
                        path="/fileUploadStatus"
                        name="File Upload Status"
                        component={FileUploadStatus}
                        isLoggedIn={isLoggedIn}
                    />


                    {/*Admin Menu*/}


                    <PrivateRoutesWithSideBar
                        exact
                        path="/SalesReprocess"
                        component={SalesReprocess}
                        isLoggedIn={isLoggedIn}
                    />

                    <PrivateRoutesWithSideBar
                        exact
                        path="/FreezinfData"
                        component={FreezingOfData}
                        isLoggedIn={isLoggedIn}
                    />

                    <PrivateRoutesWithSideBar
                        path="/freezingOfData/editFreezingOfData"
                        name="Edit freezingOfData"
                        component={EditFreezingOfDataView}
                        isLoggedIn={isLoggedIn}
                    />

                    <PrivateRoutesWithSideBar
                        path="/freezingOfData/addFreezingOfData"
                        name="Add FreezingOfData"
                        component={EditFreezingOfDataView}
                        isLoggedIn={isLoggedIn}
                    />


                    <PrivateRoutesWithSideBar
                        exact
                        path="/Cust-Egg-SU-mapping"
                        component={SUMapping}
                        isLoggedIn={isLoggedIn}
                    />

                    <PrivateRoutesWithSideBar
                        exact
                        path="/Cust-Egg-SU-mapping/editSuMappingData"
                        component={EditSuMappingView}
                        isLoggedIn={isLoggedIn}
                    />

                    <PrivateRoutesWithSideBar
                        exact
                        path="/Cust-Egg-SU-mapping/addSuMappingData"
                        component={EditSuMappingView}
                        isLoggedIn={isLoggedIn}
                    />
                    
                    <PrivateRoutesWithSideBar
                        exact
                        path="/Role"
                        component={Role}
                        isLoggedIn={isLoggedIn}
                    />
                    <PrivateRoutesWithSideBar
                        path="/role/editRole"
                        name="Edit Role"
                        component={EditRoleView}
                        isLoggedIn={isLoggedIn}
                    />

                    <PrivateRoutesWithSideBar
                        exact
                        path="/user"
                        name="User"
                        component={User}
                        isLoggedIn={isLoggedIn}
                    />

                    <PrivateRoutesWithSideBar
                        path="/user/editUser"
                        name="Edit User"
                        component={EditUserView}
                        isLoggedIn={isLoggedIn}
                    />

                    <PrivateRoutesWithSideBar
                        path="/user/addUser"
                        name="Add User"
                        component={EditUserView}
                        isLoggedIn={isLoggedIn}
                    />

                    <Route exact path="/dashboard" component={Dashboard} />
                    <Route path="/*" name="pageNotFound" component={PageNotFound} />
                </Switch>
            </Router>
        );
    }
}

const mapStateToprops = state => {
    return {
        isLoggedIn: state.loginReducer.isLoggedIn
    };
};

const mapDispatchToProps = dispatch => {
    return {
        rehydrateReducer: values => dispatch(rehydrateReducer(values))
    };
};

export const AppRouter = connect(mapStateToprops, mapDispatchToProps)(AppRouterComponent);
