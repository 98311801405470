import React from 'react';
//import { getValue } from '../../common/helpers/validations';
import { connect } from "react-redux";

let PageNotFound=(props)=> {

    function jumpToHome() {
        props.history.push('/')
    }
  return (
    <div>
        <h1>Page not found</h1>
        <button type='button' onClick={ jumpToHome} >Go to Home</button>
    </div>
  );
}

export default PageNotFound = connect(
  null
)(PageNotFound);
