import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from 'redux-saga'
import { createLogger } from 'redux-logger'

import { rootReducer } from './rootReducer'
import { rootSaga } from './rootSaga';


export const configureStore = () => {
    const logger = createLogger({
        collapsed :true
      });
    const sagaMiddleware = createSagaMiddleware();
    const store = createStore(rootReducer, (applyMiddleware(sagaMiddleware,logger)))
    sagaMiddleware.run(rootSaga);
    store.subscribe(() => {
        // console.log(store.getState())
    })
return store
}


