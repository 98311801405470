import { GET_MENU_BY_ID } from "../../common/actions/actionTypes";

export function getMenuByUsrIdAndModuleId(pageId, id, history) {
    
    return {
        type: GET_MENU_BY_ID,
        payload: { pageId, id, history }
    };
}


