
export function required(name, value) {
    //
    //console.log("fieldvalue", name);
    //console.log("value", value);
    let error;
    if (value === '' || value === null || value === 0 || value === undefined) {
        error = name + ' is required';
    }
    return error;
}

export function requiredField(name, value) {
    let error;
   // console.log("fieldvalue", name);
   // console.log("value", value);
    if (value === '' || value === null || value === 0 ) {
        error = name + ' is required';
    }
    return error;
}


export function validateEmail(name, value) {
    let errorMessage;

    if (value === '' || value === null || value.length === 0) {
        errorMessage = name + ' is required';
        return errorMessage;
    }
    else {
    }
};


export function email(value) {
    let errorMessage;
    if (!value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) && value !== '' && value !== null) {
        errorMessage = 'Invalid Email Address';
        return errorMessage;
    }
    else {
    }
};

export function validatePhone(value) {
    let errorMessage;
    if (!value.match(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/i) && value !== '' && value !== null) {
        errorMessage = 'Invalid Phone Number';
        return errorMessage;
    }
    else {
    }
};


export function validatePinCode(name, value) {
    let errorMessage;
    var pattern = /^\d{6}$/;
    //console.log("field", value);
    if (value === '' || value === null || value.length === 0) {
        errorMessage = name + ' is required';
        return errorMessage;
    }
    else if (value.length > 6) {
        errorMessage = 'Pincode must be 6 digits only.';
        return errorMessage;
    }
    else if (!pattern.test(value) && value.length !== 0) {
        errorMessage = 'Invalid PinCode';
        return errorMessage;
    }
    else {
    }
};

export function validateNewPassword(value) {
    
    let errorMessage;
    if (value.length === 0) {
        errorMessage = "Please enter new password.";
        return errorMessage;
    }
    else if (!value.match("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})")) {
        errorMessage = "Password must contains 6 characters or more,1 lowercase,1 uppercase and at least 1 number."
        return errorMessage;
    }
    else {

    }
};

export function validatePassword(value) {
    let errorMessage;
    if (value.length === 0) {
        errorMessage = "Please enter confirm password.";
        return errorMessage;
    }
};

export const getValue = (o, p) =>
    p.reduce((xs, x) => (xs && xs[x] ? xs[x] : null), o);