import React from "react";
import { Provider } from 'react-redux';

import { AppRouter } from "./common/routes/AppRouter";
import { configureStore } from "./common/store/configureStore";

import Header from "./common/components/stateLessComponents/header/";
import ErrorBoundary from "./ErrorBoundary";

const store = configureStore();

function App() {
  return (
    <div className="App">
      <ErrorBoundary>
        <Provider store={store}>

          <AppRouter />
        </Provider>
      </ErrorBoundary>
    </div>
  );
}

export default App;
