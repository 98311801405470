import React, { Suspense, useState, useEffect } from "react";
import { connect } from "react-redux";
import { getValue } from "../../../../common/helpers/validations";
import { findCustomerList, findCustomerById } from "./api";
import ReactTable from "react-table";
import { Link } from 'react-router-dom'
import { BASE_URL } from "../../../../common/helpers/urlsConstants";
import { downloadFile } from "../../../../common/helpers/fileDownload";
//import APIService from "../../../../services/api";
import { tableColumHeaders } from "./tableConstants";
import EditView from "./EditView";
import { findDistributorListAPI } from "../../../../services/api";
import CustomerUpload from "./customerUpload";
import { DisappearedLoading } from 'react-loadingg';



let DistributorsCustomer = props => {

    let { userId } = props;
    const [customers, setCustomerList] = useState([]);
    const [distributorList, setDistributorList] = useState(null);
    const [editViewCustomerId, setEditViewCustomerId] = useState(false);
    const [disableSsid, setDisableSsid] = useState(false);

    const [show, setShow] = useState(false);
    const openModal = () => setShow(true);
    const closeModal = () => setShow(false);
    const [loading, setLoading] = useState(true)

    useEffect(() => {

        findCustomerList(userId).then(data => {

            setLoading(false);
            setCustomerList(data);
        });

        findDistributorListAPI(userId).then(data => {

            let newDistArray = data.map(i => {
                let option = { key: i.distId, value: i.distDistName }
                return option
            })
            setDistributorList(newDistArray);
        })
    }, []);


    return (

        <div className="container-fluid">
            <div className="row">
                <div className="col-md-12 mt-20">
                    <div className="panel panel-default">
                        <div className="panel-body">
                            <div className="panel-title text-left">
                                Distributors Customer
                                 <button className="btn btn-info btn-upload" onClick={openModal} type="button" >Upload</button>
                                <CustomerUpload closeModal={closeModal} show={show} />
                                <button className="btn btn-info btn-download" type="button">
                                    <a onClick={() => downloadFile(`${BASE_URL}/customer/dowloadCustomerReport?userId=${props.userId}`, "Customer.xlsx")} style={{
                                        color: "inherit", textDecoration: "none",
                                    }} >Download</a>
                                </button>


                                {props.sideBarMenuOptions.length !== 0 ?
                                    props.sideBarMenuOptions.Master.map((child, i) => (
                                        child.pageName == "Add New Customer" ?
                                            child.rpAdd == 1 ?
                                                <Link to={`/distributorscustomer/addCustomer/?id=`}>
                                                    <button className="btn btn-info btn-add"> New
                                    </button>
                                                </Link>
                                                :
                                                <Link>
                                                    <button className="btn btn-info btn-add disabled"> New
                                    </button>
                                                </Link>
                                            : ""
                                    ))
                                    : ("")
                                }
                            </div>

                            {loading ? <DisappearedLoading color="#5f6973" /> :
                                <ReactTable
                                    data={customers || []}
                                    columns={tableColumHeaders(setEditViewCustomerId, userId, setCustomerList, props.sideBarMenuOptions)}
                                    filterable
                                    minRows={0}
                                    defaultPageSize={10}
                                    noDataText={"No Data"}
                                    showPageSizeOptions={true}
                                    defaultFilterMethod={filterCaseInsensitive}
                                    defaultSorted={[{ id: "custId", desc: true }]}
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
const mapStateToProps = state => {
    return {
        userId: getValue(state, ["loginReducer", "userId"]),
        sideBarMenuOptions: state.permissionsReducer.sideBarOptions,
    };
};


function filterCaseInsensitive(filter, row) {
    const id = filter.pivotId || filter.id;
    return (
        row[id] !== undefined ?
            String(row[id].toLowerCase()).indexOf(filter.value.toLowerCase()) > -1
            :
            true
    );
}

export default DistributorsCustomer = connect(mapStateToProps)(
    DistributorsCustomer
);
