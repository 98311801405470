import React from "react";
import { Link } from 'react-router-dom'
import { deleteDeviationById, findDeviationList } from "./api";
import moment from 'moment';

export const tableColumHeaders = (userId, setDeviationList, sideBarMenuOptions) => {
    function onDelete(data) {

        deleteDeviationById(data).then(resp => {
            if (resp) {
                alert(`Product Deviation ${data} Deleted `);
                findDeviationList(userId).then(data => {
                    setDeviationList(data.content);

                });
            }
        })
    }

    return [
        {
            Header: "SSA ID",
            accessor: "stdId",
            Cell: e => <a href="/deviation/editDeviation"> {e.value} </a>,
            sortable: true,
            filterable: true,
            style: {
                textAlign: "right"
            },

            Cell: props => {
                return (
                    sideBarMenuOptions.length !== 0 ?
                        sideBarMenuOptions.Master.map((child, i) => (
                            child.pageName == "Product Deviation" ?
                                <div className="text-center">
                                    <Link
                                        className={'mr-3 btn-edit'}
                                        style={{ color: "#000000", textDecoration: "underline" }}
                                        to={`/deviation/editDeviation/?id=${(props && props.original) ? props.original.stdId : 0}&read=true`}
                                    >
                                        {props.value}
                                    </Link>
                                </div> : ""
                        )
                        ) : ""

                );
            },

            maxWidth: 80
        },
        {
            Header: "Dist IDH",
            accessor: "distIDH",
            sortable: true,
            filterable: true,
            style: {
                textAlign: "right"
            },
            maxWidth: 80
        },
        {
            Header: "Dist Name",
            accessor: "distDistName",
            sortable: true,
            filterable: true,
            style: {
                textAlign: "left"
            },
            maxWidth: 300
        },
        {
            Header: "Customer IDH",
            accessor: "customerIDH",
            sortable: true,
            filterable: true,
            style: {
                textAlign: "right"
            },
            maxWidth: 80
        },
        {
            Header: "Cust Name",
            accessor: "custName",
            sortable: true,
            filterable: true,
            style: {
                textAlign: "left"
            },
            maxWidth: 300
        },
        {
            Header: "Product IDH",
            accessor: "productIDH",
            sortable: true,
            filterable: true,
            style: {
                textAlign: "right"
            },
            maxWidth: 80
        },
        {
            Header: "Product Name",
            accessor: "productName",
            sortable: true,
            filterable: true,
            style: {
                textAlign: "left"
            },
            maxWidth: 300
        },
        {
            Header: "Value",
            accessor: "stdValue",
            sortable: true,
            filterable: true,
            style: {
                textAlign: "right"
            },
            maxWidth: 70
        },
        {
            Header: "From Date",
            accessor: "stdFromDt",
            sortable: true,
            filterable: true,
            style: {
                textAlign: "center"
            },
            maxWidth: 200,
            Cell: props => {
                return (
                    moment(props.original.stdFromDt).format('DD/MM/YYYY')
                )
            }

        },
        {
            Header: "To Date",
            accessor: "stdToDt",
            sortable: true,
            filterable: true,
            style: {
                textAlign: "center"
            },
            maxWidth: 200,
            Cell: props => {

                return (
                    moment(props.original.stdToDt).format('DD/MM/YYYY')
                )
            }
        },
        {
            Header: "Action",
            sortable: false,
            filterable: false,
            maxWidth: 120,
            Cell: props => {
                return (
                    sideBarMenuOptions.length !== 0 ?
                        sideBarMenuOptions.Master.map((child, i) => (
                            //console.log("rootSubmenuKeys" + rootSubmenuKeys);
                            child.pageName == "Product Deviation" ?
                                <div className="text-center">
                                    {child.rpEdit == 1 ?
                                        <Link
                                            className={'mr-3 btn-edit'}
                                            style={{ color: "#000000" }}
                                            to={`/deviation/editDeviation/?id=${(props && props.original) ? props.original.stdId : 0}&read=false`}

                                        >
                                            <span className="glyphicon glyphicon-edit"></span>
                                        </Link>
                                        :
                                        <Link
                                            className={'mr-3 btn-edit'}
                                            style={{ color: "#000000" }}
                                        >
                                            <span className="glyphicon glyphicon-edit disabled"></span>
                                        </Link>
                                    }

                                    {child.rpDelete == 1 ?
                                        <Link
                                            className="btn-delete"
                                            style={{ color: "#000000" }}
                                            onClick={() => onDelete(props.original.stdId)}
                                            to="#">
                                            <span className="glyphicon glyphicon-trash"></span>
                                        </Link>
                                        :
                                        <Link
                                            className="btn-delete"
                                            style={{ color: "#000000" }}
                                            to="#">
                                            <span className="glyphicon glyphicon-trash disabled"></span>
                                        </Link>
                                    }
                                </div> : ""
                        )
                        ) : ""
                );

                //};

                //if (rootSubmenuKeys != null && rootSubmenuKeys == 0) {

                //return (
                //    <div className="text-center">

                //        <Link
                //            className="btn-delete"
                //            style={{ color: "#000000" }}
                //            onClick={() => onDelete(props.original.distId)}
                //            to="#">
                //            <span className="glyphicon glyphicon-trash"></span>
                //        </Link>
                //    </div>
                //)
                //};

            }
            //Cell: props => {

            //    return (
            //        <div className="text-center">

            //            <Link
            //                className={'mr-3 btn-edit'}
            //                style={{ color: "#000000" }}
            //                to={`/deviation/editDeviation/?id=${(props && props.original) ? props.original.stdId : 0}`}

            //            >
            //                <span className="glyphicon glyphicon-edit"></span>
            //            </Link>
            //            <Link
            //                className="btn-delete"
            //                style={{ color: "#000000" }}
            //                onClick={() => onDelete(props.original.stdId)}
            //                to="#">
            //                <span className="glyphicon glyphicon-trash"></span>
            //            </Link>
            //        </div>
            //    );
            //}
        }
    ];
};


