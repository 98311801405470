import React, { useEffect, useState } from "react";
import { Formik, Field } from "formik";
import { connect } from "react-redux";
import { InputBox } from "../../common/components/InputBox";
import ForgotPassword from "../../pages/sideBarNavPages/master/forgotPassword";
import { required, validateEmail, getValue } from "../../common/helpers/validations";
import { loginFailed, doLogin, rehydrateReducer } from "./actionCreator";
import sha256 from "../../common/helpers/sha";
import { Link } from 'react-router-dom'





let Login = props => {

    useEffect(() => {
        //if (sessionStorage.getItem("userData") != null) {
        //    props.rehydrateReducer(JSON.parse(sessionStorage.getItem("userData")));
        //    props.history.push("/dashboard");
        //}
    }, []);

    const { errorMsg } = props;
    const [show, setShow] = useState(false);

    const openModal = () => setShow(true);
    const closeModal = () => setShow(false);



    return (
        <div className="container-fluid">
            <div className="row">

                <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12 Desktop-form ">
                    <div className="login-form">
                        <Formik
                            initialValues={{
                                // email : "", password :"" //! comment this for development and uncomment below code
                                email: "",
                                password: ""
                            }}
                            onSubmit={values => {
                                values.password = sha256(values.password);
                                props.doLogin(values, props.history);
                                if (props.loginFailed) {
                                    values.email = "";
                                    values.password = ""
                                }
                            }}>
 

                            {({ handleSubmit }) => (
                                <form onSubmit={handleSubmit} onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        handleSubmit();
                                    }
                                }}>
                                    <div className="top">
                                        <img
                                            src="img/henkel-logo.png"
                                            alt="icon"
                                            className="icon"
                                            style={{ width: "auto" }}
                                        />
                                        <h4>Please Enter Credentials To Login</h4>
                                    </div>
                                    <div className="form-area">
                                        <div className="group">
                                            <label className="form_label">Login Id</label>
                                            <Field
                                                name="email"
                                                component={InputBox}
                                                type={"email"}
                                                validate={validateEmail.bind(this, 'LoginId')}
                                                placeholder={"Login Id"}
                                            />

                                            <label className="form_label" style={{ marginTop: "20px" }}>Password</label>
                                            <Field
                                                name="password"
                                                component={InputBox}
                                                type={"password"}
                                                placeholder={"Password"}
                                                validate={required.bind(this, 'Password')}
                                            />

                                        </div>
                                        {/* <div className="checkbox checkbox-primary"> <input id="checkbox101" type="checkbox" onChange={() => {}} />  <label htmlFor="checkbox101"> Remember Me</label> </div> */}

                                        <button type="submit" className="btn btn-danger btn-block"> LOGIN </button>
                                        <Link to="#" onClick={openModal} style={{ float: "right" }}>
                                            Forgot password
                                        </Link>
                                        <ForgotPassword closeModal={closeModal} show={show} />
                                        {/*<a href="#" style={{ float: "right" }}> Forgot password  </a>*/}
                                        <p className="text-danger" style={{ paddingTop: "20px" }}>{errorMsg && errorMsg}</p>
                                    </div>

                                </form>
                            )}
                        </Formik>
                    </div>
                </div>

                <div className="col-lg-9 col-md-12 col-sm-12 col-xs-12 footer-links">
                    <img src='img/login-bg.jpg' style={{ height: "755px", width: "100%" }} alt='loginIMG'></img>
                    {/* <div className="desktop-image"></div>*/}
                </div>

            </div>
        </div >
    );
};
const mapStateToProps = state => {
    return {
        errorMsg: getValue(state, ["loginReducer", "message"])
    };
};

const mapDispatchToProps = dispatch => {
    return {
        doLogin: (values, history) => dispatch(doLogin(values, history)),
        rehydrateReducer: values => dispatch(rehydrateReducer(values)),
        loginFailed: () => dispatch(loginFailed()),
    };
};

export default Login = connect(mapStateToProps, mapDispatchToProps)(Login);
