import React, { useState, useRef, useEffect } from "react";
import { Modal } from 'react-bootstrap';
import '../../App.css'
import { connect } from "react-redux";
import { getValue } from "../../common/helpers/validations";
import "flexmonster/theme/orange/flexmonster.min.css";
import * as FlexmonsterReact from 'react-flexmonster/hooks';
import { DatePicker } from "antd";
import moment from "moment";
import { Chart_URL, License_key } from "../../common/helpers/urlsConstants";
import FlexReport from "../../common/components/FlexReport";

var flex1 = null;
var flex2 = null;
var flex3 = null;
var flex4 = null;
var flex5 = null;
var flex6 = null;
let selectedDate;

let Dashboard1 = (props) => {

    const ref1 = useRef();
    const ref2 = useRef();
    const ref3 = useRef();
    const ref4 = useRef();
    const ref5 = useRef();
    const ref6 = useRef();

  var tokenId = sessionStorage.getItem("tokenId");
  var token = tokenId.replace(/['"]+/g, "");

    useEffect(() => {
        flex1 = ref1.current.flexmonster();
        flex2 = ref2.current.flexmonster();
        flex3 = ref3.current.flexmonster();
        flex4 = ref4.current.flexmonster();
        flex5 = ref5.current.flexmonster();
        flex6 = ref6.current.flexmonster(); 
  }, []);

    let { userId, userType } = props;
    const { MonthPicker } = DatePicker;
    let inputJson = encodeURI(JSON.stringify({ "userId": userId, "reportDate": moment().subtract(1, 'months').format('yyyyMMDD'), "userType": userType }));

    const onReportComplete = () => {
        if (flex1 === null) {
            //flex1 = ref1.current.flexmonster();
        }
        if (flex2 === null) {
            //flex2 = ref2.current.flexmonster();
        }
        if (flex3 === null) {
            // flex3 = ref3.current.flexmonster();
        }
        if (flex4 === null) {
            //flex4 = ref4.current.flexmonster();
        }
        if (flex5 === null) {
            //flex5 = ref5.current.flexmonster();
        }
        if (flex6 === null) {
            // flex6 = ref6.current.flexmonster();
        }
    }
    var SSD2 = {
        dataSource: {
            dataSourceType: "json",
            filename: Chart_URL + "?chName=SSD&chScreen=dashboard2&chartInputJson=" + inputJson,
            requestHeader: {
                "Authorization": `Bearer ${token}`
            }
        },

        "slice": {
            "rows": [
                {
                    "uniqueName": "cust_add3"
                },
                {
                    "uniqueName": "cust_add2"
                },
                {
                    "uniqueName": "cust_add1"
                },
                {
                    "uniqueName": "sd_product_name"
                },
                {
                    "uniqueName": "cust_area"
                },
                {
                    "uniqueName": "sd_cust_code"
                },
                {
                    "uniqueName": "cust_city"
                },
                {
                    "uniqueName": "cy_1"
                },
                {
                    "uniqueName": "cy_1_qty"
                },
                {
                    "uniqueName": "cy_2"
                },
                {
                    "uniqueName": "cy_2_qty"
                },
                {
                    "uniqueName": "decval"
                },
                {
                    "uniqueName": "decqty"
                },
                {
                    "uniqueName": "apr"
                },
                {
                    "uniqueName": "aprqty"
                },
                {
                    "uniqueName": "aug"
                },
                {
                    "uniqueName": "augqty"
                },
                {
                    "uniqueName": "cust_pincode"
                },
                {
                    "uniqueName": "deviation"
                },
                {
                    "uniqueName": "distidh"
                },
                {
                    "uniqueName": "distname"
                },
                {
                    "uniqueName": "distributor_engineer"
                },
                {
                    "uniqueName": "end_customer_name"
                },
                {
                    "uniqueName": "feb"
                },
                {
                    "uniqueName": "febqty"
                },
                {
                    "uniqueName": "henkel_engineer"
                },
                {
                    "uniqueName": "jan"
                },
                {
                    "uniqueName": "janqty"
                },
                {
                    "uniqueName": "jul"
                },
                {
                    "uniqueName": "julqty"
                },
                {
                    "uniqueName": "jun"
                },
                {
                    "uniqueName": "junqty"
                },
                {
                    "uniqueName": "manager"
                },
                {
                    "uniqueName": "mar"
                },
                {
                    "uniqueName": "marqty"
                },
                {
                    "uniqueName": "may"
                },
                {
                    "uniqueName": "mayqty"
                },
                {
                    "uniqueName": "nov"
                },
                {
                    "uniqueName": "novqty"
                },
                {
                    "uniqueName": "octqty"
                },
                {
                    "uniqueName": "octval"
                },
                {
                    "uniqueName": "price"
                },
                {
                    "uniqueName": "sd_product_idh"
                },
                {
                    "uniqueName": "segment"
                },
                {
                    "uniqueName": "mktsegment"
                },
                {
                    "uniqueName": "mktsubsegment"
                },
                {
                    "uniqueName": "sep"
                },
                {
                    "uniqueName": "sepqty"
                },
                {
                    "uniqueName": "state"
                },
                {
                    "uniqueName": "su"
                },
                {
                    "uniqueName": "subsegment"
                },
                {
                    "uniqueName": "jandp"
                },
                {
                    "uniqueName": "febdp"
                },
                {
                    "uniqueName": "mardp"
                },
                {
                    "uniqueName": "aprdp"
                },
                {
                    "uniqueName": "maydp"
                },
                {
                    "uniqueName": "jundp"
                },
                {
                    "uniqueName": "juldp"
                },
                {
                    "uniqueName": "augdp"
                },
                {
                    "uniqueName": "sepdp"
                },
                {
                    "uniqueName": "octdp"
                },
                {
                    "uniqueName": "novdp"
                },
                {
                    "uniqueName": "decdp"
                },
                {
                    "uniqueName": "ytd_qty"
                }
            ],
            "flatOrder": [
                "manager",
                "henkel_engineer",
                "distidh",
                "distname",
                "end_customer_name",
                "sd_cust_code",
                "distributor_engineer",
                "su",
                "sd_product_idh",
                "sd_product_name",
                "deviation",
                "janqty",
                "jandp",
                "jan",
                "febqty",
                "febdp",
                "feb",
                "marqty",
                "mardp",
                "mar",
                "aprqty",
                "aprdp",
                "apr",
                "mayqty",
                "maydp",
                "may",
                "junqty",
                "jundp",
                "jun",
                "julqty",
                "juldp",
                "jul",
                "augqty",
                "augdp",
                "aug",
                "sepqty",
                "sepdp",
                "sep",
                "octqty",
                "octdp",
                "octval",
                "novqty",
                "novdp",
                "nov",
                "decqty",
                "decdp",
                "decval",
                "ytd_qty",
                "price",
                "cy_1_qty",
                "cy_1",
                "cy_2_qty",
                "cy_2",
                "cust_add1",
                "cust_add2",
                "cust_add3",
                "cust_area",
                "cust_city",
                "state",
                "cust_pincode",
                "segment",
                "subsegment",
                "mktsegment",
                "mktsubsegment" 
            ]
        },
        "options": {
            "grid": {
                "type": "flat",
                "showTotals": "off",
                "showGrandTotals": "off"
            },
            "configuratorButton": false,
            "showAggregationLabels": false

        }
    }

    var salesColChartReport = {
        dataSource: {
            dataSourceType: "json",
            filename: Chart_URL + "?chName=SalesColChart&chScreen=dashboard1&chartInputJson=" + inputJson,
            requestHeader: {
                "Authorization": `Bearer ${token}`
            }
        },

        "slice": {
            "reportFilters": [
                {
                    "uniqueName": "distname",
                    "caption": "Distributor"
                }
            ],
            "rows": [
                {
                    "uniqueName": "invmonth",
                    "caption": "Months"
                }
            ],
            "columns": [
                {
                    "uniqueName": "[Measures]",
                    "caption": "Amount"
                },
                {
                    "uniqueName": "amounttype",
                    "caption": "SSD / NSE"
                }
            ],
            "measures": [
                {
                    "uniqueName": "amount",
                    "aggregation": "sum",
                    "caption": "Amount"
                }
            ],
            "sorting": {
                "row": {
                    "type": "asc",
                    "tuple": [
                        "invmonth.[apr-20]"
                    ],
                    "measure": {
                        "uniqueName": "amount",
                        "aggregation": "sum",
                        "format": "-105hdiggbplb00"
                    }
                }
            }
        },
        "options": {
            "viewType": "charts",
            "configuratorButton": false,
            "showAggregationLabels": false,
            "chart":
            {
                "showLegend": true,
                "showDataLabels": true,
                "showMeasures": false
            }
        }
    }

    var quartlyDonutChartReport = {
        dataSource: {
            dataSourceType: "json",
            filename: Chart_URL + "?chName=QuartlyDonutChart&chScreen=dashboard1&chartInputJson=" + inputJson,
            requestHeader: {
                "Authorization": `Bearer ${token}`
            }
        },

        "slice": {
            "reportFilters": [
                {
                    "uniqueName": "distname",
                    "caption": "Distributor"
                }
            ],
            "rows": [
                {
                    "uniqueName": "invquarter",
                    "caption": "Quater"
                }
            ],
            "columns": [
                {
                    "uniqueName": "[Measures]"
                }
            ],
            "measures": [
                {
                    "uniqueName": "amount",
                    "aggregation": "sum",
                    "format": "-105hdiggbplb00"
                }
            ]
        },
        "options": {
            "viewType": "charts",
            "configuratorButton": false,
            "chart": {
                "type": "pie",
                "showLegend": true,
                "showDataLabels": true,
                "showMeasures": false
            }
        }

    }

    var newCustColChartReport = {
        dataSource: {
            dataSourceType: "json",
            filename: Chart_URL + "?chName=NewCustColChart&chScreen=dashboard1&chartInputJson=" + inputJson,
            requestHeader: {
                "Authorization": `Bearer ${token}`
            }
        },

        "slice": {
            "reportFilters": [
                {
                    "uniqueName": "distname"
                }
            ],
            "rows": [
                {
                    "uniqueName": "mnth"
                }
            ],
            "columns": [
                {
                    "uniqueName": "[Measures]"
                }
            ],
            "measures": [
                {
                    "uniqueName": "newcustomer",
                    "aggregation": "sum",
                    "format": "-105hdiggbplb00"
                }
            ]
        },
        "options": {
            "viewType": "charts",
            "configuratorButton": false,
            "chart":
            {
                "showLegend": true,
                "showDataLabels": true,
                "showMeasures": false
            }
        }

    }

    var topCustColChartReport = {
        dataSource: {
            dataSourceType: "json",
            filename: Chart_URL + "?chName=TopCustColChart&chScreen=dashboard1&chartInputJson=" + inputJson,
            requestHeader: {
                "Authorization": `Bearer ${token}`
            }
        },

        "slice": {
            "reportFilters": [
                {
                    "uniqueName": "invmonth",
                    "filter": {
                        "members": [
                            "invmonth.[jan]"
                        ]
                    }
                },
                {
                    "uniqueName": "enngname"
                },
                {
                    "uniqueName": "distname"
                }
            ],
            "rows": [
                {
                    "uniqueName": "custname",
                    "filter": {
                        "measure": {
                            "uniqueName": "amount",
                            "aggregation": "sum",
                            "format": "-105hdiggbplb00"
                        },
                        "query": {
                            "top": 5
                        }
                    }
                }
            ],
            "columns": [
                {
                    "uniqueName": "[Measures]"
                }
            ],
            "measures": [
                {
                    "uniqueName": "amount",
                    "aggregation": "sum",
                    "format": "-105hdiggbplb00"
                }
            ],
            "sorting": {
                "column": {
                    "type": "desc",
                    "tuple": [],
                    "measure": {
                        "uniqueName": "amount",
                        "aggregation": "sum"
                    }
                }
            }
        },
        "options": {
            "viewType": "charts",
            "configuratorButton": false,
            "chart":
            {
                "showLegend": true,
                "showDataLabels": true,
                "showMeasures": false
            }
        }

    }

    var NESvsSSDReport = {
        dataSource: {
            dataSourceType: "json",
            filename: Chart_URL + "?chName=NESvsSSD&chScreen=dashboard1&chartInputJson=" + inputJson,
            requestHeader: {
                "Authorization": `Bearer ${token}`
            }
        },

        "slice": {
            "reportFilters": [
                {
                    "uniqueName": "distname"
                }
            ],
            "rows": [
                {
                    "uniqueName": "invmonth"
                }
            ],
            "columns": [
                {
                    "uniqueName": "[Measures]"
                }
            ],
            "measures": [
                {
                    "uniqueName": "nesamount",
                    "aggregation": "sum",
                    "format": "-105hdiggbplb00"
                },
                {
                    "uniqueName": "ssdamount",
                    "aggregation": "sum",
                    "format": "-105hdiggbplb00"
                }
            ]
        },
        "options": {
            "viewType": "charts",
            "chart": {
                "type": "column_line",
                "showLegend": true,
                "showDataLabels": true,
                "showMeasures": false
            }
        }

    }

    var complianceReceiptDataReport = {
        dataSource: {
            dataSourceType: "json",
            filename: Chart_URL + "?chName=ComplianceReceiptData&chScreen=dashboard1&chartInputJson=" + inputJson,
            requestHeader: {
                "Authorization": `Bearer ${token}`
            }
        },

        "slice": {
            "rows": [
                {
                    "uniqueName": "distname",
                    "caption": "Distributor"
                }
            ],
            "columns": [
                {
                    "uniqueName": "[Measures]"
                },
                {
                    "uniqueName": "mnth",
                    "caption": "SSD Month"
                }
            ],
            "measures": [
                {
                    "uniqueName": "days",
                    "aggregation": "avg"
                }
            ]
        },
        "conditions": [
            {
                "formula": "#value = 0",
                "format": {
                    "backgroundColor": "#EF5350",
                    "color": "#000000",
                    "fontFamily": "Arial",
                    "fontSize": "12px"
                }
            },
            {
                "formula": "#value = 1",
                "format": {
                    "backgroundColor": "#8cb045",
                    "color": "#000000",
                    "fontFamily": "Arial",
                    "fontSize": "12px"
                }
            },
            {
                "formula": "#value = 2",
                "format": {
                    "backgroundColor": "#FFFF00",
                    "color": "#000000",
                    "fontFamily": "Arial",
                    "fontSize": "12px"
                }
            }
        ],
        "tableSizes": {
            "columns": [
                { "idx": 0, "width": 300 },
                { "idx": 1, "width": 60 },
                { "idx": 2, "width": 60 },
                { "idx": 3, "width": 60 },
                { "idx": 4, "width": 60 },
                { "idx": 5, "width": 60 },
                { "idx": 6, "width": 60 },
                { "idx": 7, "width": 60 },
                { "idx": 8, "width": 60 },
                { "idx": 9, "width": 60 },
                { "idx": 10, "width": 60 },
                { "idx": 11, "width": 60 },
                { "idx": 12, "width": 60 }
            ]
        },
        "options": {
            "configuratorButton": false,
            "drillThrough": false,
            "grid": {
                "showTotals": "off",
                "showGrandTotals": "off",
                "showHeaders": false
            }
        }


    }

    var topProdColChartReport = {
        dataSource: {
            dataSourceType: "json",
            filename: Chart_URL + "?chName=TopProdColChart&chScreen=dashboard1&chartInputJson=" + inputJson,
            requestHeader: {
                "Authorization": `Bearer ${token}`
            }
        },

        "slice": {
            "reportFilters": [
                {
                    "uniqueName": "invmonth",
                    "filter": {
                        "members": [
                            "invmonth.[jan]"
                        ]
                    }

                },
                {
                    "uniqueName": "enngname"
                },
                {
                    "uniqueName": "distname"
                }
            ],
            "rows": [
                {
                    "uniqueName": "prodname",
                    "filter": {
                        "measure": {
                            "uniqueName": "amount",
                            "aggregation": "sum"
                        },
                        "query": {
                            "top": 10
                        }
                    }
                }

            ],
            "columns": [
                {
                    "uniqueName": "[Measures]"
                },
                {
                    "uniqueName": "invmonth"
                }
            ],
            "measures": [
                {
                    "uniqueName": "amount",
                    "aggregation": "sum"
                }
            ],
            "sorting": {
                "column": {
                    "type": "desc",
                    "tuple": [],
                    "measure": {
                        "uniqueName": "amount",
                        "aggregation": "sum"
                    }
                }
            }
        },
        "options": {
            "viewType": "charts",
            "configuratorButton": false,
            "chart":
            {
                "showLegend": true,
                "showDataLabels": true,
                "showMeasures": false
            }
        }

    }



    function customizeToolbar(toolbar) {
        // get all tabs 
        var tabs = toolbar.getTabs();
        toolbar.getTabs = function () {
            // delete the first tab 
            delete tabs[0];
            delete tabs[1];
            delete tabs[2];
            delete tabs[3];
            delete tabs[4];
            delete tabs[5];
            delete tabs[6];
            delete tabs[7];
            delete tabs[8];
            delete tabs[9];
            delete tabs[10];
            tabs.push({
                title: "To Excel",
                id: "fm-tab-export-excel",
                mobile: false,
                icon: this.icons.export_excel,
                handler: this.exportHandler,
                args: "excel"
            });
            tabs.push({
                title: "Close",
                id: "fm-tab-close",
                mobile: false,
                icon: '<img src="https://www.flexmonster.com/flexmonster/assets/img/icons/close-n-cancel-button_large@2x.png">',
                rightGroup: true,
                handler: () => clsSSDReport(false)
            });
            return tabs;
        }
    }

    function clsSSDReport(isClose) {
        setSSDReport(isClose);
    }
    const [Report, setReport, getReport] = useState(false);
    const openReport = () => setReport(true);
    const closeReport = () => setReport(false);

    const [SSDReport, setSSDReport] = useState(false);
    const openSSDReport = () => {
        setSSDReport(true);
    }
    const closeSSDReport = () => setSSDReport(false);

    const [salesColChart, setSalesColChart] = useState(false);
    const openSalesColChart = () => setSalesColChart(true);
    const closeSalesColChart = () => setSalesColChart(false);

    const [quartlyDonutChart, setQuartlyDonutChart] = useState(false);
    const openQuartlyDonutChart = () => setQuartlyDonutChart(true);
    const closetQuartlyDonutChart = () => setQuartlyDonutChart(false);


    const [newCustColChart, setNewCustColChart] = useState(false);
    const openNewCustColChart = () => setNewCustColChart(true);
    const closeNewCustColChart = () => setNewCustColChart(false);


    const [topCustColChart, setTopCustColChart] = useState(false);
    const openTopCustColChart = () => setTopCustColChart(true);
    const closeTopCustColChart = () => setTopCustColChart(false);

    const [NESvsSSD, setNESvsSSD] = useState(false);
    const openNESvsSSD = () => setNESvsSSD(true);
    const closeNESvsSSD = () => setNESvsSSD(false);

    const [topProdColChart, setTopProdColChart] = useState(false);
    const openTopProdColChart = () => setTopProdColChart(true);
    const closeTopProdColChart = () => setTopProdColChart(false);

    const [complianceReceiptData, setComplianceReceiptData] = useState(false);
    const openComplianceReceiptData = () => setComplianceReceiptData(true);
    const closeComplianceReceiptData = () => setComplianceReceiptData(false);


    const handleDateChange = date => {
        let date1 = moment(date).format('yyyyMMDD');
        selectedDate = moment(date).format("yyyyMMDD");
        inputJson = encodeURI(JSON.stringify({ "userId": userId, "reportDate": date1 }));
        let filename1 = Chart_URL + "?chName=SalesColChart&chScreen=dashboard1&chartInputJson=" + inputJson;
        salesColChartReport.dataSource.filename = filename1;
        flex1.setReport(salesColChartReport)

        let filename2 = Chart_URL + "?chName=QuartlyDonutChart&chScreen=dashboard1&chartInputJson=" + inputJson;
        quartlyDonutChartReport.dataSource.filename = filename2;
        flex2.setReport(quartlyDonutChartReport)

        let filename3 = Chart_URL + "?chName=NewCustColChart&chScreen=dashboard1&chartInputJson=" + inputJson;
        newCustColChartReport.dataSource.filename = filename3;
        flex3.setReport(newCustColChartReport)

        let filename4 = Chart_URL + "?chName=TopCustColChart&chScreen=dashboard1&chartInputJson=" + inputJson;
        topCustColChartReport.dataSource.filename = filename4;
        flex4.setReport(topCustColChartReport)

        let filename5 = Chart_URL + "?chName=ComplianceReceiptData&chScreen=dashboard1&chartInputJson=" + inputJson;
        complianceReceiptDataReport.dataSource.filename = filename5;
        flex5.setReport(complianceReceiptDataReport)

        let filename6 = Chart_URL + "?chName=TopProdColChart&chScreen=dashboard1&chartInputJson=" + inputJson
        topProdColChartReport.dataSource.filename = filename6;
        flex6.setReport(topProdColChartReport)


    };

    //<button className="btn btn-info chart-btns" type="button" onClick={openQuartlyDonutChart}>
    //    Stock Report
    //</button>


    return (
        <div className="container-fluid">
            <div className="text-left" style={{ padding: '10px 0px', display: 'flex' }}>
                <div className="col-xs-12 col-md-6">
                    <button className="btn btn-info chart-btns" type="button" onClick={openSSDReport}>
                        SSD
					</button>	
						<FlexReport
						SSDReport={SSDReport}
						closeSSDReport={closeSSDReport}
						customizeToolbar={customizeToolbar}
						License_key={License_key}
						selectedDate={selectedDate}
						data={SSD2}
                        userId={userId}
                        userType={userType}
						Chart_URL={Chart_URL}
										/>
						<button
						className="btn btn-info chart-btns"
						type="button"
						onClick={openQuartlyDonutChart}
						>
                        Stock Report
                    </button>
                    <button className="btn btn-info chart-btns" type="button" onClick={openTopProdColChart}>
                        Product Performance
                    </button>
                </div>
                <div className="col-xs-12 col-md-4">
                    <div className="text-right" style={{ padding: '9px 0px 0px 0px' }}>
                        <span style={{ fontSize: '16px' }}>Select month to view SSD up to that period</span>

                    </div>

                </div>
                <div className="col-xs-12 col-md-2">
                    <div className="text-left">
                        <MonthPicker
                            onChange={handleDateChange}
                            format="MMM-yyyy"
                            showMonthYearPicker={true}
                            placeholder="Select Month"
                            defaultValue={moment().subtract(1, 'months')}
                        />
                    </div>
                </div>
            </div>
            <div className="row" style={{ margin: '10px 0px' }}>
                <div className="row">
                    <div className="col-xs-12 col-md-6">
                        <div className="text-left">
                            <span style={{ fontSize: '18px', fontWeight: '600' }}>Distributor Sales</span>
                        </div>
                        <div className="row" style={{ margin: '10px 0px' }}>
                            <FlexmonsterReact.Pivot
                                width="100%"
                                ref={ref1}
                                report={salesColChartReport} celldoubleclick={openSalesColChart} chartclick={openSalesColChart}
                                reportcomplete={onReportComplete}
                                licenseKey={License_key}
                            />


                            <Modal show={salesColChart} onHide={closeSalesColChart}>
                                <Modal.Header>
                                    <button className="btn btn-info" type="button" style={{ marginTop: '8px', float: 'Right', backgroundColor: '#e1000f' }} onClick={closeSalesColChart}>Close</button>
                                </Modal.Header>
                                <Modal.Body>
                                    <FlexmonsterReact.Pivot toolbar={true}
                                        width="100%"
                                        report={salesColChartReport}
                                        licenseKey={License_key}
                                    />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                    <div className="col-xs-12 col-md-6">
                        <div>
                            <span style={{ fontSize: '16px', fontWeight: '600' }}>Quarterly Performance</span>
                        </div>
                        <div className="row" style={{ margin: '10px 0px' }}>
                            <FlexmonsterReact.Pivot toolbar={false}
                                width="100%"
                                ref={ref2}
                                report={quartlyDonutChartReport} celldoubleclick={openQuartlyDonutChart} chartclick={openQuartlyDonutChart}
                                licenseKey={License_key}
                                reportcomplete={onReportComplete}
                            />

                            <Modal show={quartlyDonutChart} onHide={closetQuartlyDonutChart}>
                                <Modal.Header>
                                    <button className="btn btn-info" type="button" style={{ marginTop: '8px', float: 'Right', backgroundColor: '#e1000f' }} onClick={closetQuartlyDonutChart}>Close</button>
                                </Modal.Header>
                                <Modal.Body>
                                    <FlexmonsterReact.Pivot toolbar={true}
                                        width="100%"
                                        report={quartlyDonutChartReport}
                                        licenseKey={License_key}
                                    />
                                </Modal.Body>
                            </Modal>

                        </div>
                    </div>
                </div>


                <div className="row" style={{ margin: '20px 0px' }}>
                    <div className="col-xs-12 col-md-6">
                        <div className="text-left">
                            <span style={{ fontSize: '16px', fontWeight: '600' }}>New Customers</span>
                        </div>
                        <div className="row" style={{ margin: '10px 0px' }}>
                            <FlexmonsterReact.Pivot toolbar={false}
                                width="100%"
                                ref={ref3}
                                report={newCustColChartReport} celldoubleclick={openNewCustColChart} chartclick={openNewCustColChart}
                                licenseKey={License_key}
                                reportcomplete={onReportComplete}
                            />


                            <Modal show={newCustColChart} onHide={closeNewCustColChart}>
                                <Modal.Header>
                                    <button className="btn btn-info" type="button" style={{ marginTop: '8px', float: 'Right', backgroundColor: '#e1000f' }} onClick={closeNewCustColChart}>Close</button>
                                </Modal.Header>
                                <Modal.Body>
                                    <FlexmonsterReact.Pivot toolbar={true}
                                        width="100%"
                                        report={newCustColChartReport}
                                        licenseKey={License_key} />
                                </Modal.Body>
                            </Modal>

                        </div>
                    </div>
                    <div className="col-xs-12 col-md-6">
                        <div>
                            <span style={{ fontSize: '16px', fontWeight: '600' }}>Top Customers Of Each Month</span>
                        </div>
                        <div className="row" style={{ margin: '10px 0px' }}>
                            <FlexmonsterReact.Pivot toolbar={false}
                                width="100%"
                                ref={ref4}
                                report={topCustColChartReport} celldoubleclick={openTopCustColChart} chartclick={openTopCustColChart}
                                licenseKey={License_key}
                                reportcomplete={onReportComplete}
                            />

                            <Modal show={topCustColChart} onHide={closeTopCustColChart}>
                                <Modal.Header>
                                    <button className="btn btn-info" type="button" style={{ marginTop: '8px', float: 'Right', backgroundColor: '#e1000f' }} onClick={closeTopCustColChart}>Close</button>
                                </Modal.Header>
                                <Modal.Body>
                                    <FlexmonsterReact.Pivot toolbar={true}
                                        width="100%"
                                        report={topCustColChartReport}
                                        licenseKey={License_key} />
                                </Modal.Body>
                            </Modal>

                        </div>
                    </div>
                </div>


                <div className="row mt-3">
                    <div className="col-xs-12 col-md-12">
                        <div className="text-left">
                            <span style={{ fontSize: '16px', fontWeight: '600' }}> Compliance-Reciept Data</span>
                            <span style={{ fontSize: '10px' }}>   ( Green - uploaded before 7th of next month, </span>
                            <span style={{ fontSize: '10px' }}>Yellow – uploaded after 7th of next month, </span>
                            <span style={{ fontSize: '10px' }}>Red - not uploaded )</span>

                        </div>
                        <div className="row" style={{ margin: '10px 0px' }}>
                            <FlexmonsterReact.Pivot toolbar={false}
                                width="100%"
                                height="300px"
                                ref={ref5}
                                report={complianceReceiptDataReport} celldoubleclick={openComplianceReceiptData} chartclick={openComplianceReceiptData}
                                customizeCell={customizeCellFunction}
                                licenseKey={License_key}
                                drillThrough={false}
                                reportcomplete={onReportComplete}
                            />

                            <Modal show={complianceReceiptData} onHide={closeComplianceReceiptData}>
                                <Modal.Header>
                                    <button className="btn btn-info" type="button" style={{ marginTop: '8px', float: 'Right', backgroundColor: '#e1000f' }} onClick={closeComplianceReceiptData}>Close</button>
                                </Modal.Header>
                                <Modal.Body>
                                    <FlexmonsterReact.Pivot toolbar={true}
                                        width="100%"
                                        report={complianceReceiptDataReport}
                                        customizeCell={customizeCellFunction}
                                        drillThrough={false}
                                        licenseKey={License_key} />
                                </Modal.Body>
                            </Modal>

                        </div>
                    </div>
                </div>

                <div className="row mt-3" style={{ margin: '20px 0px' }}>
                    <div className="col-xs-12 col-md-12">
                        <div>
                            <span style={{ fontSize: '16px', fontWeight: '600' }}>Top Product Performance</span>
                        </div>
                        <div className="row" style={{ margin: '10px 0px' }}>
                            <FlexmonsterReact.Pivot toolbar={false}
                                width="100%"
                                ref={ref6}
                                report={topProdColChartReport} celldoubleclick={openTopProdColChart} chartclick={openTopProdColChart}
                                licenseKey={License_key}
                                reportcomplete={onReportComplete}
                            />

                            <Modal show={topProdColChart} onHide={closeTopProdColChart}>
                                <Modal.Header>
                                    <button className="btn btn-info" type="button" style={{ marginTop: '8px', float: 'Right', backgroundColor: '#e1000f' }} onClick={closeTopProdColChart}>Close</button>
                                </Modal.Header>
                                <Modal.Body>
                                    <FlexmonsterReact.Pivot toolbar={true}
                                        width="100%"
                                        report={topProdColChartReport} licenseKey={License_key}
                                    />
                                </Modal.Body>
                            </Modal>

                        </div>
                    </div>
                </div>


            </div>
        </div>
    );
}

function customizeCellFunction(cell, data) {
    if (data.type == "value" && !data.isDrillThrough) {
        cell.text = ""
    }
}



const mapStateToProps = state => {
    return {
        userId: getValue(state, ["loginReducer", "userId"]),
        userType: getValue(state, ["loginReducer", "userType"])
    };
};
export default Dashboard1 = connect(mapStateToProps)(Dashboard1);