import { Modal, ModalTitle } from 'react-bootstrap';
import React, { useState, useEffect } from "react";
import { InputBox } from "../../../../common/components/InputBox";
import { connect } from "react-redux";
import { Field, withFormik } from "formik";
import { changePassword } from "./api";
import Notification from '../../../../common/notification'
import moment from 'moment';
import sha256 from "../../../../common/helpers/sha";
import { getValue, validateNewPassword, validatePassword } from "../../../../common/helpers/validations";



const formikEnhancer = withFormik({

    mapPropsToValues: ({ user, props }) => ({
        ...user,
        ...props
    }),
    handleSubmit: (payload, { setSubmitting, props }) => {
        
        
        setSubmitting(true);
        payload = JSON.stringify({ "usrId": props.userId, "usrLastpwdchgDt": moment().format('yyyyMMDD'), "usrOldPassword": sha256(payload.CurrentPassword), "usrPassword": sha256(payload.NewPassword) });
        changePassword(payload).then(resp => {
            if (resp.status === 200) {
                Notification.success('Password Changed Successfully.');
            }
            else {
                Notification.error('Error while saving data:' + resp.data.status_response);
            }
            setSubmitting(false);
        })

    },
    displayName: "MyForm"
});


let ChangePassword = props => {

    const { handleSubmit, setFieldValue, setValues, values, isSubmitting } = props;
    let { userId } = props;
    const { show, closeModal } = props;


    return (
        <Modal show={show} onHide={closeModal}>
            <Modal.Header>
                <ModalTitle>
                    Change Password
                    <button onClick={closeModal} className="close" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </ModalTitle>
            </Modal.Header>
            <Modal.Body>
                <div className="row">

                    <form className={isSubmitting ? "disabled" : ""} onSubmit={handleSubmit}>
                        <div className="col-md-12 mt-15 mb-25">
                            <div className="col-md-4">
                                <label htmlFor="CurrentPassword" style={{ display: "block" }}>
                                    Current Password
                                        </label>
                            </div>
                            <div className="col-md-8">
                                <Field
                                    id="CurrentPassword"
                                    name="CurrentPassword"
                                    component={InputBox}
                                    type={"password"}
                                    value={values.CurrentPassword || ""}
                                    placeholder="Current Password"
                                    onChange={(e) => {
                                        setFieldValue("CurrentPassword", e.target.value);
                                    }}
                                //validate={validatePassword}
                                />

                            </div>
                        </div>


                        <div className="col-md-12 mt-15 mb-25">
                            <div className="col-md-4">
                                <label htmlFor="NewPassword" style={{ display: "block" }}>
                                    New Password
                                    </label>
                            </div>
                            <div className="col-md-8">
                                <Field
                                    id="NewPassword"
                                    name="NewPassword"
                                    component={InputBox}
                                    type={"password"}
                                    value={values.NewPassword || ""}
                                    placeholder="New Password"
                                    onChange={(e) => {
                                        setFieldValue("NewPassword", e.target.value);
                                    }}
                                    validate={validateNewPassword}
                                />
                            </div>
                        </div>

                        <div className="col-md-12 mt-15 mb-25">
                            <div className="col-md-4">
                                <label htmlFor="ConfirmPassword" style={{ display: "block" }}>
                                    Confirm Password
                                    </label>
                            </div>
                            <div className="col-md-8">
                                <Field
                                    id="ConfirmPassword"
                                    name="ConfirmPassword"
                                    component={InputBox}
                                    type={"password"}
                                    value={values.ConfirmPassword || ""}
                                    placeholder="Confirm Password"
                                    onChange={(e) => {
                                        setFieldValue("ConfirmPassword", e.target.value);
                                    }}
                                //validate={validatePassword}
                                />
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="col-md-4">
                            </div>
                            <div className="col-md-8">
                                <button
                                    className={isSubmitting ? " btn btn-success btn-save disabled" : "btn btn-save btn-success"}
                                    type="submit"
                                    onClick={handleSubmit}>
                                    {"Save"}
                                </button>
                                <button type="button" className="btn btn-cancle" onClick={closeModal}>Cancel</button>
                            </div>

                        </div>
                    </form>

                </div>
            </Modal.Body>
        </Modal >

    );
};
const mapStateToProps = state => {
    return {
        userId: getValue(state, ["loginReducer", "userId"])
    };
};



const ChangePasswords = connect(mapStateToProps)(formikEnhancer(ChangePassword))

export default ChangePasswords;

