import React, { useState,useRef,useEffect } from "react";
import { Modal } from 'react-bootstrap';
import '../../App.css'
import { connect } from "react-redux";
import { getValue } from "../../common/helpers/validations";
import 'flexmonster/theme/orange/flexmonster.min.css';
import * as FlexmonsterReact from 'react-flexmonster/hooks';
import { DatePicker } from 'antd';
import moment from 'moment';
import { Chart_URL, License_key } from "../../common/helpers/urlsConstants";

var flex1 = null;


let Dashboard2 = (props) => {
    
	const ref1 = useRef();
    

    let { userId , userType} = props;
    const { MonthPicker } = DatePicker;
    let inputJson = encodeURI(JSON.stringify({ "userId": userId, "reportDate": moment().subtract(1, 'months').format('yyyyMMDD'), "userType": userType }));

    var tokenId = sessionStorage.getItem("tokenId")
    var token = tokenId.replace(/['"]+/g, '');

	useEffect(()=>{
        flex1 = ref1.current.flexmonster();
    },[])

    const onReportComplete = () => {
        if (flex1 === null) {
            //flex1 = ref1.current.flexmonster();
        }
      
    }

    var salesColChartReport = {
        dataSource: {
            dataSourceType: "json",            
            filename: Chart_URL + "?chName=ssddata&chScreen=dashboard3&chartInputJson=" + inputJson,
            requestHeader: {
                "Authorization": `Bearer ${token}`
            }
        },

"slice": {
        "reportFilters": [
            {
                "uniqueName": "distname"
            }
        ],
        "rows": [
            {
                "uniqueName": "mnth"
            },
            {
                "uniqueName": "loctite_engineer"
            },
            {
                "uniqueName": "customer_name"
            }
        ],
        "columns": [
            {
                "uniqueName": "[Measures]"
            }
        ],
        "measures": [
            {
                "uniqueName": "invoice_no",
                "aggregation": "count",
                "grandTotalCaption": "invoice_no"
            },
            {
                "uniqueName": "quantity",
                "aggregation": "sum",
                "grandTotalCaption": "quantity"
            },
            {
                "uniqueName": "amount",
                "aggregation": "sum",
                "grandTotalCaption": "amount"
            },
            {
                "uniqueName": "engineer_idh",
                "aggregation": "sum",
                "grandTotalCaption": "engineer_idh",
                "active": false
            },
            {
                "uniqueName": "product_idh",
                "aggregation": "sum",
                "grandTotalCaption": "product_idh",
                "active": false
            },
            {
                "uniqueName": "customer_idh",
                "aggregation": "count",
                "grandTotalCaption": "customer_idh",
                "active": false
            },
            {
                "uniqueName": "distname",
                "aggregation": "count",
                "grandTotalCaption": "distname",
                "active": false
            },
            {
                "uniqueName": "mnth",
                "aggregation": "count",
                "grandTotalCaption": "mnth",
                "active": false
            },
            {
                "uniqueName": "loctite_engineer",
                "aggregation": "count",
                "grandTotalCaption": "loctite_engineer",
                "active": false
            },
            {
                "uniqueName": "customer_name",
                "aggregation": "count",
                "grandTotalCaption": "customer_name",
                "active": false
            },
            {
                "uniqueName": "product_description",
                "aggregation": "count",
                "grandTotalCaption": "product_description",
                "active": false
            },
            {
                "uniqueName": "invoice_date",
                "aggregation": "count",
                "grandTotalCaption": "invoice_date",
                "active": false
            }
        ]       
    },
    "options": {
        "grid": {
            "type": "classic",
            "showHeaders": false,
            "showTotals": "columns",
            "showGrandTotals": "off"
        },
        "configuratorButton": false,
        "sorting": "off",
        "datePattern": "dd-MM-yyyy",
        "showAggregationLabels": false
    },
    "version": "2.8.16",
    "creationDate": "2020-12-04T06:13:54.946Z"
    }



function customizeToolbar(toolbar) { 
    // get all tabs 
    var tabs = toolbar.getTabs(); 
    toolbar.getTabs = function () { 
        // delete the first tab 
        delete tabs[0]; 
		delete tabs[1];
		delete tabs[2];
		delete tabs[3];
		delete tabs[4];
		delete tabs[5];
		delete tabs[6];
		delete tabs[7];
		delete tabs[8];
		delete tabs[9];
		delete tabs[10];
		tabs.push({
        title: "To Excel",
        id: "fm-tab-export-excel",
        mobile: false,
        icon: this.icons.export_excel,
		handler: this.exportHandler,
		args: "excel"     	  
    });
		
        return tabs; 
    } 
} 

function salesColRep(isClose){	
	setSalesColChart(isClose);
}

  

    const [salesColChart, setSalesColChart] = useState(false);
    const openSalesColChart = () => setSalesColChart(true);
    const closeSalesColChart = () => setSalesColChart(false);


    const handleDateChange = date => {
        let date1 = moment(date).format('yyyyMMDD');
        //console.log("date:", date1);
        inputJson = encodeURI(JSON.stringify({ "userId": userId, "reportDate": date1, "userType": userType }));

        let filename1 = Chart_URL + "?chName=ssddata&chScreen=dashboard3&chartInputJson=" + inputJson;
        salesColChartReport.dataSource.filename = filename1;
        flex1.setReport(salesColChartReport)

    };

    return (
        <div className="container-fluid">
			<div className="text-left" style={{ padding: '10px 0px', display: 'flex' }}>                
                <div className="col-xs-12 col-md-12">
                    <div className="text-right" style={{ padding: '9px 0px 0px 0px' }}>
                        <span style={{ fontSize: '16px' }}>Select month to view SSD up to that period</span>

                    </div>

                </div>
                <div className="col-xs-12 col-md-2">
                    <div className="text-left">
                        <MonthPicker
                            onChange={handleDateChange}
                            format="MMM-yyyy"
                            showMonthYearPicker={true}
                            placeholder="Select Month"
                            defaultValue={moment().subtract(1, 'months')}
                        />
                    </div>
                </div>
            </div>
            <div className="row" style={{ margin: '20px 0px' }}>

                <div className="row">
                    <div className="col-xs-12 col-md-12">
                        <div className="text-left">
                            <span style={{ fontSize: '16px', fontWeight: '600' }}>Sales Analysis</span>
                        </div>
                        <div style={{ padding: '5px 0px' }}>
                            <FlexmonsterReact.Pivot toolbar={true}
                                width="100%"
                                height="600px"
                                ref={ref1}
								beforetoolbarcreated={customizeToolbar}
                                report={salesColChartReport} 
                                reportcomplete={onReportComplete}
                                licenseKey={License_key}
                            />
                        </div>
                    </div>
                </div>
		
            </div>
        </div>
    );
}
const mapStateToProps = state => {
    return {
        userId: getValue(state, ["loginReducer", "userId"]),
		userType: getValue(state, ["loginReducer", "userType"])
    };
};
export default Dashboard2 = connect(mapStateToProps)(Dashboard2);