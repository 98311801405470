import { post } from "axios";
import { BASE_URL } from '../../../../common/helpers/urlsConstants';




export const findDistributorList = async (id, tokenId) => {
    tokenId = sessionStorage.getItem("tokenId")
    var token = tokenId.replace(/['"]+/g, '');
    const authData = {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${token}`
        }
    };
    const url = `${BASE_URL}/findDistributorList?page=0&size=20000&sort=distId&userId=${id}`;
    return fetch(url, authData).then(response => response.json()).then(resp => {
        
        if (!resp.content) {
            return [];
        } else {
            return [resp.content];
        }
    })
};

export const findDistributorById = async (distId, tokenId) => {
    tokenId = sessionStorage.getItem("tokenId")
    var token = tokenId.replace(/['"]+/g, '');
    const authData = {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${token}`
        }
    };
    const url = `${BASE_URL}/findDistributorById?id=${distId}`;
    return fetch(url, authData).then(response => response.json()).then(resp => {
        if (!resp) {
            return [];
        } else {
            return [resp];
        }
    })
};

export const deleteDistributorById = async (distId, tokenId) => {
    tokenId = sessionStorage.getItem("tokenId")
    var token = tokenId.replace(/['"]+/g, '');
    const authData = {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${token}`
        }
    };
    const url = `${BASE_URL}/deleteDistributorById?id=${distId}`;
    return fetch(url, authData).then(response => response.json()).then(resp => {
        if (resp.status_code = 200) {
            return true;
        } else {
            return false;
        }
    })
};

export const saveOrUpdateDistributor = async (data, tokenId) => {
    
    tokenId = sessionStorage.getItem("tokenId")
    var token = tokenId.replace(/['"]+/g, '');
    const authData = {
        method: "POST",
        headers: {
            "Authorization": `Bearer ${token}`,
            "accept": "*/*",
            "Content-Type": "application/json",
        }
    };
    
    const url = `${BASE_URL}/saveOrUpdateDistributor`;
    
    return post(url, data, authData);
};


export const fileUpload = async (file, inputJson, tokenId) => {
    tokenId = sessionStorage.getItem("tokenId")
    var token = tokenId.replace(/['"]+/g, '');
    const authData = {
        method: "POST",
        headers: {
            "Authorization": `Bearer ${token}`,
            "content-type": "multipart/form-data"
        }
    };
    const url = `${BASE_URL}/uploadFile`;

    const formData = new FormData();
    formData.append("file", file);
    formData.append("inputParamJson", inputJson);

    return post(url, formData, authData);
};


//export const findDistributorList = async id => {
//    const response = await fetch(`${BASE_URL}/findDistributorList?page=0&size=20000&sort=distId&userId=${id}`);
//    const resp = await response.json();
//    if (!resp) {
//        return [];
//    } else {
//        return resp;
//    }
//};


//export const findDistributorById = async distId => {
//    const response = await fetch(`${BASE_URL}/findDistributorById?id=${distId}`);
//    const resp = await response.json();
//    if (!resp) {
//        return [];
//    } else {
//        return [resp];
//    }
//};

//export const deleteDistributorById = async distId => {
//    const response = await fetch(`${BASE_URL}/deleteDistributorById?id=${distId}`);
//    const resp = await response.json();
//    if (resp.status_code = 200) {
//        return true;
//    } else {
//        return false;
//    }
//};


//export const saveOrUpdateDistributor = async data => {
//    return post(`${BASE_URL}/saveOrUpdateDistributor`, data)
//};



//export const fileUpload = (file, inputJson) => {
//    const authData = {
//        method: "POST",
//        headers: new Headers()
//    };
//    const url = `${BASE_URL}/uploadFile`;
//    const formData = new FormData();
//    formData.append("file", file);
//    const config = {
//        headers: {
//            "content-type": "multipart/form-data"
//        }
//    };
//    formData.append("inputParamJson", inputJson);
//    return post(url, formData, config, authData);
//};
