import { post } from 'axios';
import { BASE_URL } from "../../../../common/helpers/urlsConstants";



export const findProductList = async (id, tokenId) => {
    tokenId = sessionStorage.getItem("tokenId")
    var token = tokenId.replace(/['"]+/g, '');
    const authData = {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${token}`
        }
    };
    const url = `${BASE_URL}/product/findProductList?page=0&size=20000&sort=productId&companyId=${id}`;
    return fetch(url, authData).then(response => response.json()).then(resp => {
        
        if (!resp.content) {
            return [];
        } else {
            return [resp.content];
        }
    })
};


export const findProductById = async (productId, tokenId) => {
    tokenId = sessionStorage.getItem("tokenId")
    var token = tokenId.replace(/['"]+/g, '');
    const authData = {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${token}`
        }
    };
    const url = `${BASE_URL}/product/findProductById?id=${productId}`;
    return fetch(url, authData).then(response => response.json()).then(resp => {
        if (!resp) {
            return [];
        } else {
            return [resp];
        }
    })
};


export const deleteProductById = async (productId, tokenId) => {
    tokenId = sessionStorage.getItem("tokenId")
    var token = tokenId.replace(/['"]+/g, '');
    const authData = {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${token}`
        }
    };
    const url = `${BASE_URL}/product/deleteProductById?id=${productId}`;
    return fetch(url, authData).then(response => response.json()).then(resp => {
        if (resp.status_code = 200) {
            return true;
        } else {
            return false;
        }
    })
};


export const saveOrUpdateProduct = async (data, tokenId) => {
    tokenId = sessionStorage.getItem("tokenId")
    var token = tokenId.replace(/['"]+/g, '');
    const authData = {
        method: "POST",
        headers: {
            "Authorization": `Bearer ${token}`
        }
    };
    const url = `${BASE_URL}/product/saveOrUpdateProduct`;
    return post(url, data, authData);
};


export const fileUpload = async (file, inputJson, tokenId) => {
    tokenId = sessionStorage.getItem("tokenId")
    var token = tokenId.replace(/['"]+/g, '');
    const authData = {
        method: "POST",
        headers: {
            "Authorization": `Bearer ${token}`,
            "content-type": "multipart/form-data"
        }
    };
    const url = `${BASE_URL}/uploadFile`;

    const formData = new FormData();
    formData.append("file", file);
    formData.append("inputParamJson", inputJson);

    return post(url, formData, authData);
};



//export const findProductList = async id => {
//    const response = await fetch(`${BASE_URL}/product/findProductList?page=0&size=20000&sort=productId&companyId=${id}`);
//    const resp = await response.json();
//    if (!resp) {
//        return [];
//    } else {
//        return resp;
//    }
//};


//export const findProductById = async productId => {
//    const response = await fetch(`${BASE_URL}/product/findProductById?id=${productId}`);
//    const resp = await response.json();
//    if (!resp) {
//        return [];
//    } else {
//        return [resp];
//    }
//};


//export const deleteProductById = async productId => {
//    const response = await fetch(`${BASE_URL}/product/deleteProductById?id=${productId}`);
//    const resp = await response.json();
//    if (resp.status_code = 200) {
//        return true;
//    } else {
//        return false;
//    }
//};

//export const saveOrUpdateProduct = async data => {
//    return post(`${BASE_URL}/product/saveOrUpdateProduct`, data)
//};


//export const fileUpload = (file, inputJson) => {
//    const authData = {
//        method: "POST",
//        headers: new Headers()
//    };
//    const url = `${BASE_URL}/uploadFile`;
//    const formData = new FormData();
//    formData.append("file", file);
//    const config = {
//        headers: {
//            "content-type": "multipart/form-data"
//        }
//    };
//    formData.append("inputParamJson", inputJson);
//    return post(url, formData, config, authData);
//};
