import { BASE_URL } from "../../../../common/helpers/urlsConstants";
import axios, { post } from "axios";


//export const fileUpload = (file, inputJson) => {
//    const authData = {
//        method: "POST",
//        headers: new Headers()
//    };
//    const url = `${BASE_URL}/uploadFile`;
//    const formData = new FormData();
//    formData.append("file", file);
//    const config = {
//        headers: {
//            "content-type": "multipart/form-data"
//        }
//    };
//    formData.append("inputParamJson", inputJson);
//    return post(url, formData, config, authData);
//};


export const fileUpload = async (file, inputJson, tokenId) => {
    tokenId = sessionStorage.getItem("tokenId")
    var token = tokenId.replace(/['"]+/g, '');
    const authData = {
        method: "POST",
        headers: {
            "Authorization": `Bearer ${token}`,
            "content-type": "multipart/form-data"
        }
    };
    const url = `${BASE_URL}/uploadFile`;

    const formData = new FormData();
    formData.append("file", file);
    formData.append("inputParamJson", inputJson);

    return post(url, formData, authData);
};
