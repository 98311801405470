import { BASE_URL } from "../../../common/helpers/urlsConstants";

//export const getFileUploadedByUsr = async id => {
//  const response = await fetch(`${BASE_URL}/getFileUploadedByUsr?usrId=${id}`);
//  const resp = await response.json();
//  if (!resp) {
//    return [];
//  } else {
//    return resp;
//  }
//};


export const getFileUploadedByUsr = async (id, tokenId) => {
    tokenId = sessionStorage.getItem("tokenId")
    var token = tokenId.replace(/['"]+/g, '');
    const authData = {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${token}`
        }
    };
    const url = `${BASE_URL}/getFileUploadedByUsr?usrId=${id}`;
    return fetch(url, authData).then(response => response.json()).then(resp => {
        if (!resp) {
            return [];
        } else {
            return [resp];
        }
    })
};