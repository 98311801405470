//import { LOGIN, LOGIN_INITIATED, LOGIN_SUCCESS, LOGIN_FAILED, LOGOUT, LOGOUT_CALL,ADD_DASHBOARD_PERMISSIONS } from "../actions/actionTypes";
import { LOGIN, LOGIN_INITIATED, LOGIN_SUCCESS, LOGIN_FAILED, ADD_DASHBOARD_PERMISSIONS } from "../actions/actionTypes";
import { takeLatest, call, put } from "redux-saga/effects";
import { loginAPI } from "../apis/loginAPI";

export function* loginSaga() {
	yield takeLatest(LOGIN, workerSaga);
	// yield takeLatest(LOGOUT, logoutWorkerSaga);
}

function* workerSaga(action) {
	try {
		yield put({ type: LOGIN_INITIATED });

		const response = yield call(loginAPI, action.payload.email, action.payload.password);

		if (response.data.userFoud) {
			yield put({ type: LOGIN_SUCCESS, payload: response.data })
			yield put({ type: ADD_DASHBOARD_PERMISSIONS, payload: response.data.userModuleList })
			action.payload.history.push('/')
			yield call(saveInLocalStorage, response.data)
		} else {
			yield put({ type: LOGIN_FAILED, payload: response.data.message })
		}
	}
	catch (error) {
		yield put({ type: LOGIN_FAILED, payload: "The loginID or password you entered is incorrect. Please try again." })
		console.log("login err", error)
	}
}

const saveInLocalStorage = (response) => {
	sessionStorage.setItem('userData', JSON.stringify(response));
	sessionStorage.setItem('tokenId', JSON.stringify(response.tokenId));
}

