import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from "react-router-dom";
import { Field, withFormik } from "formik";
import schema from "./editFreezingDataViewFields.json"
import { findFreezePeriodList, saveOrUpdateFreezePeriod } from "./api.js";
import { connect } from "react-redux";
import { DatePicker } from "antd";
import { getValue, required, requiredField } from "../../../../common/helpers/validations";
import { DisappearedLoading } from 'react-loadingg';
import moment from 'moment';
import Notification from '../../../../common/notification'


const formikEnhancer = withFormik({
    mapPropsToValues: ({ user, props }) => ({
        ...user,
        ...props
    }),
    handleSubmit: (payload, { setSubmitting, props }) => {
        const dataToSave = [];
        debugger;
        payload.fpcrdt = null;
        payload.fpcruid = props.userId;
        payload.fpupdt = 0;
        payload.fpupuid = 0;
        dataToSave.push(payload);
        setSubmitting(true);
        saveOrUpdateFreezePeriod(dataToSave).then(resp => {
            //console.log("savedData", resp);
            if (resp.status === 200) {
                Notification.success('Saved Successful.');
                props.history.push('/FreezinfData');
            }
            else {
                Notification.error('Error while saving data:' + resp.data.status_response);
            }
            setSubmitting(false);
        })

    },


    displayName: "MyForm"
});


let EditFreezingOfDataView = (props) => {
    const { userId, handleSubmit, setFieldValue, setValues, values, isSubmitting } = props;
    const [loading, setLoading] = useState(true);

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    const fpid = useQuery().get("id");

    const initData = {
        fpcrdt: null,
        fpcruid: userId,
        fpfromdt: "",
        fpisopen: false,
        fptodt: "",
        fpupdt: 0,
        fpupuid: 0,
    }

    const history = useHistory()

    const handleCancle = () => {
        history.goBack()
    }

    const filterData = (freezingData) => {
        if (freezingData.length != 0) {
            const result = freezingData.filter(x => x.fpid == fpid)
            setValues(result[0])
        }
    }

    const handleChange = isChecked => {
        if (isChecked.target.checked) {
            setFieldValue("fpisopen", true);
        }
        else {
            setFieldValue("fpisopen", false);
        }
    };

    useEffect(() => {
        if (fpid) {
            findFreezePeriodList().then(data => {
                filterData(data[0]);
            })
        }
        else {
            setValues(initData);
            setLoading(false);
        }
    }, []);


    return (
        <div className="container-fluid" >
            <div className="row">
                <div className="col-md-12 mt-20">
                    <div className="panel panel-default">
                        <div className="panel-body">
                            <div className="panel-title text-left">Freezing of data
                                <button
                                    className="btn btn-cancle"
                                    style={{ float: "right" }}
                                    type="button btn"
                                    onClick={handleCancle}>
                                    {"Cancel"}
                                </button>
                                <button
                                    className={isSubmitting ? " btn btn-success btn-save disabled" : "btn btn-save btn-success"}
                                    style={{ float: "right" }}
                                    type="submit"
                                    onClick={handleSubmit}>
                                    {"Save"}
                                </button>
                            </div>

                            <div className="editpanel">
                                <form onSubmit={handleSubmit}>
                                    {isSubmitting ?
                                        <DisappearedLoading color="#5f6973" />
                                        : ("")}
                                    {schema.map((item, index) => {
                                        return (
                                            <div key={index} >
                                                <div className={item.Editable === "No" ? "col-md-12 display-flex disabled" : "col-md-12 display-flex"}>
                                                    <label className="form_label w-30">
                                                        {item.FieldName} {item.Mandatory && <span className="error">* </span>}
                                                    </label>

                                                    {item.backendMappingName === "fpfromdt" || item.backendMappingName === "fptodt" ? (
                                                        <div className="form_input">
                                                            <DatePicker
                                                                id={item.backendMappingName}
                                                                placeholder="Select Date"
                                                                value={values[item.backendMappingName] ? moment(values[item.backendMappingName]) : moment()}
                                                                onChange={
                                                                    item.backendMappingName === "fpfromdt" ? ((field, value) => {
                                                                        setFieldValue("fpfromdt", value);
                                                                    }) : ((field, value) => {
                                                                        setFieldValue("fptodt", value);
                                                                    })}
                                                                validate={item.Mandatory && required.bind(this, item.FieldName)}
                                                            /></div>

                                                    ) : (
                                                            <div className="form_input">
                                                                <input
                                                                    name={"fpisopen"}
                                                                    type="checkbox"
                                                                    id={"fpisopen"}
                                                                    className={"checkbox"}
                                                                    onChange={handleChange}
                                                                    checked={values[item.backendMappingName]}
                                                                />

                                                            </div>
                                                        )}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </form>
                                <div className="col-md-12 mt-20 row display-flex">
                                    <button
                                        className={isSubmitting ? " btn btn-success btn-save disabled" : "btn btn-save btn-success"}
                                        type="submit"
                                        onClick={handleSubmit} >
                                        {"Save"}
                                    </button>
                                    <button
                                        className="btn btn-cancle"
                                        type="button btn"
                                        onClick={handleCancle} >
                                        {"Cancel"}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


const mapStateToProps = state => {
    return {
        userId: getValue(state, ["loginReducer", "userId"])
    };
};
const EditFreezingOfDataViews = connect(mapStateToProps)(formikEnhancer(EditFreezingOfDataView))

export default EditFreezingOfDataViews;
