import {
    LOGIN_INITIATED,
    LOGIN_SUCCESS,
    LOGIN_FAILED,
    LOGOUT_CALL,
    REHYDRATE_REDUCER_WITH_LOCAL_DATA
    
} from "../actions/actionTypes";

const initialState = {
    fetching: false,
    error: false,
    success: false,
    fullName: "",
    isLoggedIn: false, //! comment this for development and uncomment below code
    //// isLoggedIn: true,
    message: "",
    userId: "",
	userType: "",
    userCompanyId: ""
};

export function loginReducer(state = initialState, action) {
    switch (action.type) {
        case LOGIN_INITIATED:
            return {
                ...state,
                fetching: true,
                error: false,
                success: false,
                message: "",
                fullName: "",
                userId: "",
				userType: "",
                userCompanyId: "",
                usrSupportAdmin: ""
            };

        case LOGIN_SUCCESS:
            return {
                ...state,
                fetching: false,
                error: false,
                success: true,
                isLoggedIn: true,
                message: "",
                fullName: `${action.payload.usrFirstName} ${action.payload.usrLastName}`,
                userId: action.payload.usrId,
				userType: action.payload.userType,
                userCompanyId: action.payload.usrCompanyid,
                usrSupportAdmin: action.payload.usrSupportAdmin
            };

        case LOGIN_FAILED:
            return {
                ...state,
                fetching: false,
                error: true,
                success: false,
                isLoggedIn: false,
                message: "The loginID or password you entered is incorrect. Please try again.",
                fullName: ""
            };

        case LOGOUT_CALL:
            return {
                ...state,
                fetching: false,
                error: false,
                success: false,
                isLoggedIn: false,
                userId: "",
				userType: ""
            };

        case REHYDRATE_REDUCER_WITH_LOCAL_DATA:
            return {
                ...state,
                fetching: false,
                error: false,
                success: true,
                isLoggedIn: true,
                message: "",
                fullName: `${action.payload.usrFirstName} ${action.payload.usrLastName}`,
                userId: action.payload.usrId,
				userType: action.payload.userType,
                userCompanyId: action.payload.usrCompanyid,
                usrSupportAdmin: action.payload.usrSupportAdmin
            };
        default:
            return state;
    }
}
