import { post } from 'axios';
import { BASE_URL } from "../../../../common/helpers/urlsConstants";


export const findUserAdminById = async (userId, tokenId) => {

    tokenId = sessionStorage.getItem("tokenId")
    var token = tokenId.replace(/['"]+/g, '');
    const authData = {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${token}`
        }
    };
    const url = `${BASE_URL}/findUserAdminById?id=${userId}`;
    return fetch(url, authData).then(response => response.json()).then(resp => {

        if (!resp) {
            return [];
        } else {
            return [resp];
        }
    })
};


export const findAdminUserList = async (userCompanyId, userId,tokenId) => {
    tokenId = sessionStorage.getItem("tokenId")
    var token = tokenId.replace(/['"]+/g, '');
    const authData = {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${token}`
        }
    };
    const url = `${BASE_URL}/getAdminUserList?usrCompanyId=${userCompanyId}&usrId=${userId}`;
    return fetch(url, authData).then(response => response.json()).then(resp => {
        if (!resp) {
            return [];
        } else {
            return [resp];
        }
    })
};


export const saveOrUpdateUserAdmin = async (data, tokenId) => {

    tokenId = sessionStorage.getItem("tokenId")
    var token = tokenId.replace(/['"]+/g, '');
    const authData = {
        method: "POST",
        headers: {
            "Authorization": `Bearer ${token}`
        }
    };

    for (var i = 0; i < data.amUserDepts.length; i++) {
        if (data.amUserDepts[i].label != undefined && data.amUserDepts[i].value != undefined) {
            delete data.amUserDepts[i]["label"];
            delete data.amUserDepts[i]["value"];
        }
    }

    for (var i = 0; i < data.amUserMaps.length; i++) {
        if (data.amUserMaps[i].label != undefined && data.amUserMaps[i].value != undefined) {
            delete data.amUserMaps[i]["label"];
            delete data.amUserMaps[i]["value"];
        }
    }

    for (var i = 0; i < data.amUserRoles.length; i++) {
        if (data.amUserRoles[i].label != undefined && data.amUserRoles[i].value != undefined) {
            delete data.amUserRoles[i]["label"];
            delete data.amUserRoles[i]["value"];
        }
    }

    for (var i = 0; i < data.amUserTypes.length; i++) {
        if (data.amUserTypes[i].label != undefined && data.amUserTypes[i].value != undefined) {
            delete data.amUserTypes[i]["label"];
            delete data.amUserTypes[i]["value"];
        }
    }

    for (var i = 0; i < data.amUserZones.length; i++) {
        if (data.amUserZones[i].label != undefined && data.amUserZones[i].value != undefined) {
            delete data.amUserZones[i]["label"];
            delete data.amUserZones[i]["value"];
        }
    }
    const url = `${BASE_URL}/saveOrUpdateUserAdmin`;
    return post(url, data, authData);
};

//export const findUserAdminById = async userId => {
//    const response = await fetch(`${BASE_URL}/findUserAdminById?id=${userId}`);
//    const resp = await response.json();
//    if (!resp) {
//        return [];
//    } else {
//        return [resp];
//    }
//};

//export const findAdminUserList = async userCompanyId => {
//    const response = await fetch(`${BASE_URL}/getAdminUserList?usrCompanyId=${userCompanyId}`);
//    const resp = await response.json();
//    if (!resp) {
//        return [];
//    } else {
//        return [resp];
//    }
//};

//export const saveOrUpdateUserAdmin = async data => {
//    
//    for (var i = 0; i < data.amUserDepts.length; i++) {
//        if (data.amUserDepts[i].label != undefined && data.amUserDepts[i].value != undefined) {
//            delete data.amUserDepts[i]["label"];
//            delete data.amUserDepts[i]["value"];
//        }
//    }

//    for (var i = 0; i < data.amUserMaps.length; i++) {
//        if (data.amUserMaps[i].label != undefined && data.amUserMaps[i].value != undefined) {
//            delete data.amUserMaps[i]["label"];
//            delete data.amUserMaps[i]["value"];
//        }
//    }

//    for (var i = 0; i < data.amUserRoles.length; i++) {
//        if (data.amUserRoles[i].label != undefined && data.amUserRoles[i].value != undefined) {
//            delete data.amUserRoles[i]["label"];
//            delete data.amUserRoles[i]["value"];
//        }
//    }

//    for (var i = 0; i < data.amUserTypes.length; i++) {
//        if (data.amUserTypes[i].label != undefined && data.amUserTypes[i].value != undefined) {
//            delete data.amUserTypes[i]["label"];
//            delete data.amUserTypes[i]["value"];
//        }
//    }

//    for (var i = 0; i < data.amUserZones.length; i++) {
//        if (data.amUserZones[i].label != undefined && data.amUserZones[i].value != undefined) {
//            delete data.amUserZones[i]["label"];
//            delete data.amUserZones[i]["value"];
//        }
//    }
//    return post(`${BASE_URL}/saveOrUpdateUserAdmin`, data)
//};



