import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'mutation-observer';
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./App.css";

import "./css/shortcuts.css";
import "./css/style.css";
import "./css/responsive.css";
import "react-table/react-table.css";
import 'antd/dist/antd.css';

const render = () => {
  ReactDOM.render(<App />, document.getElementById("root"));
};

render();
