import React, { useState } from "react";
import * as FlexmonsterReact from "react-flexmonster/hooks";
import { Modal } from "react-bootstrap";
import moment from "moment";

const FlexReport = (props) => {
    const {
        SSDReport,
        closeSSDReport,
        customizeToolbar,
        License_key,
        selectedDate,
        userId,
        userType,
        data,
        Chart_URL,
    } = props;

    const loadData = () => {
        let jsonStr = encodeURI(
            JSON.stringify({
                userId: userId,
                reportDate: !selectedDate
                    ? moment().subtract(1, "months").format("yyyyMMDD")
                    : selectedDate,
                userType: userType,
            })
        );
        data.dataSource.filename =
            Chart_URL + "?chName=SSD2&chScreen=dashboard2&chartInputJson=" + jsonStr;

        return data;
    };

    return (
        <Modal show={SSDReport} onHide={closeSSDReport}>
            <Modal.Body>
                <FlexmonsterReact.Pivot
                    toolbar={true}
                    width="100%"
                    report={loadData()}
                    beforetoolbarcreated={customizeToolbar}
                    licenseKey={License_key}
                />
            </Modal.Body>
        </Modal>
    );
};

export default FlexReport;
