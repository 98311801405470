import React from "react"
import { Modal, ModalTitle } from 'react-bootstrap';
import { useHistory } from "react-router-dom";

function BiModal(props) {

    const { show, closeModal } = props;
    let history=useHistory();

    return (
        <Modal
            show={show}
            onHide={closeModal}
            backdrop="static"
        >
            <Modal.Header>
                <ModalTitle>
                    Idle Session Warning
                </ModalTitle>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-12 mt-20">
                        <button className="btn btn-info btn-modal" type="button" onClick={() =>{sessionStorage.removeItem('userData');history.push("/login");}}>
                            Log me out
                        </button>
                        <button className="btn btn-info btn-modal" type="button" onClick={closeModal}>Keep me signed in</button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )

}

export default BiModal;
