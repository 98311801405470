import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import { DatePicker } from "antd";
import { getValue } from "../../../../common/helpers/validations";
import Select from "../../../../common/components/Select";
import { fileUpload } from "./api";
import { Link } from "react-router-dom";
import Notification from '../../../../common/notification'
import moment from 'moment';
import { getCompany } from "../../../../services/staticdata";
import { DisappearedLoading } from 'react-loadingg';


let UploadPrimarySalesData = props => {

    const [respId, setrespId] = useState(false);
    const [formSubmitting, setformSubmitting] = useState(false);
    const [formSubmitted, setformSubmitted] = useState(false);
	const { MonthPicker } = DatePicker;

    let { userId } = props;
    const [company, setCompany] = useState([]);

    async function loadStaticData() {
        setCompany(await getCompany());
    }

    useEffect(() => {
        loadStaticData();
    }, []);

    const [startMonth, setStartMonth] = useState(new Date());

    const handleMonthChange = month => {
        //console.log("date", moment(month).format('MMM-yyyy'));
        setStartMonth(month);
    };

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-12 mt-20">
                    <div className="panel panel-default">
                        <div className="panel-title text-left">Upload Primary Sales Data</div>
                        <div className="editpanel">

                            <Formik initialValues={{
                                file: null, inputJson: null,
                            }} onSubmit={values => {
                                let inputJson = JSON.stringify({ "type": "primarySales", "companyId": values.Company, "reportDate": moment(startMonth).format('yyyyMMDD'), "userid": userId });
                                setformSubmitting(true);
                                fileUpload(values.file, inputJson).then(response => {
                                    if (response.data.batchId) {
                                        Notification.success('Uploaded Successful.');
                                        setformSubmitted(true);
                                        setrespId(response.data.batchId);
                                        setformSubmitting(true);
                                    }
                                    else {
                                        setformSubmitting(false);
                                    }
                                }).catch(err => {
                                    setformSubmitting(false);
                                    return err;
                                })
                            }}
                            >
                                {({ handleSubmit, values, setFieldValue }) => (

                                    <form /*className={formSubmitting ? "disabled" : ""}*/ onSubmit={handleSubmit}>
                                        {formSubmitting ?
                                            <DisappearedLoading color="#5f6973" />
                                            : ("")}
                                        <div className="col-md-12 mt-15">
                                            <div className="col-md-2">
                                                <label htmlFor="Company" style={{ display: "block" }}>
                                                    Company
                                    </label>
                                            </div>
                                            <div className="col-md-10">
                                                <Select
                                                    id="Company"
                                                    value={values.Company}
                                                    options={company}
                                                    onChange={(field, value) => {
                                                        setFieldValue("Company", value);
                                                    }}
                                                />
                                            </div>
                                        </div>


                                        <div className="col-md-12 mt-15">
                                            <div className="col-md-2">
                                                <label htmlFor="month" style={{ display: "block" }}>
                                                    Month
                                    </label>
                                            </div>
                                            <div className="col-md-10">
                                                <MonthPicker
                                                    id={"month"}
                                                    onChange={handleMonthChange}
                                                    format="MMM-yyyy"
                                                    showMonthYearPicker={true}
                                                    placeholder="Select Month"
                                                />
                                            </div>
                                        </div>


                                        <div className="col-md-12 mt-15">
                                            <div className="col-md-2">
                                                <label htmlFor="overwrite" style={{ display: "block" }}>
                                                    Overwrite
                                     </label>
                                            </div>
                                            <div className="col-md-10">
                                                <input
                                                    name={"overwrite"}
                                                    type="checkbox"
                                                    id={"overwrite"}
                                                    className={"checkbox"}
                                                />
                                            </div>
                                        </div>


                                        <div className="col-md-12 mt-15">
                                            <div className="col-md-2">
                                                <label htmlFor="file" style={{ display: "block" }}>
                                                    File
                                    </label>
                                            </div>
                                            <div className="col-md-10">
                                                <input
                                                    id={"file"}
                                                    type="file"
                                                    onChange={(e, value) => {
                                                        setFieldValue("file", e.target.files[0]);
                                                    }}
                                                />
                                            </div>
                                        </div>


                                        <div className="col-md-12 mt-20 mb-20">
                                            <div className="col-md-2">
                                            </div>
                                            <div className="col-md-10">
                                               
                                                {props.sideBarMenuOptions.length !== 0 ?
                                                    props.sideBarMenuOptions.Upload.map((child, i) => (
                                                        child.pageName == "Upload Primary Sales" ?
                                                                child.rpEdit == 1 ?
                                                                <button type="submit" className={" btn btn-info btn-fileupload " + (formSubmitting && "disabled")}>Upload</button>
                                                                    :
                                                                    <button type="submit" className={" btn btn-info btn-fileupload disabled " + (formSubmitting && "disabled")}>Upload</button>
                                                            : ""
                                                    ))
                                                    : ("")
                                                }
                                                <button type="button" className="btn btn-filecancle">Cancel</button>
                                            </div>
                                        </div>

                                    </form>
                                )}
                            </Formik>

                            {respId && (
                                <Link to="/fileUploadStatus">
                                    <div className="alert alert-success cursor" role="alert" style={{ clear: "both" }}>
                                        {'Primary sales file is submitted. Click here to check the status and copy batch id.'}
                                        <strong id="batchId">{respId}</strong>
                                    </div>
                                </Link>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
const mapStateToProps = state => {
    return {
        userId: getValue(state, ["loginReducer", "userId"]),
        sideBarMenuOptions: state.permissionsReducer.sideBarOptions
    };
};

export default UploadPrimarySalesData = connect(mapStateToProps)(UploadPrimarySalesData);



