import React from "react";
import { Link } from 'react-router-dom'
import { deleteDistributorById, findDistributorList } from "./api";
//import { object } from "prop-types";
//import { color } from "highcharts";
//import * as e from "express";


export const tableColumHeaders = (userId, setDistributorList, sideBarMenuOptions) => {
    function onDelete(data) {
        deleteDistributorById(data).then(resp => {
            if (resp) {
                alert(`Distributor ${data} Deleted `);
                findDistributorList(userId).then(data => {
                    setDistributorList(data.content);
                });
            }
        })
    }

    return [
        {
            Header: "Distributor IDH",
            accessor: "distIdhCode",
            //Cell: e=>  <a href="distributor/editDistributor"> { e.value } </a >,
            sortable: true,
            maxWidth: 110,
            style: {
                textAlign: "right",
            },
            Cell: props => {
                return (
                    sideBarMenuOptions.length !== 0 ?
                        sideBarMenuOptions.Master.map((child, i) => (
                            child.pageName == "Distributor" ?
                                <div className="text-center">
                                    <Link
                                        className={'mr-3 btn-edit'}
                                        style={{ color: "#000000", textDecoration: "underline"}}
                                        to={`/distributor/editDistributor/?id=${(props && props.original) ? props.original.distId : 0}&read=true`}
                                    >
                                        {props.value}
                                    </Link>
                                </div> : ""
                        )
                        ) : ""
                );
            }
        },
        {
            Header: "Name",
            accessor: "distDistName",
            sortable: true,
            filterable: true,
            style: {
                textAlign: "left"
            },
            maxWidth: 550
        },
        {
            Header: "Zone",
            accessor: "zoneName",
            sortable: true,
            filterable: true,
            style: {
                textAlign: "left"
            },
            maxWidth: 200
        },
        {
            Header: "Email",
            accessor: "distEmail",
            sortable: false,
            filterable: true,
            style: {
                textAlign: "left"
            },
            maxWidth: 300
        },
        {
            Header: "Action",
            sortable: false,
            maxWidth: 170,
            filterable: false,
            Cell: props => {
                return (
                    sideBarMenuOptions.length !== 0 ?
                        sideBarMenuOptions.Master.map((child, i) => (
                            //console.log("rootSubmenuKeys" + rootSubmenuKeys);
                            child.pageName == "Distributor" ?
                                <div className="text-center">
                                    {child.rpEdit == 1 ?
                                        <Link
                                            className={'mr-3 btn-edit'}
                                            style={{ color: "#000000" }}
                                            to={`/distributor/editDistributor/?id=${(props && props.original) ? props.original.distId : 0}`}
                                        >
                                            <span className="glyphicon glyphicon-edit"></span>
                                        </Link>
                                        :
                                        <Link
                                            className={'mr-3 btn-edit'}
                                            style={{ color: "#000000" }}
                                        >
                                            <span className="glyphicon glyphicon-edit disabled"></span>
                                        </Link>
                                    }

                                    {child.rpDelete == 1 ?
                                        <Link
                                            className="btn-delete"
                                            style={{ color: "#000000" }}
                                            onClick={() => onDelete(props.original.distId)}
                                            to="#">
                                            <span className="glyphicon glyphicon-trash"></span>
                                        </Link>
                                        :
                                        <Link
                                            className={'mr-3 btn-edit'}
                                            style={{ color: "#000000" }}
                                        >
                                            <span className="glyphicon glyphicon-trash disabled"></span>
                                        </Link>
                                    }
                                </div> : ""
                        )
                        ) : ""
                );

                //};

                //if (rootSubmenuKeys != null && rootSubmenuKeys == 0) {

                //return (
                //    <div className="text-center">

                //        <Link
                //            className="btn-delete"
                //            style={{ color: "#000000" }}
                //            onClick={() => onDelete(props.original.distId)}
                //            to="#">
                //            <span className="glyphicon glyphicon-trash"></span>
                //        </Link>
                //    </div>
                //)
                //};

            }
        }
    ];
};


