import React from "react";
import { Link } from 'react-router-dom'
import { deleteProductDRPById, findProductDRPList } from "./api";
import moment from 'moment';


export const tableColumHeaders = (userCompanyId, setProductDRPList, sideBarMenuOptions) => {

    function onDelete(data) {
        deleteProductDRPById(data).then(resp => {
            if (resp) {
                alert(`ProductDRP ${data} Deleted `);
                findProductDRPList(userCompanyId).then(data => {
                    setProductDRPList(data.content);
                });
            }
        })
    }

    return [
        {
            Header: "SSA ID",
            accessor: "pdId",
            Cell: e => <a href="editProductDRP"> {e.value} </a>,
            sortable: true,
            filterable: true,
            style: {
                textAlign: "right"
            },

            Cell: props => {
                return (
                    sideBarMenuOptions.length !== 0 ?
                        sideBarMenuOptions.Master.map((child, i) => (
                            child.pageName == "Product DP" ?
                                <div className="text-center">
                                    <Link
                                        className={'mr-3 btn-edit'}
                                        style={{ color: "#000000", textDecoration: "underline" }}
                                        to={`/editProductDRP/?id=${(props && props.original) ? props.original.pdId : 0}&read=true`}
                                    >
                                        {props.value}
                                    </Link>
                                </div> : ""
                        )
                        ) : ""

                );
            },

            maxWidth: 100
        },
        {
            Header: "Product Name",
            accessor: "productName",
            sortable: true,
            filterable: true,
            style: {
                textAlign: "left"
            },
            maxWidth: 450
        },
        {
            Header: "Product",
            accessor: "productIdh",
            sortable: true,
            filterable: true,
            style: {
                textAlign: "right"
            },
            maxWidth: 150
        },
        {
            Header: "From Date",
            accessor: "pdFromDt",
            sortable: true,
            filterable: true,
            style: {
                textAlign: "center"
            },
            maxWidth: 200,
            Cell: props => {
                if (props.original.pdFromDt === null || props.original.pdFromDt === "" || props.original.pdFromDt === 0) {
                    return (
                        moment().format('DD/MM/YYYY')
                    )
                }
                else {
                    return (
                        moment(props.original.pdFromDt).format('DD/MM/YYYY')
                    )

                }

            }
        },
        {
            Header: "To Date",
            accessor: "pdToDt",
            sortable: true,
            filterable: true,
            style: {
                textAlign: "center"
            },
            maxWidth: 200,
            Cell: props => {
                if (props.original.pdToDt === null || props.original.pdToDt === "" || props.original.pdToDt === 0) {
                    return (
                        moment().format('DD/MM/YYYY')
                    )
                }
                else {
                    return (
                        moment(props.original.pdToDt).format('DD/MM/YYYY')
                    )
                }
            }
        },
        {
            Header: "DRP",
            accessor: "pdDrp",
            sortable: true,
            filterable: true,
            style: {
                textAlign: "right"
            },
            maxWidth: 100
        },
        {
            Header: "Action",
            sortable: false,
            filterable: false,
            maxWidth: 170,
            Cell: props => {
                return (
                    sideBarMenuOptions.length !== 0 ?
                        sideBarMenuOptions.Master.map((child, i) => (
                            //console.log("rootSubmenuKeys" + rootSubmenuKeys);
                            child.pageName == "Product DP" ?
                                <div className="text-center">
                                    {child.rpEdit == 1 ?
                                        <Link
                                            className={'mr-3 btn-edit'}
                                            style={{ color: "#000000" }}
                                            to={`/editProductDRP/?id=${(props && props.original) ? props.original.pdId : 0}&read=false`}
                                        >
                                            <span className="glyphicon glyphicon-edit"></span>
                                        </Link>
                                        :
                                        <link
                                            classname={'mr-3 btn-edit'}
                                            style={{ color: "#000000" }}
                                        >
                                            <span classname="glyphicon glyphicon-edit disabled"></span>
                                        </link>
                                    }

                                    {child.rpDelete == 1 ?
                                        <Link
                                            className="btn-delete"
                                            style={{ color: "#000000" }}
                                            onClick={() => onDelete(props.original.pdId)}
                                            to="#">
                                            <span className="glyphicon glyphicon-trash"></span>
                                        </Link>
                                        :
                                        <Link
                                            className="btn-delete"
                                            style={{ color: "#000000" }}
                                            to="#">
                                            <span className="glyphicon glyphicon-trash disabled"></span>
                                        </Link>
                                    }
                                </div> : ""
                        )
                        ) : ""
                );

            }

            //Cell: props => {

            //    return (
            //        <div className="text-center">
            //            <Link
            //                className={'mr-3 btn-edit'}
            //                style={{ color: "#000000" }}
            //                to={`/editProductDRP/?id=${(props && props.original) ? props.original.pdId : 0}`}
            //            >
            //                <span className="glyphicon glyphicon-edit"></span>
            //            </Link>
            //            <Link
            //                className="btn-delete"
            //                style={{ color: "#000000" }}
            //                onClick={() => onDelete(props.original.pdId)}
            //                to="#">
            //                <span className="glyphicon glyphicon-trash"></span>
            //            </Link>
            //        </div>
            //    );
            //}
        }
    ];
};


