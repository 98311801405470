import React, { Component } from 'react';

export class InputBox extends Component {

    render() {
        const { field, form: { touched, errors }, type, disable = false } = this.props

        return (
            <React.Fragment >
                <input className={errors[field.name] && touched[field.name] ? "input-on-error" : ""} ktype={type} {...field} {...this.props} disabled={!!disable} />
                {touched[field.name] &&
                    errors[field.name] && <div className="error" style={{ position: "absolute" }}>{errors[field.name]}</div>
                }
            </React.Fragment>
        );
    }
}

export class TextArea extends Component {

    render() {
        
        const { field, form: { touched, errors }, type, disable = false } = this.props
        return (
            <React.Fragment >
                <textarea type={type} {...field} {...this.props} disabled={!!disable} />
                {touched[field.name] &&
                    errors[field.name] && <div className="error" style={{ position: "absolute" }}>{errors[field.name]}</div>
                }
            </React.Fragment>
        );
    }
}

