import { post } from 'axios';
import { BASE_URL } from "../../../../common/helpers/urlsConstants";


export const findCustomerList = async (id, tokenId) => {
    tokenId = sessionStorage.getItem("tokenId")
    var token = tokenId.replace(/['"]+/g, '');
    const authData = {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${token}`
        }
    };
    const url = `${BASE_URL}/customer/findCustomerList?page=0&size=50000&sort=custId&userId=${id}`;
    return fetch(url, authData).then(response => response.json()).then(resp => {
        
        if (!resp.content) {
            return [];
        } else {
            return resp.content;
        }
    })
};


export const findCustomerById = async (custId, tokenId) => {
    tokenId = sessionStorage.getItem("tokenId")
    var token = tokenId.replace(/['"]+/g, '');
    const authData = {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${token}`
        }
    };
    const url = `${BASE_URL}/customer/findCustomerById?id=${custId}`;
    return fetch(url, authData).then(response => response.json()).then(resp => {
        
        if (!resp) {
            return [];
        } else {
            return [resp];
        }
    })
};


export const deleteCustomerById = async (custId, tokenId) => {
    tokenId = sessionStorage.getItem("tokenId")
    var token = tokenId.replace(/['"]+/g, '');
    const authData = {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${token}`
        }
    };
    const url = `${BASE_URL}/customer/deleteCustomerById?id=${custId}`;
    return fetch(url, authData).then(response => response.json()).then(resp => {
        if (resp.status_code = 200) {
            return true;
        } else {
            return false;
        }
    })
};


export const saveOrUpdateCustomer = async (data, tokenId) => {
    tokenId = sessionStorage.getItem("tokenId")
    var token = tokenId.replace(/['"]+/g, '');
    const authData = {
        method: "POST",
        headers: {
            "Authorization": `Bearer ${token}`
        }
    };
    const url = `${BASE_URL}/customer/saveOrUpdateCustomer`;
    return post(url, data, authData);
};


export const fileUpload = async (file, inputJson, tokenId) => {
    tokenId = sessionStorage.getItem("tokenId")
    var token = tokenId.replace(/['"]+/g, '');
    const authData = {
        method: "POST",
        headers: {
            "Authorization": `Bearer ${token}`,
            "content-type": "multipart/form-data"
        }
    };
    const url = `${BASE_URL}/uploadFile`;

    const formData = new FormData();
    formData.append("file", file);
    formData.append("inputParamJson", inputJson);

    return post(url, formData, authData);
};



//export const findCustomerList = async id => {
//    const response = await fetch(`${BASE_URL}/customer/findCustomerList?page=0&size=20000&sort=custId&userId=${id}`);
//  const resp = await response.json();
//  if (!resp) {
//    return [];
//  } else {
//    return resp;
//  }
//};


//export const findCustomerById = async custId => {
//  const response = await fetch(`${BASE_URL}/customer/findCustomerById?id=${custId}`);
//  const resp = await response.json();
//  if (!resp) {
//    return [];
//  } else {
//    return [resp];
//  }
//};


//export const deleteCustomerById = async custId => {
//  const response = await fetch(`${BASE_URL}/customer/deleteCustomerById?id=${custId}`);
//  const resp = await response.json();
//  if (resp.status_code=200) {
//    //console.log("deleted cust :",custId);
//    return true;
//  } else {
//    return false;
//  }
//};


//export const saveOrUpdateCustomer = async data => {  
//  return post(`${BASE_URL}/customer/saveOrUpdateCustomer`, data)
//};


//Added By Bindi (For Customer Upload)

//export const fileUpload = (file, inputJson) => {
//    const authData = {
//        method: "POST",
//        headers: new Headers()
//    };
//    const url = `${BASE_URL}/uploadFile`;
//    const formData = new FormData();
//    formData.append("file", file);
//    const config = {
//        headers: {
//            "content-type": "multipart/form-data"
//        }
//    };
//    formData.append("inputParamJson", inputJson);
//    return post(url, formData, config, authData);
//};


