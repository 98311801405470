import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getValue } from "../../../../common/helpers/validations";
import ReactTable from "react-table";
import { Link } from 'react-router-dom'
import { BASE_URL } from "../../../../common/helpers/urlsConstants";
import { downloadFile } from "../../../../common/helpers/fileDownload";
import { findDistributorList } from "./api";
import { tableColumHeaders } from "./tableConstants";
import DistributorUpload from "./distributorUpload";
import { DisappearedLoading } from 'react-loadingg';


let Distributor = props => {

    let { userId } = props;
    const [distributors, setDistributorList] = useState([]);
    const [show, setShow] = useState(false);
    const openModal = () => setShow(true);
    const closeModal = () => setShow(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        findDistributorList(userId).then(data => {
            //console.log("data", data);
            setLoading(false);
            setDistributorList(data[0]);
        });
    }, []);
   
    return (

        <div className="container-fluid">
            <div className="row">
                <div className="col-md-12 mt-20">
                    <div className="panel panel-default">
                        <div className="panel-body">
                            <div className="panel-title text-left">
                                Distributors
                                 
                                <DistributorUpload closeModal={closeModal} show={show} />
                                <button className="btn btn-info btn-download" type="button">
                                    <a onClick={() => downloadFile(`${BASE_URL}/dowloadDistributorReport?userId=${props.userId}`, "Distributors.xlsx")} style={{
                                        color: "inherit",
                                        textDecoration: "none",
                                    }} >
                                        Download</a>
                                </button>


                                {props.sideBarMenuOptions.length !== 0 ?
                                    props.sideBarMenuOptions.Master.map((child, i) => (
                                        child.pageName == "Distributor" ?
                                            child.rpAdd == 1 ?
                                                <Link to={`/distributor/addDistributor/?id=`}>
                                                    <button className="btn btn-info btn-add" type="button">New</button>
                                                </Link>
                                                :
                                                <Link>
                                                    <button className="btn btn-info btn-add disabled" type="button">New</button>
                                                </Link>
                                            : ""
                                    ))
                                    : ("")
                                }
                                <button className="btn btn-info btn-upload" onClick={openModal} type="button" >Template</button>
                            </div>
                            {loading ? <DisappearedLoading color="#5f6973" /> :
                                <ReactTable
                                    data={distributors || []}
                                    columns={tableColumHeaders(userId, setDistributorList, props.sideBarMenuOptions)}
                                    filterable
                                    minRows={0}
                                    defaultPageSize={10}
                                    noDataText={"No Data"}
                                    showPageSizeOptions={true}
                                    defaultFilterMethod={filterCaseInsensitive}
                                    defaultSorted={[{
                                        id: "distId",
                                        desc: true
                                    }]}
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
const mapStateToProps = state => {
    return {
        userId: getValue(state, ["loginReducer", "userId"]),
        sideBarMenuOptions: state.permissionsReducer.sideBarOptions
    };
};


function filterCaseInsensitive(filter, row) {
    const id = filter.pivotId || filter.id;
    return (
        row[id] !== undefined ?
            String(row[id].toLowerCase()).indexOf(filter.value.toLowerCase()) > -1
            :
            true
    );
}

export default Distributor = connect(mapStateToProps)(Distributor);



