import { BASE_URL } from "../helpers/urlsConstants";

//export const getMenuByIdAPI = async (pageId, id,tokenId) => {
//	
//	tokenId = localStorage.getItem("tokenId")
//	const response = await fetch(`${BASE_URL}/getMenuByUsrIdAndModuleId?pageId=${pageId}&usrId=${id}`, { headers: { "Authorization": `Bearer ${tokenId}` } });
//	const resp = await response.json();
//	if (!resp) {
//		return false;
//	} else {
//		return resp
//	}
//}


export const getMenuByIdAPI = async (pageId, id, tokenId) => {
    tokenId = sessionStorage.getItem("tokenId")
    var token = tokenId.replace(/['"]+/g, '');
    const authData = {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${token}`
        }
    };
    const url = `${BASE_URL}/getMenuByUsrIdAndModuleId?pageId=${pageId}&usrId=${id}`;
    return fetch(url, authData).then(response => {
        const resp = response.json();
       
        if (!resp) {
            return false;
        } else {
            return resp
        }
    })
};


