import React from "react";
import { Link } from 'react-router-dom';
import moment from 'moment';
//import { useFilters } from 'react-table';



export const tableColumHeaders = (userId, setTicketList) => {
    //console.log("TicketList", setTicketList);
    function onDelete(data) {
    }
    return [

        {
            Header: "Ticket Number",
            accessor: "ticketId",
            sortable: true,
            maxWidth: 120,
            style: {
                textAlign: "right",
            }
        },
        {
            Header: "Distributor",
            accessor: "distName",
            sortable: true,
            filterable: true,
            style: {
                textAlign: "left"
            },
            maxWidth: 900
        },
        {
            Header: "Short Description",
            accessor: "ticketShortDesc",
            sortable: true,
            filterable: true,
            style: {
                textAlign: "left"
            },
            maxWidth: 400
        },
        {
            Header: " Support Engg",
            accessor: "supportEngg",
            sortable: true,
            filterable: true,
            style: {
                textAlign: "left"
            },
            maxWidth: 250
        },
        {
            Header: "Status",
            accessor: "ticketStatus",
            sortable: true,
            filterable: true,
            style: {
                textAlign: "left"
            },
            maxWidth: 150
        },
        {
            Header: "lastupdate",
            accessor: "ticketUpDt",
            sortable: true,
            filterable: true,
            style: {
                textAlign: "center"
            },
            maxWidth: 120,
            Cell: props => {
                return (
                    moment(props.original.ticketUpDt).format('DD/MM/YYYY')
                )
            }
        },
        {
            Header: "Action",
            sortable: false,
            maxWidth: 130,
            filterable: false,
            Cell: props => {
                return (
                    <div className="text-center">
                        <Link
                            className={'mr-3 btn-edit'}
                            style={{ color: "#000000" }}
                            to={`/ticket/editTicket/?id=${(props && props.original) ? props.original.ticketId : 0}`}
                        >
                            <span className="glyphicon glyphicon-edit"></span>
                        </Link>
                        <Link
                            className="btn-delete"
                            style={{ color: "#000000" }}
                            onClick={() => onDelete(props.original.ticketId)}
                            to="#">
                            <span className="glyphicon glyphicon-trash"></span>
                        </Link>
                    </div>
                );
            }
        }
    ];
};


