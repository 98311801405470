//export const BASE_URL = 'http://65.0.44.27:8180/henkel';
//export const WEB_URL = 'http://localhost:3000/'
//export const Chart_URL = 'http://65.0.44.27:8180/henkel/chart/getChartDataByTypeAndName'
//export const License_key = 'Z7YZ-XHDB2W-4F0Q2H-3D1O06-0I213G-4J3J1I-520352-351006-6G6Y5O-1X54'
//UAT export const License_key = 'Z7PO-XIA164-096H4Z-163Y1E-520S31-5K0L27-4Z6C4M-401H1Y-5E3P6V-5C0U1C-20'
//PROD export const License_key = 'Z7YZ-XHDB2W-4F0Q2H-3D1O06-0I213G-4J3J1I-520352-351006-6G6Y5O-1X54'

//UAT2 http://65.0.44.27:8180/
//UAT1 http://3.20.7.91:8180
// 13.233.65.242
//PROD 3.6.61.12:8180
//https://in-wisdom.com/
//https://aws.jksolutionsjk.com/login

//UAT
//export const BASE_URL = 'https://in-wisdom.com//henkel_uat';
//export const WEB_URL = 'http://localhost:3000/'
//export const Chart_URL = 'https://in-wisdom.com//henkel_uat/chart/getChartDataByTypeAndName'
//export const License_key = 'Z7YZ-XHDB2W-4F0Q2H-3D1O06-0I213G-4J3J1I-520352-351006-6G6Y5O-1X54'

//Prod
export const BASE_URL = 'https://in-wisdom.com//henkel';
export const WEB_URL = 'http://localhost:3000/'
export const Chart_URL = 'https://in-wisdom.com//henkel/chart/getChartDataByTypeAndName'
export const License_key = 'Z7YZ-XHDB2W-4F0Q2H-3D1O06-0I213G-4J3J1I-520352-351006-6G6Y5O-1X54'