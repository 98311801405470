import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getValue } from "../../../../common/helpers/validations";
import ReactTable from "react-table";
import { getRoleList } from "./api.js";
import { Link } from 'react-router-dom'
import { BASE_URL } from "../../../../common/helpers/urlsConstants";
import { tableColumHeaders } from "./tableConstants";
import { DisappearedLoading } from 'react-loadingg';


let Role = props => {

    const [role, setRoleList] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getRoleList().then(data => {
            setLoading(false);
            setRoleList(data[0])
        })
    }, []);

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-12 mt-20">
                    <div className="panel panel-default">
                        <div className="panel-body">
                            <div className="panel-title text-left">
                                Role
                                    <button className="btn btn-info btn-add" type="button">
                                    New
                                    </button>
                            </div>
                            {loading ? <DisappearedLoading color="#5f6973" /> :
                                <ReactTable
                                    data={role || []}
                                    columns={tableColumHeaders(setRoleList)}
                                    filterable
                                    minRows={0}
                                    defaultPageSize={10}
                                    noDataText={"No Data"}
                                    showPageSizeOptions={true}
                                    defaultFilterMethod={filterCaseInsensitive}
                                    defaultSorted={[{
                                        id: "rhId ",
                                        desc: true
                                    }]}
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
const mapStateToProps = state => {
    //return {
    //    userCompanyId: getValue(state, ["loginReducer", "userCompanyId"])
    //};
};


function filterCaseInsensitive(filter, row) {
    const id = filter.pivotId || filter.id;
    return (
        row[id] !== undefined ?
            String(row[id].toLowerCase()).indexOf(filter.value.toLowerCase()) > -1
            :
            true
    );
}

export default Role = connect(mapStateToProps)(Role);




