
import React, { useEffect, useState } from "react";
import { Field, withFormik } from "formik";
import { InputBox, TextArea } from "../../common/components/InputBox";
import Select from "../../common/components/Select";
import schema from "./editTicketViewFields.json"
import { connect } from "react-redux";
import { getValue, email, validatePhone, required, requiredField } from "../../common/helpers/validations";
import { findTicketById, saveOrUpdateTicket } from "./api";
import { findDistributorListAPI } from "../../services/api";
import { useLocation } from "react-router-dom";
import { getSupportEngineer, getTicketStatus } from "../../services/staticdata";
//import { Link } from 'react-router-dom'
import { Timeline, TimelineEvent } from 'react-event-timeline'
import moment from 'moment';
import Notification from '../../common/notification'
import { BASE_URL } from "../../common/helpers/urlsConstants";
import { DisappearedLoading } from 'react-loadingg';
import { downloadFile } from "../../common/helpers/fileDownload";

const formikEnhancer = withFormik({

    mapPropsToValues: ({ user, props }) => ({
        ...user,
        ...props
    }),
    handleSubmit: (payload, { setSubmitting, props }) => {
        
        if (payload.Description !== "" && payload.Description !== undefined) {
            let newAddedConversations = [];
            newAddedConversations = {
                tcComments: payload.Description,
                tcCrDt: null,
                tcCrUid: props.userId,
                tcUpDt: null,
                tcUpUid: null
            }
            payload.atTicketConversions.unshift(newAddedConversations);
        }

        setSubmitting(true);
        saveOrUpdateTicket(payload, payload.files).then(resp => {
            
            if (resp.status === 200) {
                Notification.success('Saved Successful.');
                props.history.goBack()
            }
            else {
                Notification.error('Error while saving data:' + resp.data.status_response);
            }
            setSubmitting(false);
        })
    },
    displayName: "MyForm"
});


let EditTicketView = (props) => {

    let { userId, userSupportAdmin } = props;
    const { handleSubmit, setFieldValue, setValues, values, isSubmitting } = props;
    const [distributorList, setDistributorList] = useState(null);
    const [supportEngineer, setSupportEngineer] = useState([]);
    const [ticketStatus, setTicketStatus] = useState([]);
    const [ticketFiles, setTicketFiles] = useState([]);
    const [ticketConversions, setTicketConversions] = useState([]);

    const initData = {
        atTicketConversions: [],
        atTicketFiles: [],
        ticketContactPerson: "",
        ticketCrDt: null,
        ticketCrUid: userId,
        ticketDistId: null,
        ticketEmail: "",
        ticketEnggId: null,
        ticketMobile: "",
        ticketShortDesc: null,
        ticketStatus: "OPEN",
        ticketType: "",
        ticketUpDt: null,
        ticketUpUid: null
    }

    async function loadStaticData() {
        setSupportEngineer(await getSupportEngineer());
        setTicketStatus(await getTicketStatus());
    }

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    const ticketId = useQuery().get("id");

    const handleCancle = () => {
        props.history.push('/ticket');
    };

    const handleFileChange = (e) => {
        
        setFieldValue("files", e.target.files);
        //handleAddFiles(e.target.files)
        //e.target.value = '';
        let newAddedFiles = [];
        for (var i = 0; i < e.target.files.length; i++) {

            newAddedFiles[i] = {
                tfCrDt: e.target.files[i].lastModified,
                tfCrUid: props.userId,
                tfFilePath: e.target.files[i].name,
                tfUpDt: null,
                tfUpUid: null
            }
            props.values.atTicketFiles.unshift(newAddedFiles[i]);
        }
        setFieldValue(props.values.atTicketFiles);
        setTicketFiles(props.values.atTicketFiles);
    };

    //const handleAddFiles = (files) => {
    //    
    //    let newAddedFiles = [];
    //    for (var i = 0; i < files.length; i++) {

    //        newAddedFiles[i] = {
    //            tfCrDt: files[i].lastModified,
    //            tfCrUid: props.userId,
    //            tfFilePath: files[i].name,
    //            tfUpDt: null,
    //            tfUpUid: null
    //        }
    //        props.values.atTicketFiles.unshift(newAddedFiles[i]);
    //    }
    //    setFieldValue(props.values.atTicketFiles);
    //    setTicketFiles(props.values.atTicketFiles);
    //};


    const handleDescriptionChange = (e) => {
        
        setFieldValue("Description", e.target.value);
    };

    const handleAddConversations = () => {
        
        let newAddedConversations = [];

        newAddedConversations = {
            tcComments: props.values.Description,
            tcCrDt: null,
            tcCrUid: props.userId,
            tcUpDt: null,
            tcUpUid: null
        }
        props.values.atTicketConversions.unshift(newAddedConversations);
        setFieldValue(props.values.atTicketConversions);
        setTicketConversions(props.values.atTicketConversions);
        values.Description = "";
    }

    let defaultStatus = ticketStatus.find(function (e) {
        return e.key.match("OPEN");
    });

    //var x;
    //x = defaultStatus.value;

    useEffect(() => {

        loadStaticData();
        findDistributorListAPI(userId).then(data => {
            let newDistArray = data.map(i => {
                let option = { key: i.distId, value: i.distDistName }
                return option
            })
            setDistributorList(newDistArray);
        })


        if (ticketId != "") {
            findTicketById(ticketId).then(data => {
                setValues(data[0]);
                setTicketFiles(data[0].atTicketFiles.reverse());
                setTicketConversions(data[0].atTicketConversions.reverse());
            });
        }
        else {
            setValues(initData);
        }
    }, []);
    //

    ////var json = JSON.parse(ticketStatus);
    ////var status = json["value"]
    
    //for (let key in defaultStatus) {
    //    let curObj = defaultStatus[key];
    //    console.log("default", curObj['value'])
    //}


    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-12 mt-20">
                    <div className="panel panel-default">
                        <div className="panel-title text-left">Ticket
                            <button
                                className="btn btn-cancle"
                                style={{ float: "right" }}
                                type="button btn"
                                onClick={handleCancle}>
                                {"Cancel"}
                            </button>
                            <button
                                className={isSubmitting ? " btn btn-success btn-save disabled" : "btn btn-save btn-success"}
                                style={{ float: "right" }}
                                type="submit"
                                onClick={handleSubmit}>
                                {"Save"}
                            </button>

                        </div>
                        <div className="editpanel">

                            <form onSubmit={handleSubmit} /*className={isSubmitting ? "disabled" : ""}*/>
                                {isSubmitting ?
                                    <DisappearedLoading color="#5f6973" />
                                    : ("")}
                                <div className="row">
                                    {schema.map((item, index) => {
                                        return (
                                            <div key={index} >
                                                {ticketId ? (
                                                    <div>
                                                        {item.backendMappingName === "ticketShortDesc" ? (

                                                            <div className="col-md-12 display-flex mt-10" >
                                                                <label className="col-md-2">
                                                                    {item.FieldName} {item.Mandatory && <span className="error">* </span>}
                                                                </label>

                                                                <div className={item.Editable === "No" ? "col-md-10 mb-20 disabled" : "col-md-10 mb-20"}>
                                                                    <Field
                                                                        name={item.backendMappingName}
                                                                        component={InputBox}
                                                                        type={"text"}
                                                                        validate={item.Mandatory && required.bind(this, item.FieldName)}
                                                                        placeholder={item.FieldName}
                                                                        value={values[item.backendMappingName] || ''}
                                                                    />
                                                                </div>
                                                            </div>
                                                        ) : (
                                                                <div className="col-md-6 display-flex mt-10" >
                                                                    <label className="col-md-6">
                                                                        {item.FieldName} {item.Mandatory && <span className="error">* </span>}
                                                                    </label>

                                                                    {item.ObjectType === "Textbox" ? (
                                                                        <div className={item.backendMappingName === "ticketId" ? "col-md-6 form_input disabled" : "col-md-6 form_input"}>
                                                                            <Field
                                                                                name={item.backendMappingName}
                                                                                component={InputBox}
                                                                                type={"text"}
                                                                                validate={item.backendMappingName === "ticketMobile" ? (validatePhone) : item.backendMappingName === "ticketEmail" ? (email) : (item.Mandatory && required.bind(this, item.FieldName))}
                                                                                placeholder={item.FieldName}
                                                                                value={values[item.backendMappingName] || ''}
                                                                                style={item.backendMappingName === "ticketId" ? { color: "#000", fontSize: "14px", fontWeight: "800" } : {}}
                                                                            />
                                                                        </div>
                                                                    ) : (
                                                                            <>
                                                                                {userSupportAdmin === null ? (
                                                                                    <div className={item.backendMappingName === "ticketDistId" ? "col-md-6 form_input" : "col-md-6 form_input disabled"}>
                                                                                        <Select
                                                                                            id={item.backendMappingName}
                                                                                            name={item.backendMappingName}
                                                                                            value={values[item.backendMappingName] ? values[item.backendMappingName] : ticketStatus.key}
                                                                                            options={eval(item.Source)}
                                                                                            onChange={(field, value) => {
                                                                                                setFieldValue(field, value);
                                                                                            }}
                                                                                            validate={item.Mandatory && requiredField(values[item.backendMappingName])}
                                                                                        />
                                                                                    </div>
                                                                                ) : (
                                                                                        <div className="col-md-6 form_input">
                                                                                            <Select
                                                                                                id={item.backendMappingName}
                                                                                                name={item.backendMappingName}
                                                                                                value={values[item.backendMappingName] || null}
                                                                                                options={eval(item.Source)}
                                                                                                onChange={(field, value) => {
                                                                                                    setFieldValue(field, value);
                                                                                                }}
                                                                                                validate={item.Mandatory && requiredField(values[item.backendMappingName])}
                                                                                            />
                                                                                        </div>
                                                                                    )}
                                                                            </>
                                                                        )}
                                                                </div>
                                                            )}
                                                    </div>
                                                ) :
                                                    (
                                                        <>
                                                            {item.backendMappingName !== "ticketId" ? (
                                                                <div>
                                                                    {item.backendMappingName === "ticketShortDesc" ? (

                                                                        <div className="col-md-12 display-flex mt-10" >
                                                                            <label className="col-md-2">
                                                                                {item.FieldName} {item.Mandatory && <span className="error">* </span>}
                                                                            </label>

                                                                            <div className={item.Editable === "No" ? "col-md-10 mb-20 disabled" : "col-md-10 mb-20"}>
                                                                                <Field
                                                                                    name={item.backendMappingName}
                                                                                    component={InputBox}
                                                                                    type={"text"}
                                                                                    validate={item.Mandatory && required.bind(this, item.FieldName)}
                                                                                    placeholder={item.FieldName}
                                                                                    value={values[item.backendMappingName] || ''}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    ) : (

                                                                            < div className="col-md-6 display-flex mt-10" >
                                                                                <label className="col-md-6">
                                                                                    {item.FieldName} {item.Mandatory && <span className="error">* </span>}
                                                                                </label>

                                                                                {item.ObjectType === "Textbox" ? (
                                                                                    <div className="col-md-6 form_input">
                                                                                        <Field
                                                                                            name={item.backendMappingName}
                                                                                            component={InputBox}
                                                                                            type={"text"}
                                                                                            validate={item.backendMappingName === "ticketMobile" ? (validatePhone) : item.backendMappingName === "ticketEmail" ? (email) : (item.Mandatory && required.bind(this, item.FieldName))}
                                                                                            placeholder={item.FieldName}
                                                                                            value={values[item.backendMappingName] || ''}
                                                                                        />
                                                                                    </div>
                                                                                ) : (
                                                                                        <>
                                                                                            {userSupportAdmin === null ? (
                                                                                                <div className={item.backendMappingName === "ticketDistId" ? "col-md-6 form_input" : "col-md-6 form_input disabled"}>
                                                                                                    <Select
                                                                                                        id={item.backendMappingName}
                                                                                                        name={item.backendMappingName}
                                                                                                        value={values[item.backendMappingName]}
                                                                                                        options={eval(item.Source)}
                                                                                                        onChange={(field, value) => {
                                                                                                            setFieldValue(field, value);
                                                                                                        }}
                                                                                                        validate={item.Mandatory && requiredField(values[item.backendMappingName])}
                                                                                                    />
                                                                                                </div>
                                                                                            ) : (
                                                                                                    <div className="col-md-6 form_input">
                                                                                                        <Select
                                                                                                            id={item.backendMappingName}
                                                                                                            name={item.backendMappingName}
                                                                                                            value={values[item.backendMappingName] || null}
                                                                                                            options={eval(item.Source)}
                                                                                                            onChange={(field, value) => {
                                                                                                                setFieldValue(field, value);
                                                                                                            }}
                                                                                                            validate={item.Mandatory && requiredField(values[item.backendMappingName])}
                                                                                                        />
                                                                                                    </div>
                                                                                                )}
                                                                                        </>
                                                                                    )}
                                                                            </div>
                                                                        )}
                                                                </div>
                                                            ) :
                                                                ("")
                                                            }
                                                        </>
                                                    )}
                                            </div>
                                        );
                                    })}

                                    <div className="col-md-12 display-flex mt-10" >
                                        <label className="col-md-2" style={{ marginLeft: "2px" }}>
                                            File Upload:
                                        </label>
                                        <div className="col-md-10 mb-20">
                                            <input
                                                id="files"
                                                type="file"
                                                onChange={handleFileChange}
                                                multiple
                                            />
                                        </div>
                                        {/* <button
                                            className={values.files ? "btn btn-success btn-save " : "btn btn-save disabled"}
                                            type="button"
                                            onClick={() => handleAddFiles(values.files)}
                                            style={{ margin: "3px 10px 0px 0px", width: "45px", height: "25px" }}
                                        >
                                            <span className="glyphicon glyphicon-plus"></span>
                                        </button>*/}
                                    </div>

                                    <div className="col-md-12 display-flex mt-10">
                                        <table className="table" style={{ marginLeft: "18%" }}>
                                            <thead>
                                                <tr>
                                                    <th scope="col" style={{ width: "35%" }}>File Path</th>
                                                    <th scope="col" style={{ width: "20%" }}>Created Date</th>
                                                    <th scope="col" style={{ width: "20%" }}>Download File</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {ticketFiles.map((item, index) => {
                                                    return (
                                                        <tr className="border" key={index} >
                                                            <td>{item.tfFilePath}</td>
                                                            <td>{moment(item.tfCrDt).format('DD-MMM-YYYY h:m A')}</td>
                                                            <td>
                                                                <a onClick={()=>downloadFile(`${BASE_URL}/${ticketId}/${item.tfFilePath}`,item.tfFilePath)}>
                                                                    <span className="glyphicon glyphicon-download-alt"></span>
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}

                                            </tbody>
                                        </table>
                                    </div>


                                    <div className="col-md-12 display-flex mt-10" >
                                        <label className="col-md-2" style={{ width: "20%" }}>
                                            Comments:
                                        </label>
                                        <div className="col-md-10 form_input" style={{ marginLeft: "7px" }}>
                                            <Field
                                                id="Description"
                                                name="Description"
                                                component={TextArea}
                                                type={"text"}
                                                placeholder="Description"
                                                onChange={handleDescriptionChange}
                                            />
                                        </div>
                                        <button
                                            className={values.Description ? "btn btn-success btn-save " : "btn btn-save disabled"}
                                            type="button"
                                            onClick={handleAddConversations}
                                            style={{ margin: "10px 10px 0px 0px", width: "45px", height: "25px" }}
                                        >
                                            <span className="glyphicon glyphicon-plus"></span>
                                        </button>
                                    </div>

                                    <div className="col-md-12 display-flex mt-10" style={{ display: "block", marginLeft: "16%" }} >
                                        {ticketConversions.map((item, index) => {
                                            return (
                                                <div key={index} className="timeline" style={{ width: "70%", fontWeight: "Bold" }} >
                                                    <Timeline>
                                                        <TimelineEvent
                                                            title={item.tcCrDt ? moment(item.tcCrDt).format('DD-MMM-YYYY h:m A') : moment().format('DD-MMM-YYYY h:m A')}
                                                            container="card"
                                                            style={{
                                                                boxShadow: '0 0 6px 1px',
                                                                border: '1px solid #777',
                                                                borderRadius: 3,
                                                            }}
                                                            cardHeaderStyle={{ backgroundColor: 'rgb(220,220,220)', color: 'rgb(0, 0, 0)' }}
                                                            icon={<span className="glyphicon glyphicon-envelope"></span>}
                                                            iconColor="rgb(111, 186, 28)"
                                                            collapsible
                                                        >
                                                            <p style={{
                                                                fontWeight: 'Bold', fontSize: '14px'
                                                            }}>{item.tcComments}</p>
                                                        </TimelineEvent>
                                                    </Timeline>
                                                </div>
                                            );
                                        })}
                                    </div>

                                </div>
                            </form>
                            <div className="col-md-12 mt-10 display-flex">
                                <button
                                    className={isSubmitting ? " btn btn-success btn-save disabled" : "btn btn-save btn-success"}
                                    style={{
                                        marginLeft: "auto"
                                    }}
                                    type="submit"
                                    onClick={handleSubmit}>
                                    {"Save"}
                                </button>
                                <button
                                    className="btn btn-cancle"
                                    type="button btn"
                                    onClick={handleCancle}>
                                    {"Cancel"}
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div >
    );
};

const mapStateToProps = state => {
    return {
        userId: getValue(state, ["loginReducer", "userId"]),
        userSupportAdmin: getValue(state, ["loginReducer", "usrSupportAdmin"])
    };
};

const EditTicketViews = connect(mapStateToProps)(formikEnhancer(EditTicketView))

export default EditTicketViews;






