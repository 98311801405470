import React, { useState } from "react";
import { connect } from "react-redux";
import { getValue } from "../../common/helpers/validations";
import { getMenuByUsrIdAndModuleId } from "./actionCreator";
//import "./styles.css";
//import { rehydrateReducer } from "../login/actionCreator";

let Dashboard = props => {
    const {
        getMenuByUsrIdAndModuleId,
        userId,
        history
    } = props;

    const cards = getValue(props, ["cards", "dashboardOptions"]) || [];
    
    console.log("cards" + cards);

    function navigateAndCallApi(pageId, pageName) {
       
        getMenuByUsrIdAndModuleId(pageId, userId, history);
        sessionStorage.setItem('defaultRoute', pageName)
    }
 
    return (
        <div>
            <div
                className="row presentation p-0 m-0"
            >
                <div
                    className="col-lg-12 col-md-12 titles"
                    style={{ textAlign: "center", top: "-12px" }}
                >
                    <h1>DISTRIBUTOR SMART ZONE</h1>
                </div>
            </div>


            <div className="container" style={{ marginTop: "30px" }}>
                <div className="row">
                    {cards.map((card, i) => (
                        <div className="col-lg-3" key={i} onClick={() => navigateAndCallApi(card.moduleId, card.defaultRoute)}>
                            <div className="module_cnt">
                                <img
                                    src={"/img/" + card.moduleName + ".png"}
                                    alt="market_img"
                                    style={{ height: "140px" }}
                                />
                                <h2 style={{ fontWeight: "500", }}>{card.moduleName}</h2>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        cards: state.permissionsReducer,
        userId: state.loginReducer.userId
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getMenuByUsrIdAndModuleId: (pageId, id, history) =>
            dispatch(getMenuByUsrIdAndModuleId(pageId, id, history)),
    };
};

export default Dashboard = connect(
    mapStateToProps,
    mapDispatchToProps
)(Dashboard);
