import { BASE_URL } from "../helpers/urlsConstants";
import { post } from 'axios';

//export const loginAPI = async (email, password) => {
//    const response = await fetch(`${BASE_URL}/validateUserById?usrLoginid=${email}&usrPassword=${password}`);
//    const resp = await response.json();
//    //console.log("usertype", resp);
//    if (!resp.userFoud) {
//        return false //! please spell check with backend 
//    } else {
//        return resp
//    }
//}

export const loginAPI = (email, password) => {
    const authData = {
        method: "POST",
        headers: new Headers()
    };
    const config = {
        headers: {
            "accept": "*/*"
        }
    };
    const url = `${BASE_URL}/validateUserById`;
    const formData = new FormData();

    formData.append("usrLoginid", email);
    formData.append("usrPassword", password);

    return post(url, formData,authData,config);
};

