import { BASE_URL } from "../../../../common/helpers/urlsConstants";
import axios, { post } from "axios";



export const Reprocessdata = async (inputJson, tokenId) => {
    debugger;
    tokenId = sessionStorage.getItem("tokenId")
    var token = tokenId.replace(/['"]+/g, '');
    const authData = {
        method: "POST",
        headers: {
            "Authorization": `Bearer ${token}`,
            "content-type": "multipart/form-data"
        }
    };
    const url = `${BASE_URL}/Reprocessdata?inputParamJson=${inputJson}`;

    //const formData = new FormData();
    //formData.append("inputParamJson", inputJson);

    return post(url, authData);
};
