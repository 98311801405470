import React from "react";
import { Link } from 'react-router-dom'
import moment from 'moment';
import { deleteSmSuMappingById, findsmsuMappingList } from "./api";


export const tableColumHeaders = (userCompanyId, setSuMappingList) => {
    function onDelete(suMappingId) {
        deleteSmSuMappingById(suMappingId).then(resp => {
            debugger;
            if (resp) {
                alert(`SuMapping Data of ${suMappingId} Deleted `);
                findsmsuMappingList(userCompanyId).then(data => {
                    setSuMappingList(data[0]);
                });
            }
        })
    }

    return [
        {
            Header: "SSA ID",
            accessor: "sumId",
            sortable: true,
            filterable: true,
            style: {
                textAlign: "right"
            },
            maxWidth: 60
        },
        {
            Header: "Company",
            accessor: "company",
            sortable: true,
            filterable: true,
            style: {
                textAlign: "left"
            },
            maxWidth: 80
        },
        {
            Header: "Distributor",
            accessor: "distName",
            sortable: true,
            filterable: true,
            style: {
                textAlign: "left"
            },
            maxWidth: 100
        },
        {
            Header: "Customer",
            accessor: "customer",
            sortable: true,
            filterable: true,
            style: {
                textAlign: "left"
            },
            maxWidth: 100
        },
        {
            Header: "Engineer",
            accessor: "enggName",
            sortable: true,
            filterable: true,
            style: {
                textAlign: "left"
            },
            maxWidth: 300
        },
        {
            Header: "Product",
            accessor: "product",
            sortable: true,
            filterable: true,
            style: {
                textAlign: "left"
            },
            maxWidth: 100
        },
        {
            Header: "SU",
            accessor: "suName",
            sortable: true,
            filterable: true,
            style: {
                textAlign: "left"
            },
            maxWidth: 60
        },
        {
            Header: "From Date",
            accessor: "sumFromDt",
            sortable: true,
            maxWidth: 150,
            style: {
                textAlign: "center",
            },
            Cell: props => {

                if (props.original.sumFromDt !== null || props.original.sumFromDt !== "" || props.original.sumFromDt !== 0) {
                    return (
                        moment(props.original.sumFromDt).format('DD/MM/YYYY')
                    )
                }
                else {
                    return (
                        moment().format('DD/MM/YYYY')
                    )
                }
            }
        },
        {
            Header: "To Date",
            accessor: "sumToDt",
            sortable: true,
            filterable: true,
            style: {
                textAlign: "center"
            },
            maxWidth: 150,
            Cell: props => {
                if (props.original.sumToDt !== null || props.original.sumToDt !== "" || props.original.sumToDt !== 0) {
                    return (
                        moment(props.original.sumToDt).format('DD/MM/YYYY')
                    )
                }
                else {
                    return (
                        moment().format('DD/MM/YYYY')
                    )
                }
            }
        },
        {
            Header: "Action",
            sortable: false,
            maxWidth: 150,
            filterable: false,
            Cell: props => {
                return (
                    <div className="text-center">
                        <Link
                            className={'mr-3 btn-edit'}
                            style={{ color: "#000000" }}
                            to={`/Cust-Egg-SU-mapping/editSuMappingData/?id=${(props && props.original) ? props.original.sumId : 0}`}
                        >
                            <span className="glyphicon glyphicon-edit"></span>
                        </Link>
                        <Link
                            className="btn-delete"
                            style={{ color: "#000000" }}
                            onClick={() => onDelete(props.original.sumId)}
                            to="#">
                            <span className="glyphicon glyphicon-trash"></span>
                        </Link>
                    </div>
                );
            }
        }
    ];
};


