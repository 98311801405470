import React from "react";
import { Link } from 'react-router-dom'
//import { deleteUserById, findUserList } from "./api";


export const tableColumHeaders = (userId, setUsersList) => {
    function onDelete(data) {
        //deleteUserById(data).then(resp => {
        //    if (resp) {
        //        alert(`User ${data} Deleted `);
        //        findUserList(userId).then(data => {
        //            setDistributorList(data.content);
        //        });
        //    }
        //})
    }

    return [
        {
            Header: "Henkel IDH",
            accessor: "usrIdh",
            sortable: true,
            maxWidth: 110,
            style: {
                textAlign: "right",
            }
        },
        {
            Header: "Name",
            accessor: "userName",
            sortable: true,
            filterable: true,
            style: {
                textAlign: "left"
            },
            maxWidth: 700
        },
        {
            Header: "Email Id",
            accessor: "usrLoginId",
            sortable: false,
            filterable: false,
            style: {
                textAlign: "left"
            },
            maxWidth: 400
        },
        {
            Header: "User Type",
            accessor: "userType",
            sortable: false,
            filterable: false,
            style: {
                textAlign: "left"
            },
            maxWidth: 200
        },
        {
            Header: "Role",
            accessor: "userRole",
            sortable: false,
            filterable: false,
            style: {
                textAlign: "left"
            },
            maxWidth: 120
        },
        {
            Header: "Mobile Number",
            accessor: "usrMobileNo",
            sortable: false,
            filterable: false,
            style: {
                textAlign: "Center"
            },
            maxWidth: 150
        },
        {
            Header: "Action",
            sortable: false,
            maxWidth: 170,
            filterable: false,
            Cell: props => {
                return (
                    <div className="text-center">
                        <Link
                            className={'mr-3 btn-edit'}
                            style={{ color: "#000000" }}
                            to={`/user/editUser/?id=${(props && props.original) ? props.original.usrId : 0}`}
                        >
                            <span className="glyphicon glyphicon-edit"></span>
                        </Link>
                        {/* <Link
                            className="btn-delete"
                            style={{ color: "#000000" }}
                            onClick={() => onDelete(props.original.usrId)}
                            to="#">
                            <span className="glyphicon glyphicon-trash"></span>
                        </Link> */}
                    </div>
                );
            }
        }
    ];
};


