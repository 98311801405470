import { Modal, ModalTitle } from 'react-bootstrap';
import React, { useState, useEffect } from "react";
import { InputBox } from "../../../../common/components/InputBox";
import { connect } from "react-redux";
import { Field, Formik } from "formik";
import { forgotPassword } from "./api";
import Notification from '../../../../common/notification'
import { validateEmail, getValue } from "../../../../common/helpers/validations";


let ForgotPassword = props => {

    const [status, setStatus] = useState(false);
    const [formSubmitting, setformSubmitting] = useState(false);
    const [formSubmitted, setformSubmitted] = useState(false);
    let { userId } = props;
    const { show, closeModal } = props;

    const handleClose = () => {
        closeModal();
        setTimeout(() => {
            setStatus(false)
            setformSubmitting(false)
        }, 1000
        )
    }

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header>
                <ModalTitle>

                    Forgot Password
                    <button onClick={handleClose} className="close" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </ModalTitle>
            </Modal.Header>
            <Modal.Body>
                <div className="row">

                    <Formik initialValues={{
                        user: null
                    }} onSubmit={values => {

                        setformSubmitting(true);
                        forgotPassword(values.user).then(response => {
                            if (response.status === 200) {
                                Notification.success('Password reset successfully.');
                                setformSubmitted(true);
                                setStatus(response.status);
                                setformSubmitting(true);
                            }
                            else {
                                setformSubmitting(false);
                                setStatus(false);
                            }
                        }).catch(err => {
                            setformSubmitting(false);
                            return err;
                        })
                    }}
                    >
                        {({ handleSubmit, values, setFieldValue }) => (

                            <form className={formSubmitting ? "disabled" : ""} onSubmit={handleSubmit}>
                                <div className="col-md-12 mt-10 text-center" style={{ fontSize: "14px", color: "red" }}>
                                    <p> Please enter your login Id to reset the password.</p>
                                </div>

                                <div className="col-md-12 mt-10 mb-25">
                                    <div className="col-md-2">
                                        <label htmlFor="LoginId" style={{ display: "block" }}>
                                            Login Id
                                        </label>
                                    </div>
                                    <div className="col-md-10">
                                        <Field
                                            id="user"
                                            name="user"
                                            component={InputBox}
                                            type={"text"}
                                            value={values.user || ""}
                                            placeholder="Login Id"
                                            onChange={(e) => {
                                                setFieldValue("user", e.target.value);
                                            }}
                                            validate={validateEmail.bind(this, 'Login Id')}
                                        />

                                    </div>
                                </div>

                                <div className="col-md-12" style={{ marginBottom: "12px" }}>
                                    <div className="col-md-4">
                                    </div>
                                    <div className="col-md-8">
                                        <button
                                            className={formSubmitting ? " btn btn-success btn-save disabled" : "btn btn-save btn-success"}
                                            type="submit"
                                            onClick={handleSubmit}>
                                            {"Submit"}
                                        </button>
                                        <button type="button" className="btn btn-cancle" onClick={handleClose}>Cancel</button>
                                    </div>

                                </div>
                            </form>
                        )}
                    </Formik>

                    {status && (
                        <div className="alert alert-success cursor mt-10" role="alert" style={{ clear: "both", marginLeft: "15px", width: "95%" }}>
                            {'Password reset successfully.Please check your email.'}
                        </div>
                    )}
                </div>
            </Modal.Body>
        </Modal >

    );
};
const mapStateToProps = state => {
    return {
        userId: getValue(state, ["loginReducer", "userId"])
    };
};


export default ForgotPassword = connect(mapStateToProps)(ForgotPassword);


