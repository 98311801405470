import { post } from "axios";
import { BASE_URL } from '../../../../common/helpers/urlsConstants';



export const findProductList = async (id, tokenId) => {
    tokenId = sessionStorage.getItem("tokenId")
    var token = tokenId.replace(/['"]+/g, '');
    const authData = {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${token}`
        }
    };
    const url = `${BASE_URL}/product/findProductList?page=0&size=20000&sort=productId&companyId=${id}`;
    return fetch(url, authData).then(response => response.json()).then(resp => {
        if (!resp) {
            return [];
        } else {
            return [resp];
        }
    })
};


export const getCustomerList = async (distId, tokenId) => {
    tokenId = sessionStorage.getItem("tokenId")
    var token = tokenId.replace(/['"]+/g, '');
    const authData = {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${token}`
        }
    };
    const url = `${BASE_URL}/customer/getCustomerDetailsByCustDistId?distId=${distId}`;
    return fetch(url, authData).then(response => response.json()).then(resp => {

        if (!resp) {
            return [];
        } else {
            return resp;
        }
    })
};


export const findsmsuMappingList = async (userCompanyId, tokenId) => {
    tokenId = sessionStorage.getItem("tokenId")
    var token = tokenId.replace(/['"]+/g, '');
    const authData = {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${token}`
        }
    };
    const url = `${BASE_URL}/SmSuMapping/findsmsuMappingList?page=0&size=50000&sumCompanyid=${userCompanyId}`;
    return fetch(url, authData).then(response => response.json()).then(resp => {
        if (!resp.content) {
            return [];
        } else {
            return [resp.content];
        }
    })
};


export const findSmSuMappingById = async (suMappingId, tokenId) => {

    tokenId = sessionStorage.getItem("tokenId")
    var token = tokenId.replace(/['"]+/g, '');
    const authData = {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${token}`
        }
    };
    const url = `${BASE_URL}/SmSuMapping/findSmSuMappingById?id=${suMappingId}`;
    return fetch(url, authData).then(response => response.json()).then(resp => {
        if (!resp) {
            return [];
        } else {
            return [resp];
        }
    })
};



export const saveOrUpdateProductDrp = async (data, tokenId) => {
    tokenId = sessionStorage.getItem("tokenId")
    var token = tokenId.replace(/['"]+/g, '');
    const authData = {
        method: "POST",
        headers: {
            "Authorization": `Bearer ${token}`,
            "accept": "*/*",
            "Content-Type": "application/json",
        }
    };

    const url = `${BASE_URL}/SmSuMapping/saveOrUpdateProductDrp`;

    return post(url, data, authData);
};


export const deleteSmSuMappingById = async (suMappingId, tokenId) => {
    tokenId = sessionStorage.getItem("tokenId")
    var token = tokenId.replace(/['"]+/g, '');
    const authData = {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${token}`
        }
    };
    const url = `${BASE_URL}/SmSuMapping/deleteSmSuMappingById?id=${suMappingId}`;
    return fetch(url, authData).then(response => response.json()).then(resp => {
        if (resp.status_code = 200) {
            return true;
        } else {
            return false;
        }
    })
};