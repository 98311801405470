import React, { useEffect, useState } from "react";
import { Field, withFormik } from "formik";
import { InputBox } from "../../../../common/components/InputBox";
//import Select from "../../../../common/components/Select";
import Select from 'react-select';
import schema from "./editUserViewFields.json"
import { findUserAdminById, saveOrUpdateUserAdmin } from "./api";
import { useLocation, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import Notification from '../../../../common/notification'
import { required, requiredField, validatePhone, validateEmail, getValue } from "../../../../common/helpers/validations";
import { getCompany, getZone, getUserType, getDepartment, getDmtRoles, getRole, getReportingManager } from "../../../../services/staticdata";
//import  CheckboxSelect  from '@atlaskit/select';
import { DisappearedLoading } from 'react-loadingg';


const formikEnhancer = withFormik({

    mapPropsToValues: ({ user, props }) => ({
        ...user,
        ...props
    }),
    handleSubmit: (payload, { setSubmitting, props }) => {
        setSubmitting(true);
        saveOrUpdateUserAdmin(payload).then(resp => {
            if (resp.status === 200) {
                Notification.success('saved successful.');
                props.history.goBack()
            }
            setSubmitting(false);
        })

    },
    displayName: "MyForm"
});


let EditUserView = (props) => {

    let { userId, userCompanyId,usrSupportAdmin } = props;
    const { handleSubmit, setFieldValue, setValues, values, isSubmitting } = props;

    const [zone, setZone] = useState([]);
    const [company, setCompany] = useState([]);
    const [usertype, setUsertype] = useState([]);
    const [department, setDepartment] = useState([]);
    const [dmtRoles, setDmtRoles] = useState([]);
    const [role, setRoles] = useState([]);
    const [reportingManager, setReportingManager] = useState([]);

    const [userRole, setUserRole] = useState([]);
    const [userTypes, setUserTypes] = useState([]);
    const [userReportingManager, setUserReportingManager] = useState([]);
    const [userZone, setUserZone] = useState([]);
    const [userDepartment, setUserDepartment] = useState([]);
    const [userDmtRoles, setUserDmtRoles] = useState([]);
    const [userCompany, setUserCompany] = useState([]);
    const [checked, setchecked] = useState();

    const initData = {
        amUserDepts: [],
        amUserMaps: [],
        amUserTypes: [],
        amUserRoles: [],
        amUserZones: [],
        usrActive: 0,
        usrAddress: "",
        usrCompanyid: 0,
        usrCrDt: null,
        usrCrUid: userId,
        usrDob: null,
        usrFirstName: "",
        usrHomepage: null,
        usrIdh: "",
        usrLastName: "",
        usrLastloginDt: null,
        usrLastpwdchgDt: null,
        usrLoginid: "",
        usrOldPassword: "",
        usrMobileno: "",
        usrPassword: "",
        usrSbu: 0,
        usrSupportAdmin: null,
        usrType: "",
        usrUpDt: 0,
        usrUpUid: null,
    }

    async function loadStaticData() {
        setZone(await getZone());
        setCompany(await getCompany());
        setUsertype(await getUserType());
        setDepartment(await getDepartment());
        setDmtRoles(await getDmtRoles());
        setRoles(await getRole());
        setReportingManager(await getReportingManager());
    }

    let filteredDepartment = department.filter(function (e) {
        return e.parent.match(userCompanyId);
    });

    let filteredCompany = company.filter(function (e) {
        return e.key == userCompanyId;
    });

    let filteredReportingManager = reportingManager.filter(function (e) {
        return e.usr_companyid.match(userCompanyId)
    });

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    const id = useQuery().get("id");
    const readOnly = useQuery().get("read");

    const history = useHistory()
    const handleCancle = () => {
        history.goBack()
    };

    const customStyles = {

        valueContainer: (provided, state) => ({
            ...provided,
            height: '30px',
            padding: '0px 8px'
        }),
    };

    const handleDmtRoles = (e) => {
        if (e.target.checked) {
            setchecked(e.target.checked);
        }
        else {
            setchecked(e.target.checked);
        }
    }



    const onSelectChange = (name, e) => {
        switch (name) {
            case 'Role':
                setUserRole(e);
                let newRole = [];
                if (e != null) {
					values.amUserRoles = [];
                    for (var i = 0; i < e.length; i++) {
                        if (e[i].key != "" && e[i].key != 0 && e[i].key != undefined) {
                            //let j = e.length - 1
                            newRole = {
                                urActive: 0,
                                urCrDt: null,
                                urCrUid: userId,
                                urRhId: e[i].key,
                                urUpDt: null,
                            }
                            values.amUserRoles.unshift(newRole);
                            //break;
                        }
                        else {
                            values.amUserRoles = e;
                        }
                    }
                }
                break;

            case 'UserType':
                setUserTypes(e);
                let newUserType = [];
                if (e != null) {
					values.amUserTypes = [];
                    for (var i = 0; i < e.length; i++) {
                        if (e[i].key != "" && e[i].key != 0 && e[i].key != undefined) {
                            //let j = e.length - 1
                            newUserType = {
                                utCrDt: null,
                                utTypeId: e[i].key,
                                utCrUid: userId,
                                utUpDt: null,
                            }
                            values.amUserTypes.unshift(newUserType);
                            //break;
                        }
                        else {
                            values.amUserTypes = e;
                        }
                    }
                }
                break;

            case 'ReportingManager':
                setUserReportingManager(e);
                let newReportingManager = [];
                if (e != null) {
					values.amUserMaps = [];
                    for (var i = 0; i < e.length; i++) {
                        if (e[i].key != "" && e[i].key != 0 && e[i].key != undefined) {
                            //let j = e.length - 1
                            newReportingManager = {
                                umCrDt: null,
                                umCrUid: userId,
                                umParentId: e[i].key,
                                umUpDt: null,
                                umUpUid: null,
                            }
                            values.amUserMaps.unshift(newReportingManager);
                            //break;
                        }
                        else {
                            values.amUserMaps = e;
                        }
                    }
                }
                break;

            case 'Zone':
                setUserZone(e);
                let newZone = [];
                if (e != null) {
					values.amUserZones = [];
                    for (var i = 0; i < e.length; i++) {
                        if (e[i].key != "" && e[i].key != 0 && e[i].key != undefined) {
                            //let j = e.length - 1
                            newZone = {
                                uzCrDt: null,
                                uzCrUid: userId,
                                uzUpDt: null,
                                uzUpUid: null,
                                uzZoneId: e[i].key,
                            }
                            values.amUserZones.unshift(newZone);
                            //break;
                        }
                        else {
                            values.amUserZones = e;
                        }
                    }
                }
                break;

            case 'Department':
                setUserDepartment(e);
                let newDepartment = [];
                if (e != null) {
					values.amUserDepts = [];
                    for (var i = 0; i < e.length; i++) {
                        if (e[i].key != "" && e[i].key != 0 && e[i].key != undefined) {
                          //  let j = e.length - 1
                            newDepartment = {
                                udCrDt: null,
                                udCrUid: userId,
                                udDeptId: e[i].key,
                                udUpDt: null,
                                udUpUid: null,
                            }
                            values.amUserDepts.unshift(newDepartment);
                           // break;
                        }
                        else {
                            values.amUserDepts = e;
                        }
                    }
                }
                break;

            case 'DMTRoles':
                setUserDmtRoles(e);
                break;

            case 'Company':
                setUserCompany(e);
                values.usrCompanyid = e.key;
                break;
        }
        //console.log("values", e);
    };



    useEffect(() => {
        loadStaticData();
        (async () => {
            var uDept = await getDepartment();
            var uType = await getUserType();
            var uRole = await getRole();
            var uZone = await getZone();
            var uReManager = await getReportingManager();
            var ucompany = await getCompany();

            if (id) {
                findUserAdminById(id).then(data => {
                    console.log("usrSupportAdmin:",1);

                    setValues(data[0]);                    
                    setUserCompany({ label: ucompany.find(e => e.key === data[0].usrCompanyid).label, value: ucompany.find(e => e.key === data[0].usrCompanyid).value });                    
                    setUserTypes(data[0].amUserTypes.map(y => { return { ...y, label: uType.find(e => e.key === y.utTypeId).label, value: uType.find(e => e.key === y.utTypeId).label } }));
                    setUserDepartment(data[0].amUserDepts.map(y => { return { ...y, label: uDept.find(e => e.key === y.udDeptId).label, value: uDept.find(e => e.key === y.udDeptId).label } }));
                    setUserTypes(data[0].amUserTypes.map(y => { return { ...y, label: uType.find(e => e.key === y.utTypeId).label, value: uType.find(e => e.key === y.utTypeId).label } }));
                    setUserRole(data[0].amUserRoles.map(y => { return { ...y, label: uRole.find(e => e.key === y.urRhId).label, value: uRole.find(e => e.key === y.urRhId).label } }));
                    setUserZone(data[0].amUserZones.map(y => { return { ...y, label: uZone.find(e => e.key === y.uzZoneId).label, value: uZone.find(e => e.key === y.uzZoneId).label } }));
                    setUserReportingManager(data[0].amUserMaps.map(y => { return { ...y, label: uReManager.find(e => e.key === y.umParentId).label, value: uReManager.find(e => e.key === y.umParentId).label } }));
                });
            }
            else {
                setValues(initData);
            }
        })()
    }, [id]);

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-12 mt-20">
                    <div className="panel panel-default">
                        <div className={readOnly === "true" ? "panel-body disabled" : "panel-body"}>
                            <div className="panel-title text-left">User
                                 <button
                                    className="btn btn-cancle"
                                    style={{ float: "right" }}
                                    type="button btn"
                                    onClick={handleCancle}>
                                    {"Cancel"}
                                </button>
                                <button
                                    className={isSubmitting ? " btn btn-success btn-save disabled" : "btn btn-save btn-success"}
                                    style={{ float: "right" }}
                                    type="submit"
                                    onClick={handleSubmit}>
                                    {"Save"}
                                </button>
                            </div>
                            <div className="editpanel">
                                {isSubmitting ?
                                    <DisappearedLoading color="#5f6973" />
                                    : ("")}
                                <form onSubmit={handleSubmit}>
                                    {schema.map((item, index) => {
                                        return (
                                            <div key={index} >
                                                {item.ObjectType === "Textbox" ? (
                                                    <div className={item.Editable === "No" ? "col-md-12 display-flex disabled" : "col-md-12 display-flex"}>
                                                        <label className="form_label w-30">
                                                            {item.FieldName} {item.Mandatory && <span className="error">* </span>}
                                                        </label>
                                                        <div className=" form_input w-70">
                                                            <Field
                                                                name={item.backendMappingName}
                                                                component={InputBox}
                                                                type={"text"}
                                                                validate={item.backendMappingName === "usrMobileno" ? validatePhone : item.backendMappingName === "usrLoginid" ? validateEmail.bind(this, item.FieldName) : item.Mandatory && required.bind(this, item.FieldName)}
                                                                placeholder={item.FieldName}
                                                                value={values[item.backendMappingName] || ''}
                                                            /> </div>
                                                    </div>
                                                ) : (
                                                        <div className="col-md-12  display-flex">

                                                        </div>
                                                    )}
                                            </div>
                                        );
                                    })}

                                    <div className="col-md-12  display-flex">
                                        <label className="form_label w-30" style={{ display: "block" }}>
                                            Company :
                                                </label>
                                        <div className="form_input w-70" style={{ fontSize: "14px" }}>
                                            <Select
                                                id="Company"
                                                name="Company"
                                                styles={customStyles}
                                                value={userCompany}
                                                options={usrSupportAdmin ===1 ? company : filteredCompany}
                                                //defaultValue={userRole[0]}
                                                closeMenuOnSelect={false}
                                                placeholder="Choose Company"
                                                onChange={onSelectChange.bind(this, 'Company')}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-12  display-flex">
                                        <label className="form_label w-30" style={{ display: "block" }}>
                                            Role :
                                                </label>
                                        <div className="form_input w-70" style={{ fontSize: "14px" }}>
                                            <Select
                                                id="Role"
                                                name="Role"
                                                styles={customStyles}
                                                value={userRole}
                                                options={role}
                                                //defaultValue={userRole[0]}
                                                isMulti
                                                closeMenuOnSelect={false}
                                                placeholder="Choose User Role"
                                                onChange={onSelectChange.bind(this, 'Role')}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-12  display-flex">
                                        <label className="form_label w-30" style={{ display: "block" }}>
                                            User Type :
                                                </label>
                                        <div className="form_input w-70" style={{ fontSize: "14px" }}>
                                            <Select
                                                id="UserType"
                                                name="UserType"
                                                styles={customStyles}
                                                options={usertype}
                                                value={userTypes}
                                                //defaultValue={usertype[0]}
                                                isMulti
                                                closeMenuOnSelect={false}
                                                placeholder="Choose User Type"
                                                onChange={onSelectChange.bind(this, 'UserType')}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-12  display-flex">
                                        <label className="form_label w-30" style={{ display: "block" }}>
                                            Reporting Manager :
                                                </label>
                                        <div className="form_input w-70" style={{ fontSize: "14px" }}>
                                            <Select
                                                id="ReportingManager"
                                                name="ReportingManager"
                                                styles={customStyles}
                                                value={userReportingManager}
                                                //defaultValue={userReportingManager[0]}
                                                isMulti
                                                closeMenuOnSelect={false}
                                                placeholder="Choose User Reporting Manager"
                                                onChange={onSelectChange.bind(this, 'ReportingManager')}
                                                options={filteredReportingManager}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-12  display-flex">
                                        <label className="form_label w-30" style={{ display: "block" }}>
                                            Zone :
                                                </label>
                                        <div className="form_input w-70" style={{ fontSize: "14px" }}>
                                            <Select
                                                id="Zone"
                                                name="Zone"
                                                styles={customStyles}
                                                value={userZone}
                                                options={zone}
                                                //defaultValue={userZone[0]}
                                                isMulti
                                                closeMenuOnSelect={false}
                                                placeholder="Choose User ZOne"
                                                onChange={onSelectChange.bind(this, 'Zone')}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-12  display-flex">
                                        <label className="form_label w-30" style={{ display: "block" }}>
                                            Department :
                                                </label>
                                        <div className="form_input w-70" style={{ fontSize: "14px" }}>
                                            <Select
                                                id="Department"
                                                name="Department"
                                                styles={customStyles}
                                                value={userDepartment}
                                                options={filteredDepartment}
                                                //defaultValue={userDepartment[0]}
                                                isMulti
                                                closeMenuOnSelect={false}
                                                placeholder="Choose User Department"
                                                onChange={onSelectChange.bind(this, 'Department')}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-12 display-flex">
                                        <label className="form_label w-30" style={{ display: "block" }} >
                                            Enable DMT Roles:
                                                    </label>
                                        <div className="form_input w-70">
                                            <input
                                                name={"enableRoles"}
                                                type="checkbox"
                                                id={"enableRoles"}
                                                className={"checkbox"}
                                                onChange={handleDmtRoles}
                                            />
                                        </div>
                                    </div>

                                    {checked ?
                                        <div className="col-md-12  display-flex mt-20">
                                            <label className="form_label w-30" style={{ display: "block" }}>
                                                DMT Roles :
                                                </label>
                                            <div className="form_input w-70" style={{ fontSize: "14px" }}>
                                                <Select
                                                    id="DMTRoles"
                                                    name="DMTRoles"
                                                    styles={customStyles}
                                                    value={""}
                                                    options={dmtRoles}
                                                    onChange={onSelectChange.bind(this, 'DMTRoles')}
                                                    isMulti={true}
                                                />
                                            </div>
                                        </div>
                                        : ("")
                                    }
                                </form>
                                <div className="col-md-12  display-flex mt-40">
                                    <button
                                        className={isSubmitting ? " btn btn-success btn-save disabled" : "btn btn-save btn-success"}
                                        type="submit"
                                        onClick={handleSubmit}>
                                        {"Save"}
                                    </button>
                                    <button
                                        className="btn btn-cancle"
                                        type="button btn"
                                        onClick={handleCancle}>
                                        {"Cancel"}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        userId: getValue(state, ["loginReducer", "userId"]),
        userCompanyId: getValue(state, ["loginReducer", "userCompanyId"]),
        usrSupportAdmin: getValue(state, ["loginReducer", "usrSupportAdmin"])
    };
};

const EditUserViews = connect(mapStateToProps)(formikEnhancer(EditUserView))

export default EditUserViews;





