import { post } from 'axios';
import { BASE_URL } from "../../../../common/helpers/urlsConstants";


export const changePassword = async (data, tokenId) => {
    tokenId = sessionStorage.getItem("tokenId")
    var token = tokenId.replace(/['"]+/g, '');
    const authData = {
        method: "POST",
        headers: {
            "Authorization": `Bearer ${token}`,
			"accept": "*/*",
            "Content-Type": "application/json"
        }
    };
    const url = `${BASE_URL}/changePassword`;
    return post(url, data, authData);
};

