import React, { useEffect, useState } from "react";
import { Field, withFormik } from "formik";
import { InputBox } from "../../../../common/components/InputBox";
import Select from "../../../../common/components/Select";
import schema from "./editViewFields.json"
import { findCustomerById, saveOrUpdateCustomer } from "./api";
import { useLocation, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import Notification from '../../../../common/notification'
import { getValue } from "../../../../common/helpers/validations";
import { validatePinCode, validateEmail, required, requiredField } from "../../../../common/helpers/validations";
import { findDistributorListAPI } from "../../../../services/api";
import { getZone, getCountry, getState, getSegment, getSubSegment, getSelectData, getEnac5, getMarketSegment,getMarketSubSegment, getCustomerGroup, getEngineer, getSubSbu } from "../../../../services/staticdata";
import { DisappearedLoading } from 'react-loadingg';


const formikEnhancer = withFormik({

    mapPropsToValues: ({ user, props }) => ({
        ...user,
        ...props
    }),
    validate: (values) => {
        const errors = {};
        if (!values.custMktSegment) {
          errors.custMktSegment = "Required";
        }
        if (!values.custMktSubsegment) {
            errors.custMktSubsegment = "Required";
          }
        return errors;
      },
    handleSubmit: (payload, { setSubmitting, props }) => {
        setSubmitting(true);
        //console.log("payload", payload);

        saveOrUpdateCustomer(payload).then(resp => {
            //console.log("savedData", resp);
            if (resp.status === 200) {
                Notification.success('Saved Successful.');
                props.history.goBack()
            }
            else {
                Notification.error('Error while saving data:' + resp.data.status_response);
            }
            setSubmitting(false);
        })

    },
    displayName: "MyForm"
});


let EditView = (props) => {

    let { userCompanyId, userId } = props;
    const { handleSubmit, setFieldValue, setValues, values, isSubmitting } = props;
    const [distributorList, setDistributorList] = useState(null);


    const [zone, setZone] = useState([]);
    const [state, setState] = useState([]);
    const [country, setCountry] = useState([]);
    const [segment, setSegment] = useState([]);
    const [subSegment, setSubSegment] = useState([]);
    const [subSbu, setSubSbu] = useState([]);
    const [selectData, setSelectData] = useState([]);
    const [enac5, setEnac5] = useState([]);
    const [marketSegment, setMarketSegment] = useState([]);
    const [marketSubSegment, setMarketSubSegment] = useState([]);
    const [marketSubSegmentAll, setMarketSubSegmentAll] = useState([]);
    const [customerGroup, setCustomerGroup] = useState([]);
    const [engineer, setEngineer] = useState([]);

    const initData = {
        custActive: null,
        custAdd1: "",
        custAdd2: "",
        custAdd3: null,
        custApprove: 0,
        custApproverUid: null,
        custArea: "",
        custCity: "",
        custCompanyid: userCompanyId,
        custCountryId: 0,
        custCrDt: null,
        custCrUid: userId,
        custDistId: 0,
        custDistrict: null,
        custEmail: "",
        custEnac5: null,
        custEnac6: null,
        custGroup: null,
        custGstno: "",
        custHenkelEngg: 0,
        custHenkelEngg1: 0,
        custHenkelEngg2: 0,
        custIbsegment: null,
        custKeycontact: "",
        custMktSegment: null,
        custMktSubsegment: null,
        custName: "",
        custPhone: "",
        custPincode: "",
        custSalesPerson: "",
        custSegment: 0,
        custStateId: 0,
        custSubSbu: null,
        custSubsegment: 0,
        custTaluka: null,
        custUpDt: 0,
        custUpUid: 0,
        custWebsite: "",
        custZone: 0
    }


    async function loadStaticData() {
        setZone(await getZone());
        setCountry(await getCountry());
        setState(await getState());
        setSegment(await getSegment());
        setSubSegment(await getSubSegment());
        setSubSbu(await getSubSbu());
        setSelectData(await getSelectData());
        setEnac5(await getEnac5());
        setMarketSegment(await getMarketSegment());
        setMarketSubSegmentAll(await getMarketSubSegment());
        setCustomerGroup(await getCustomerGroup());
        setEngineer(await getEngineer());
    }


    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    const custId = useQuery().get("id");
    const readOnly = useQuery().get("read");

    const history = useHistory()
    const handleCancle = () => {
        history.goBack()
    };

    const onMarketSegmentChnage =(key )=> {
        //console.log("key:",key);
        //console.log("marketSegment:",marketSegment);
        //console.log("marketSubSegmentAll:",marketSubSegmentAll);
        let localmktSubSegment = marketSubSegmentAll.filter(obj=>obj["parent"]==key);
       //console.log("localmktSubSegment:",localmktSubSegment);
       setMarketSubSegment(localmktSubSegment); 
    }
    useEffect( () => {
        loadStaticData();
    },[]);
    useEffect( () => {
        //debugger;
        //console.log("testingProps", props);
        //loadStaticData();
        if (custId) {
            findCustomerById(custId).then(data => {
            getMarketSubSegment().then(data1=>{
                    console.log(data1);
                    setMarketSubSegment(data1.filter(obj=>obj["parent"]==data[0].custMktSegment)); 
                });                
                setValues(data[0]);
                //console.log(data[0]);
        
            });
        }
        else {
            setValues(initData);
        }

        findDistributorListAPI(userId).then(data => {
            let newDistArray = data.map(i => {
                let option = { key: i.distId, value: i.distDistName }
                return option
            })
            setDistributorList(newDistArray);
        })

    }, []);


    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-12 mt-20">
                    <div className="panel panel-default">
                        <div className={readOnly === "true" ? "panel-body disabled" : "panel-body"}>
                            <div className="panel-title text-left">Distributors Customer
                             <button
                                    className="btn btn-cancle"
                                    style={{ float: "right" }}
                                    type="button btn"
                                    onClick={handleCancle}>
                                    {"Cancel"}
                                </button>
                                <button
                                    className={isSubmitting ? " btn btn-success btn-save disabled" : "btn btn-save btn-success"}
                                    style={{ float: "right" }}
                                    type="submit"
                                    onClick={handleSubmit}>
                                    {"Save"}
                                </button>
                            </div>
                            <div className="editpanel">
                                <form onSubmit={handleSubmit}/* className={isSubmitting ? "disabled" : ""}*/>
                                    {isSubmitting ?
                                        <DisappearedLoading color="#5f6973" />
                                        : ("")}
                                    {schema.map((item, index) => {
                                        return (
                                            <div key={index} >
                                                {item.ObjectType === "Textbox" ? (
                                                    <div>
                                                        {custId ? (
                                                            <div className={item.Editable === "No" ? "col-md-12 display-flex disabled" : "col-md-12 display-flex"} >
                                                                <label className="form_label w-30">
                                                                    {item.FieldName} {item.Mandatory && <span className="error">* </span>}
                                                                </label>
                                                                {item.backendMappingName === "custEmail" ? (
                                                                    <div className="form_input w-70">
                                                                        <Field
                                                                            name={item.backendMappingName}
                                                                            component={InputBox}
                                                                            type={"text"}
                                                                            placeholder={item.FieldName}
                                                                            value={values[item.backendMappingName] || ''}
                                                                            validate={validateEmail.bind(this, item.FieldName)}
                                                                        />
                                                                    </div>
                                                                ) :
                                                                    (
                                                                        <div className="form_input w-70">
                                                                            <Field
                                                                                name={item.backendMappingName}
                                                                                component={InputBox}
                                                                                type={"text"}
                                                                                placeholder={item.FieldName}
                                                                                value={values[item.backendMappingName] || ''}
                                                                                validate={item.backendMappingName === "custPincode" ? (validatePinCode.bind(this, item.FieldName)) : (item.Mandatory && required.bind(this, item.FieldName))}
                                                                            />
                                                                        </div>
                                                                    )}
                                                            </div>
                                                        ) :
                                                            (
                                                                <div className={item.Editable === "No" ? "col-md-12 display-flex disabled" : "col-md-12 display-flex"} >
                                                                    {item.backendMappingName !== "custId" && item.backendMappingName !== "custCode" ? (
                                                                        <>
                                                                            <label className="form_label w-30">
                                                                                {item.FieldName} {item.Mandatory && <span className="error">* </span>}
                                                                            </label>
                                                                            {item.backendMappingName === "custEmail" ? (
                                                                                <div className="form_input w-70">
                                                                                    <Field
                                                                                        name={item.backendMappingName}
                                                                                        component={InputBox}
                                                                                        type={"text"}
                                                                                        placeholder={item.FieldName}
                                                                                        value={values[item.backendMappingName] || ''}
                                                                                        validate={validateEmail.bind(this, item.FieldName)}
                                                                                    />
                                                                                </div>
                                                                            ) :
                                                                                (
                                                                                    <div className="form_input w-70">
                                                                                        <Field
                                                                                            name={item.backendMappingName}
                                                                                            component={InputBox}
                                                                                            type={"text"}
                                                                                            placeholder={item.FieldName}
                                                                                            value={values[item.backendMappingName] || ''}
                                                                                            validate={item.backendMappingName === "custPincode" ? (validatePinCode.bind(this, item.FieldName)) : (item.Mandatory && required.bind(this, item.FieldName))}
                                                                                        />
                                                                                    </div>
                                                                                )}

                                                                        </>
                                                                    ) : ("")
                                                                    }

                                                                </div>
                                                            )}
                                                    </div>

                                                ) : (
                                                        <div className="col-md-12 display-flex">
                                                            <label
                                                                className="form_label w-30"
                                                                htmlFor={item.backendMappingName}
                                                                style={{ display: "block" }}
                                                            >
                                                                {item.FieldName} {item.Mandatory && <span className="error">* </span>}
                                                            </label>
                                                            <div className=" form_input w-70">
                                                                {item.backendMappingName === "custMktSegment" ? (
                                                                <Select
                                                                    id={item.backendMappingName}
                                                                    name={item.backendMappingName}
                                                                    value={values[item.backendMappingName] || ''}
                                                                    options={eval(item.Source)}
                                                                    onChange={(field, value) => {
                                                                        setFieldValue(field, value);
                                                                        onMarketSegmentChnage(value);
                                                                    }}
                                                                    validate={item.Mandatory && requiredField(item.FieldName,values[item.backendMappingName])}
                                                                /> ) :
                                                                (<Select
                                                                    id={item.backendMappingName}
                                                                    name={item.backendMappingName}
                                                                    value={values[item.backendMappingName] || ''}
                                                                    options={eval(item.Source)}
                                                                    onChange={(field, value) => {
                                                                        setFieldValue(field, value);
                                                                    }}
                                                                    validate={item.Mandatory && requiredField(item.FieldName,values[item.backendMappingName])}
                                                                />)}
                                                            </div>
                                                        </div>
                                                    )}
                                            </div>
                                        );
                                    })}
                                </form>
                                <div className="col-md-12  display-flex">
                                    <button
                                        className={isSubmitting ? " btn btn-success btn-save disabled" : "btn btn-save btn-success"}
                                        type="submit"
                                        onClick={handleSubmit}>
                                        {"Save"}
                                    </button>
                                    <button
                                        className="btn btn-cancle"
                                        type="button btn"
                                        onClick={handleCancle}>
                                        {"Cancel"}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        userId: getValue(state, ["loginReducer", "userId"]),
        userCompanyId: getValue(state, ["loginReducer", "userCompanyId"])
    };
};

const EditViews = connect(mapStateToProps)(formikEnhancer(EditView))

export default EditViews;



