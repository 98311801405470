import React from "react";
import { Link } from 'react-router-dom'

export const tableColumHeaders = (setRoleList) => {

    return [
        {
            Header: "Name",
            accessor: "rhCode",
            sortable: true,
            filterable: true,
            style: {
                textAlign: "left"
            },
            maxWidth: 200
        },
        {
            Header: "Description",
            accessor: "rhdesc",
            sortable: false,
            filterable: false,
            style: {
                textAlign: "left"
            },
            maxWidth: 900
        },
        {
            Header: "Action",
            sortable: false,
            maxWidth: 170,
            filterable: false,
            Cell: props => {
                //console.log("roleId", props.original);
                return (
                    <div className="text-center">
                        <Link
                            className={'mr-3 btn-edit'}
                            style={{ color: "#000000" }}
                            to={`/role/editRole/?id=${(props && props.original) ? props.original.rhId : 0}`}
                        >
                            <span className="glyphicon glyphicon-edit"></span>
                        </Link>
                        <Link
                            className="btn-delete"
                            style={{ color: "#000000" }}
                            //onClick={() => onDelete(props.original.usrId)}
                            to="#">
                            <span className="glyphicon glyphicon-trash"></span>
                        </Link>
                    </div>
                );
            }
        }
    ];
};


