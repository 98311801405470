import React from "react";
import PropTypes from "prop-types";
import { required } from "../helpers/validations";


class Select extends React.Component {

    makeOption = option => (

        <option key={option.key} value={option.key}>
            {option.value}
        </option>

    );

    handleChange = event => {
        this.props.onChange(event.target.id, event.target.value);
        event.preventDefault();
    };

    handleBlur = event => {
        this.props.onBlur(this.props.id, true);
        event.preventDefault();
    };

    render() {
        const { id, name, options, onBlur } = this.props;
        return (
            <div>
                <select
                    id={id}
                    name={name}
                    component="Select"
                    onChange={this.handleChange}
                    onBlur={onBlur && this.handleBlur}
                    value={this.props.value || ''}
                >
                    <option value="" defaultValue className='disabled'>{this.props.placeHolder || 'Select'}</option>
                    {Array.isArray(options) && options.map(this.makeOption)}

                </select>
                {this.props.validate && <div className="error" style={{ position: "absolute" }}> {this.props.validate} </div>}
            </div>
        );
    }
}

Select.propTypes = {
    options: PropTypes.arrayOf(String),
    placeHolder: PropTypes.string
};

Select.defaultValue = {
    options: []
};

export default Select;
