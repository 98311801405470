import React,{ useState } from "react";
import { Route, Redirect } from "react-router-dom";
import CustomHeader from "../../common/components/stateLessComponents/header/";
import { Layout, Menu } from "antd";
import { useIdleTimer} from  'react-idle-timer'
import BiModal from "../../common/components/BiModal"

export const PrivateRoutes = ({

    component: Component,
    isLoggedIn,
    ...rest
}) => {


    const { Header, Sider, Content, Footer } = Layout;
	
	const handleOnIdle = event => {
        console.log('user is idle', event)
        openModal();
      }
      const [show, setShow] = useState(false);
      const openModal = () => setShow(true);
      const closeModal = () => setShow(false);
   
    useIdleTimer({
        timeout: 5 * 1000,
        onIdle: handleOnIdle,
        debounce: 500
      })	

    return (
        <React.Fragment>
            <Route
                {...rest}
                render={props => {
                    return (
                        isLoggedIn === true ? (
                            <Layout style={{ minHeight: '100vh' }}>
							 
                                <CustomHeader />
                                <Layout>
                                    <Content>
                                        <Component {...props} />
                                    </Content>
                                    <Footer style={{ textAlign: 'center',position: 'sticky',bottom: "0"}}>Wisdom Portal, powered by JK Solution</Footer>
                                </Layout>
                            </Layout>
                        ) : (
                                <Redirect
                                    to={{ pathname: "/login", state: { from: props.location } }}
                                />
                            )
                    )
                }
                }
            />
        </React.Fragment>
    );
};
