import { Modal, ModalTitle } from 'react-bootstrap';
import React, { useState } from "react";
import { connect } from "react-redux";
import { Formik, Field } from "formik";
import { getValue } from "../../../../common/helpers/validations";
import { fileUpload } from "./api";
import { Link } from "react-router-dom";
import Notification from '../../../../common/notification'
import Select from "../../../../common/components/Select";
import { useEffect } from "react";
import { findDistributorListAPI } from "../../../../services/api";
import moment from 'moment';
import { BASE_URL } from "../../../../common/helpers/urlsConstants";
import { downloadFile } from "../../../../common/helpers/fileDownload";


let DistributorUpload = props => {
    const [respId, setrespId] = useState(false);
    const [formSubmitting, setformSubmitting] = useState(false);
    const [formSubmitted, setformSubmitted] = useState(false);
    let { userId } = props;
    const { show, closeModal } = props;
    const [distributorList, setDistributorList] = useState(null);
    const [selectedIdhCode, setSelectedIdhcode] = useState(null);

    useEffect(() => {
        findDistributorListAPI(userId).then(data => {
            let newDistArray = data.map(i => {
                let option = { key: i.distIdhCode, value: i.distDistName }
                return option
            })
            setDistributorList(newDistArray);
        })
    }, []);


    return (
        <Modal show={show} onHide={closeModal}>
            <Modal.Header>
                <ModalTitle>
                    TEMPLATE DOWNLOAD
                    <button onClick={closeModal} className="close" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </ModalTitle>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <Formik initialValues={{
                        distIdhCode: null
                    }}>
                        {({ values, setFieldValue }) => (
                            <form>
                                <div className="col-md-12 mt-15">
                                    <div className="col-md-4">
                                        <label htmlFor="distIdhCode" style={{ display: "block" }}>
                                            Distributor Name
                                    </label>
                                    </div>
                                    <div className="col-md-8">

                                        <Select
                                            id="distIdhCode"
                                            value={values.distIdhCode}
                                            options={distributorList}
                                            onChange={(field, value) => {
                                                setFieldValue("distIdhCode", value);
                                            }}
                                        />

                                    </div>
                                </div>
                                <div className="col-md-12 mt-15">
                                </div>

                                <div className="col-md-12 mt-20 mb-20">
                                    <div className="col-md-4">
                                    </div>
                                    <div className="col-md-8">
                                        <button className=" btn btn-info btn-fileupload " type="button">
                                            <a onClick={() => downloadFile(`${BASE_URL}/${values.distIdhCode}.xlsx`,`${values.distIdhCode}.xlsx`)} style={{
                                                color: "inherit",
                                                textDecoration: "none",
                                            }} >
                                                Download</a>
                                        </button>
                                        <button type="button" className="btn btn-filecancle" onClick={closeModal}>Cancel</button>
                                    </div>
                                </div>


                            </form>
                        )}
                    </Formik>

                    {respId && (
                        <Link to="/fileUploadStatus">
                            <div className="alert alert-success cursor mt-15" role="alert" style={{ clear: "both", marginLeft: "15px", width: "95%" }}>
                                {'Distributor data upload job is submitted. Click here to check the status and copy batch id.'}
                                <strong id="batchId">{respId}</strong>
                            </div>
                        </Link>
                    )}

                </div>
            </Modal.Body>
        </Modal>

    );
};
const mapStateToProps = state => {
    return {
        userId: getValue(state, ["loginReducer", "userId"])
    };
};


export default DistributorUpload = connect(
    mapStateToProps
)(DistributorUpload);