import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { getUserRolePage, saveOrUpdateUserRolePage } from "./api.js";
import "../../../../css/style.css";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { withFormik } from "formik";
import "../../../../css/style.css";
import Notification from '../../../../common/notification';
import { connect } from "react-redux";
import { getValue } from "../../../../common/helpers/validations";

const formikEnhancer = withFormik({
    mapPropsToValues: ({ user, props }) => ({
        ...user,
        ...props
    }),
    handleSubmit: (payload, { setSubmitting, props }) => {

        if (payload.length > 0 && payload != null && payload != undefined) {
            for (var i = 0; i < payload.length; i++) {
                payload[i].rpCrID = props.userId;
            }
        }
        console.log(JSON.stringify(payload));
        setSubmitting(true);
        saveOrUpdateUserRolePage(payload).then(resp => {

            // console.log("savedData", resp);
            if (resp.status === 200) {
                Notification.success('Saved Successful.');
                props.history.push('/Role');
            }
            else {
                Notification.error('Error while saving data:' + resp.data.status_response);
            }
            setSubmitting(false);
        })
    },
    displayName: "MyForm"
});


let EditRoleView = (props) => {
    const [data, setData] = useState([]);
    const [counter, setCounter] = useState(0);
    const [currentLevel, setCurrentLevel] = useState();
    const [subCurrentLevel, setSubCurrentLevel] = useState();

    const { handleSubmit, setFieldValue, setValues, values, isSubmitting } = props;
    var newData = [];

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    function updateJasonObject(jsonData, pageId, checkFor, isChecked) {
        //debugger;
        if (jsonData && jsonData.length > 0) {
            var checked = 0;
            if (isChecked) {
                checked = 1;
            }
            var rpPageId = 0;
            for (var i = 0; i < jsonData.length; i++) {
                if (jsonData[i].rpPageid == pageId || jsonData[i].pageParentId == pageId) {
                    rpPageId = jsonData[i].rpPageid;
                    //debugger;
                    if (checkFor == "A") {
                        jsonData[i].rpAdd = checked;
                    }
                    else if (checkFor == "E") {
                        jsonData[i].rpEdit = checked;
                    }
                    else if (checkFor == "D") {
                        jsonData[i].rpDelete = checked;
                    }
                    else if (checkFor == "V") {
                        jsonData[i].rpView = checked;
                    }

                }
                if (jsonData[i].pageParentId !== null && jsonData[i].pageParentId === rpPageId) {
                    //debugger;
                    if (checkFor == "A") {
                        jsonData[i].rpAdd = checked;
                    }
                    else if (checkFor == "E") {
                        jsonData[i].rpEdit = checked;
                    }
                    else if (checkFor == "D") {
                        jsonData[i].rpDelete = checked;
                    }
                    else if (checkFor == "V") {
                        jsonData[i].rpView = checked;
                    }
                }
            }
        }
        return jsonData;
    }


    const handleRoleCheckChange = (e, checkFor, me) => {
        //debugger;
        var updatedObjList = updateJasonObject(data, e, checkFor, me.target.checked, 0);
        setData(updatedObjList);
        setValues(updatedObjList);
        setCounter(counter + 1);
        //console.log(JSON.stringify(updatedObjList));
    }

    const handleIndexChange = (value) => {
        //debugger;
        if (value === currentLevel) {
            setCurrentLevel(null);
            setSubCurrentLevel(null);
        }
        else {
            setCurrentLevel(value);
        }
    }



    function result(x, objectLevel) {
        //debugger;
        for (let i = 0; i < x.length; i++) {
            //debugger;
            if (Array.isArray(x[i]["userRolePage"])) {
                let temp = { ...x[i], userRolePage: null };
                temp.level = objectLevel;
                newData.push(temp);
                result(x[i]["userRolePage"], objectLevel + 1);
            } else {
                x[i].level = objectLevel;
                newData.push(x[i]);
            }
        }

        return newData;
    }



    const history = useHistory()

    const handleCancle = () => {
        history.goBack()
    }
    const roleID = useQuery().get("id");

    useEffect(() => {
        if (roleID) {
            getUserRolePage(roleID).then(value => {
                // setData(value[0]);
                var updatedData = result(value[0], 1);
                setData(updatedData);
                setValues(updatedData);
            });
        }
        else {
        }
    }, []);

    return (
        <div className="container-fluid" >
            <div className="row">
                <div className="col-md-12 mt-20">
                    <div className="panel panel-default">
                        <div className="panel-body">
                            <div className="panel-title text-left">ROLE
                                <button
                                    className="btn btn-cancle"
                                    style={{ float: "right" }}
                                    type="button btn"
                                    onClick={handleCancle}
                                >
                                    {"Cancel"}
                                </button>
                                <button
                                    className={isSubmitting ? " btn btn-success btn-save disabled" : "btn btn-save btn-success"}
                                    style={{ float: "right" }}
                                    type="submit"
                                    onClick={handleSubmit}
                                >
                                    {"Save"}
                                </button>
                            </div>


                            <div className="editpanel">
                                <div className="col-md-6">
                                </div>
                                <div className="col-md-6 display-flex">
                                    <label className="role-title">ADD</label>
                                    <label className="role-title">EDIT</label>
                                    <label className="role-title">VIEW</label>
                                    <label className="role-title">DELETE</label>
                                </div>
                                {data.map((item, index) => {
                                    return (
                                        <>
                                            {item.level == 1 &&
                                                <div className="col-md-12 display-flex" style={{ marginBottom: "10px" }}>
                                                    <div className="col-md-6">
                                                        {item.rpPageid === currentLevel ? (
                                                            <RemoveCircleOutlineIcon style={{ fontSize: "20px", marginBottom: "-5px" }} onClick={() => handleIndexChange(item.rpPageid)} />
                                                        ) : (
                                                                <AddCircleOutlineIcon style={{ fontSize: "20px", marginBottom: "-5px" }} onClick={() => handleIndexChange(item.rpPageid)} />
                                                            )}
                                                        <text> {item.pageName}</text>
                                                    </div>
                                                    <div className="col-md-6 display-flex" >
                                                        <input
                                                            name={"rpAdd"}
                                                            type="checkbox"
                                                            id={"rpAdd"}
                                                            className="checkbox-rolepage"
                                                            checked={item.rpAdd === 1 ? true : false}
                                                            onChange={(e) => handleRoleCheckChange(item.rpPageid, 'A', e)}
                                                        />
                                                        <input
                                                            name={"rpEdit"}
                                                            type="checkbox"
                                                            id={"rpEdit"}
                                                            className="checkbox-rolepage"
                                                            checked={item.rpEdit === 1 ? true : false}
                                                            onChange={(e) => handleRoleCheckChange(item.rpPageid, 'E', e)}
                                                        />
                                                        <input
                                                            name={"rpView"}
                                                            type="checkbox"
                                                            id={"rpView"}
                                                            checked={item.rpView === 1 ? true : false}
                                                            className="checkbox-rolepage"
                                                            onChange={(e) => handleRoleCheckChange(item.rpPageid, 'V', e)}
                                                        />
                                                        <input
                                                            name={"rpDelete"}
                                                            type="checkbox"
                                                            id={"rpDelete"}
                                                            className="checkbox-rolepage"
                                                            checked={item.rpDelete === 1 ? true : false}
                                                            onChange={(e) => handleRoleCheckChange(item.rpPageid, 'D', e)}
                                                        />
                                                    </div>
                                                </div>
                                            }

                                            {(item.pageParentId == currentLevel && item.level == 2) &&
                                                (
                                                    <div className="col-md-12 display-flex" style={{ marginBottom: "10px" }} >
                                                        <div className="col-md-6" style={{ left: "35px" }}>
                                                            {item.rpPageid === subCurrentLevel ? (
                                                                <RemoveCircleOutlineIcon style={{ fontSize: "20px", marginBottom: "-5px" }} onClick={() => setSubCurrentLevel(item.rpPageid === subCurrentLevel ? null : item.rpPageid)} />
                                                            ) : (
                                                                    <AddCircleOutlineIcon style={{ fontSize: "20px", marginBottom: "-5px" }} onClick={() => setSubCurrentLevel(item.rpPageid === subCurrentLevel ? null : item.rpPageid)} />
                                                                )}
                                                            <text> {item.pageName}</text>
                                                        </div>
                                                        <div className="col-md-6 display-flex" >
                                                            <input
                                                                name={"rpAdd"}
                                                                type="checkbox"
                                                                id={"rpAdd"}
                                                                className="checkbox-rolepage"
                                                                checked={item.rpAdd === 1 ? true : false}
                                                                onChange={(e) => handleRoleCheckChange(item.rpPageid, 'A', e)}
                                                            />
                                                            <input
                                                                name={"rpEdit"}
                                                                type="checkbox"
                                                                id={"rpEdit"}
                                                                className="checkbox-rolepage"
                                                                checked={item.rpEdit === 1 ? true : false}
                                                                onChange={(e) => handleRoleCheckChange(item.rpPageid, 'E', e)}
                                                            />
                                                            <input
                                                                name={"rpView"}
                                                                type="checkbox"
                                                                id={"rpView"}
                                                                checked={item.rpView === 1 ? true : false}
                                                                className="checkbox-rolepage"
                                                                onChange={(e) => handleRoleCheckChange(item.rpPageid, 'V', e)}
                                                            />
                                                            <input
                                                                name={"rpDelete"}
                                                                type="checkbox"
                                                                id={"rpDelete"}
                                                                className="checkbox-rolepage"
                                                                checked={item.rpDelete === 1 ? true : false}
                                                                onChange={(e) => handleRoleCheckChange(item.rpPageid, 'D', e)}
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                            {(item.pageParentId == subCurrentLevel && item.level == 3) && (
                                                <div className="col-md-12 display-flex" style={{ marginBottom: "10px" }} >
                                                    <div className="col-md-6" style={{ left: "80px" }}>
                                                        {item.pageName}
                                                    </div>
                                                    <div className="col-md-6 display-flex" >
                                                        <input
                                                            name={"rpAdd"}
                                                            type="checkbox"
                                                            id={"rpAdd"}
                                                            className="checkbox-rolepage"
                                                            checked={item.rpAdd === 1 ? true : false}
                                                            onChange={(e) => handleRoleCheckChange(item.rpPageid, 'A', e)}
                                                        />
                                                        <input
                                                            name={"rpEdit"}
                                                            type="checkbox"
                                                            id={"rpEdit"}
                                                            className="checkbox-rolepage"
                                                            checked={item.rpEdit === 1 ? true : false}
                                                            onChange={(e) => handleRoleCheckChange(item.rpPageid, 'E', e)}
                                                        />
                                                        <input
                                                            name={"rpView"}
                                                            type="checkbox"
                                                            id={"rpView"}
                                                            checked={item.rpView === 1 ? true : false}
                                                            className="checkbox-rolepage"
                                                            onChange={(e) => handleRoleCheckChange(item.rpPageid, 'V', e)}
                                                        />
                                                        <input
                                                            name={"rpDelete"}
                                                            type="checkbox"
                                                            id={"rpDelete"}
                                                            className="checkbox-rolepage"
                                                            checked={item.rpDelete === 1 ? true : false}
                                                            onChange={(e) => handleRoleCheckChange(item.rpPageid, 'D', e)}
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                        </>
                                    );
                                })}
                            </div >
                            <div className="col-md-12 mt-20 row display-flex">
                                <button
                                    className={isSubmitting ? " btn btn-success btn-save disabled" : "btn btn-save btn-success"}
                                    type="submit"
                                    onClick={handleSubmit} >
                                    {"Save"}
                                </button>
                                <button
                                    className="btn btn-cancle"
                                    type="button btn"
                                    onClick={handleCancle}
                                >
                                    {"Cancel"}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        userId: getValue(state, ["loginReducer", "userId"]),
        userCompanyId: getValue(state, ["loginReducer", "userCompanyId"])
    };
};

const EditRoleViews = connect(mapStateToProps)(formikEnhancer(EditRoleView))

export default EditRoleViews;