import axios from 'axios';
import { BASE_URL } from '../common/helpers/urlsConstants';
window.axios = axios;

//export const findDistributorListAPI = async id => {
//    const response = await fetch(`${BASE_URL}/findDistributorList?size=2000&userId=${id}`);
//    const resp = await response.json();
//    if (!resp.content) {
//        return false;
//    } else {
//        return resp.content;
//    }
//};

export const findDistributorListAPI = async (id, tokenId) => {
    tokenId = sessionStorage.getItem("tokenId")
    var token = tokenId.replace(/['"]+/g, '');
    const authData = {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${token}`
        }
    };
    const url = `${BASE_URL}/findDistributorList?size=2000&userId=${id}`;
    return fetch(url, authData).then(response => response.json()).then(resp => {
        if (!resp.content) {
            return [];
        } else {
            return resp.content;
        }
    })
};
