import React from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import Sidebar from "../../common/components/stateLessComponents/siderbar";
import CustomHeader from "../../common/components/stateLessComponents/header/";
import { shouldRedirectToPath } from "../../pages/login/actionCreator";
//import Header from "antd/lib/calendar/Header";
import { Layout, Menu } from "antd";

function privateRoutesWithSideBar({
    component: Component,
    isLoggedIn,
    data,
    ...rest
}) {


    const { Header, Sider, Content,Footer } = Layout;

    return (
        <React.Fragment>
            <Route
                {...rest}
                render={props => {
                    return (
                        isLoggedIn === true ? (
                            <Layout style={{ minHeight: '100vh' }}>
                                <CustomHeader />
                                <Layout>
                                    <Sidebar />
                                    <Layout >
                                        <Content>
                                            <Component {...props} />
                                        </Content>
                                        <Footer style={{ textAlign: 'center'}}>Wisdom Portal, powered by JK Solution</Footer>
                                    </Layout>
                                </Layout>
                            </Layout>
                        ) : (
                                <Redirect
                                    to={{ pathname: "/login", state: { from: props.location } }}
                                />
                            )
                    )
                }
                }
            />
        </React.Fragment>
    );
}

const mapStateToProps = state => {
    return {
        data: state.permissionsReducer.sideBarOptions
    };
};
// const mapDispatchToProps = dispatch => {
//   return {
//     shouldRedirectToPath: (id, history) => dispatch(shouldRedirectToPath(id, history))
//   };
// };

export default privateRoutesWithSideBar = connect(mapStateToProps)(
    privateRoutesWithSideBar
);
