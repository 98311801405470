import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getValue } from "../../../../common/helpers/validations";
import ReactTable from "react-table";
import { Link } from 'react-router-dom'
import { BASE_URL } from "../../../../common/helpers/urlsConstants";
import { downloadFile } from "../../../../common/helpers/fileDownload";
import { findProductList, findProductById } from "./api";
import { tableColumHeaders } from "./tableConstants";
import { DisappearedLoading } from 'react-loadingg';
import ProductUpload from "./productUpload";
import Header from "antd/lib/calendar/Header";
import { render } from "react-dom";
import EditProductViews from "./editProductView";

let Product = props => {

    let { userCompanyId } = props;
    const [products, setProductList] = useState([]);
    const [show, setShow] = useState(false);
    const openModal = () => setShow(true);
    const closeModal = () => setShow(false);
    const [loading, setLoading] = useState(true)
    
    useEffect(() => {
        findProductList(userCompanyId).then(data => {

            setLoading(false);
            setProductList(data[0]);

        });
    }, []);

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-12 mt-20">
                    <div className="panel panel-default">
                        <div className="panel-body">
                            <div className="panel-title text-left">
                                Product
                                 <button className="btn btn-info btn-upload" onClick={openModal} type="button" >Upload</button>
                                <ProductUpload closeModal={closeModal} show={show} />
                                <button className="btn btn-info btn-download" type="button">
                                    <a onClick={() => downloadFile(`${BASE_URL}/product/dowloadProductReport?companyid=${props.userCompanyId}`, "Product.xlsx")} style={{
                                        color: "inherit", textDecoration: "none",
                                    }} >Download</a>
                                </button>


                                {props.sideBarMenuOptions.length !== 0 ?
                                    props.sideBarMenuOptions.Master.map((child, i) => (
                                        child.pageName == "Product" ?
                                            child.rpAdd == 1 ?
                                                <Link to={`/product/addProduct/?id=`}>
                                                    <button className="btn btn-info btn-add"> New
                                    </button>
                                                </Link>
                                                :
                                                <Link>
                                                    <button className="btn btn-info btn-add disabled"> New
                                    </button>
                                                </Link>
                                            : ""
                                    ))
                                    : ("")
                                }


                            </div>
                            {loading ? <DisappearedLoading color="#5f6973" /> :
                                <ReactTable
                                    data={products || []}
                                    columns={tableColumHeaders(userCompanyId, setProductList, props.sideBarMenuOptions)}
                                    filterable
                                    minRows={0}
                                    defaultPageSize={10}
                                    noDataText={"No Data"}
                                    showPageSizeOptions={true}
                                    defaultFilterMethod={filterCaseInsensitive}
                                    defaultSorted={[{ id: "productId", desc: true }]}
                                />
                            }
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
const mapStateToProps = state => {
    return {
        userCompanyId: getValue(state, ["loginReducer", "userCompanyId"]),
        sideBarMenuOptions: state.permissionsReducer.sideBarOptions
    };
};



function filterCaseInsensitive(filter, row) {
    const id = filter.pivotId || filter.id;
    return (
        row[id] !== undefined ?
            String(row[id].toLowerCase()).indexOf(filter.value.toLowerCase()) > -1
            :
            true
    );
}

export default Product = connect(mapStateToProps)(Product);



