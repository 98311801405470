import axios from 'axios';
//import { BASE_URL } from '../common/helpers/urlsConstants';
window.axios = axios;

export const getZone = async () => {

	let zone_json = await fetch('/MetaData/zone.json');
	let zone = await zone_json.json();
	//console.log("zone_json=",zone);
	return zone
}

export const getCountry = async () => {

	let country_json = await fetch('/MetaData/country.json')
	let country = await country_json.json();
	return country
}

export const getState = async () => {

	let state_json = await fetch('/MetaData/state.json')
	let state = await state_json.json();
	return state
}

export const getSegment = async () => {

	let segment_json = await fetch('/MetaData/segment.json')
	let segment = await segment_json.json();
	return segment
}

export const getSubSegment = async () => {

	let subSegment_json = await fetch('/MetaData/subSegment.json')
	let subSegment = await subSegment_json.json();
	return subSegment
}


export const getSubSbu = async () => {

	let subSbu_json = await fetch('/MetaData/subsbu.json')
	let subSbu = await subSbu_json.json();
	return subSbu
}


export const getSelectData = async () => {

	let selectData_json = await fetch('/MetaData/selectData.json')
	let selectData = await selectData_json.json();
	return selectData
}


export const getEnac5 = async () => {

	let enac5_json = await fetch('/MetaData/marketSegment.json')
	let enac5 = await enac5_json.json();
	return enac5
}


export const getMarketSegment = async () => {

	let marketSegment_json = await fetch('/MetaData/marketSegment.json')
	let marketSegment = await marketSegment_json.json();
	return marketSegment
}

export const getMarketSubSegment = async () => {

	let marketSubSegment_json = await fetch('/MetaData/marketSubSegment.json')
	let marketSubSegment = await marketSubSegment_json.json();
	return marketSubSegment
}
export const getCustomerGroup = async () => {

	let customerGroup_json = await fetch('/MetaData/customergroup.json')
	let customerGroup = await customerGroup_json.json();
	return customerGroup
}


export const getEngineer = async () => {

	let engineer_json = await fetch('/MetaData/engineer.json')
	let engineer = await engineer_json.json();
	return engineer
}

export const getAlliedBrands = async () => {

	let alliedBrands_json = await fetch('/MetaData/alliedbrands.json')
	let alliedBrands = await alliedBrands_json.json();
	return alliedBrands
}

export const getTitle = async () => {

	let title_json = await fetch('/MetaData/title.json')
	let title = await title_json.json();
	return title
}

export const getLegalNature = async () => {

	let legalNature_json = await fetch('/MetaData/legalnature.json')
	let legalNature = await legalNature_json.json();
	return legalNature
}

export const getManager = async () => {

	let manager_json = await fetch('/MetaData/manager.json')
	let manager = await manager_json.json();
	return manager
}

export const getDistributorType = async () => {

	let distributorType_json = await fetch('/MetaData/distributorType.json')
	let distributorType = await distributorType_json.json();
	return distributorType
}

export const getDashboardSE = async () => {
	
	let dashboardSE_json = await fetch('/MetaData/dashboardSE.json')
	let dashboardSE = await dashboardSE_json.json();
	return dashboardSE
}

export const getCompany = async () => {
	let company_json = await fetch('/MetaData/company.json')
	let company = await company_json.json();
	return company
}

export const getAppLevel1 = async () => {
	let appLevel1_json = await fetch('/MetaData/applevel1.json')
	let applevel1 = await appLevel1_json.json();
	return applevel1
}

export const getAppLevel2 = async () => {
	let applevel2_json = await fetch('/MetaData/applevel2.json')
	let applevel2 = await applevel2_json.json();
	return applevel2
}

export const getUom = async () => {
	let uom_json = await fetch('/MetaData/uom.json')
	let uom = await uom_json.json();
	return uom
}

export const getProductGroup = async () => {
	let productGroup_json = await fetch('/MetaData/productgroup.json')
	let productGroup = await productGroup_json.json();
	return productGroup
}

export const getSteeringUName = async () => {
	let steeringUName_json = await fetch('/MetaData/steeringUName.json')
	let steeringUName = await steeringUName_json.json();
	return steeringUName
}

export const getSupportEngineer = async () => {
    let supportEngi_json = await fetch('/MetaData/supportEngg.json')
    let supportEngi = await supportEngi_json.json();
    return supportEngi
}

export const getTicketStatus = async () => {
    let ticketStatus_json = await fetch('/MetaData/ticketStatus.json')
    let ticketStatus = await ticketStatus_json.json();
    return ticketStatus
}

export const getUserType = async () => {
    let userType_json = await fetch('/MetaData/userType.json')
    let userType = await userType_json.json();
    return userType
}

export const getDepartment = async () => {
    let department_json = await fetch('/MetaData/department.json')
    let department = await department_json.json();
    return department 
}

export const getDmtRoles = async () => {
    let dmtRoles_json = await fetch('/MetaData/dmtRole.json')
    let dmtRoles = await dmtRoles_json.json();
    return dmtRoles
} 

export const getRole = async () => {
    let role_json = await fetch('/MetaData/role.json')
    let role = await role_json.json();
    return role
}

export const getReportingManager = async () => {
    let reportingManager_json = await fetch('/MetaData/reportingmanger.json')
    let reportingManager = await reportingManager_json.json();
    return reportingManager
} 