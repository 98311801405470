import React from "react";
import { Link } from 'react-router-dom'
import { deleteCustomerById, findCustomerList } from "./api";


export const tableColumHeaders = (setEditViewCustomerId, userId, setCustomerList, sideBarMenuOptions) => {
    function onDelete(data) {
        deleteCustomerById(data).then(resp => {
            if (resp) {
                alert(`Customer ${userId} Deleted `);
                findCustomerList(userId).then(data => {
                    setCustomerList(data.content);
                });

            }
        })
    }

    function handleChange(e) {
    }

    return [
        {
            Header: "SSA ID",
            accessor: "custId",
            maxWidth: 60,
            sortable: true,
            filterable: true,
            style: {
                textAlign: "right"
            },
            Cell: props => {
                return (
                    sideBarMenuOptions.length !== 0 ?
                        sideBarMenuOptions.Master.map((child, i) => (
                            child.pageName == "Add New Customer" ?
                                <div className="text-center">
                                    <Link
                                        className={'mr-3 btn-edit'}
                                        style={{ color: "#000000", textDecoration: "underline" }}
                                        to={`/distributorscustomer/editCustomer/?id=${(props && props.original) ? props.original.custId : 0}&read=true`}
                                    >
                                        {props.value}
                                    </Link>
                                </div> : ""
                        )
                        ) : ""
                );
            }
        },
        {
            Header: "Code",
            accessor: "custCode",
            sortable: true,
            filterable: true,
            style: {
                textAlign: "left"
            },
            maxWidth: 80
        },
        {
            Header: "Name",
            accessor: "custName",
            sortable: true,
            filterable: true,
            style: {
                textAlign: "left"
            },
            maxWidth: 400
        },
        {
            Header: "Distributor",
            accessor: "distDistName",
            sortable: true,
            filterable: true,
            style: {
                textAlign: "left"
            },
            maxWidth: 400
        },
        {
            Header: "Zone",
            accessor: "zoneName",
            sortable: true,
            filterable: true,
            style: {
                textAlign: "left"
            },
            maxWidth: 100
        },
        /*{
          Header: "Email Id",
          accessor: "emailId",
          sortable: true,
          filterable: true,
          style: {
            textAlign: "left"
          }
        },
        {
          Header: "Phone Number",
          accessor: "phoneNumber",
          sortable: true,
          filterable: true,
          style: {
            textAlign: "left"
          }
        },*/
        {
            Header: "Henkel Sales Person",
            accessor: "henkelSalesPerson",
            sortable: true,
            filterable: true,
            style: {
                textAlign: "left"
            },
            maxWidth: 250
        },
        {
            Header: "Approved",
            accessor: "custApprove",
            sortable: true,
            filterable: false,
            maxWidth: 100,
            Cell: props => {
                return (
                    <input
                        type="checkbox"
                        className="checkbox"
                        checked={(props.original.custApprove === 1 ? true : false)}
                        onChange={handleChange}
                    />
                );
            }
        },
        {
            Header: "Action",
            sortable: false,
            filterable: false,
            Cell: props => {
                return (
                    sideBarMenuOptions.length !== 0 ?
                        sideBarMenuOptions.Master.map((child, i) => (
                            child.pageName == "Add New Customer" ?
                                <div className="text-center">
                                    {child.rpEdit == 1 ?
                                        <Link
                                            className={'mr-3 btn-edit'}
                                            style={{ color: "#000000" }}
                                            to={`/distributorscustomer/editCustomer/?id=${(props && props.original) ? props.original.custId : 0}`}
                                        >
                                            <span className="glyphicon glyphicon-edit"></span>
                                        </Link>
                                        :
                                        <Link
                                            className={'mr-3 btn-edit'}
                                            style={{ color: "#000000" }}
                                        >
                                            <span className="glyphicon glyphicon-edit disabled"></span>
                                        </Link>
                                    }

                                    {child.rpDelete == 1 ?
                                        <Link
                                            className="btn-delete"
                                            style={{ color: "#000000" }}
                                            onClick={() => onDelete(props.original.custId)}
                                            to="#"
                                        >
                                            <span className="glyphicon glyphicon-trash"></span>
                                        </Link>
                                        :
                                        <Link
                                            className="btn-delete"
                                            style={{ color: "#000000" }}
                                            to="#"
                                        >
                                            <span className="glyphicon glyphicon-trash disabled"></span>
                                        </Link>
                                    }
                                </div> : ""
                        )
                        ) : ""
                );
            }
            //Cell: props => {

            //    return (
            //        <div className="text-center">
            //            <Link
            //                className={'mr-3 btn-edit'}
            //                style={{ color: "#000000" }}
            //                to={`/distributorscustomer/editCustomer/?id=${(props && props.original) ? props.original.custId : 0}`}
            //            >
            //                <span className="glyphicon glyphicon-edit"></span>
            //            </Link>
            //            <Link
            //                className="btn-delete"
            //                style={{ color: "#000000" }}
            //                onClick={() => onDelete(props.original.custId)}
            //                to="#"
            //            >
            //                <span className="glyphicon glyphicon-trash"></span>
            //            </Link>
            //        </div>
            //    );
            //}

        }
    ];
};

