import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getValue } from "../../common/helpers/validations";
import ReactTable from "react-table";
import { Link } from 'react-router-dom'
import { tableColumHeaders } from "./tableConstants";
import { DisappearedLoading } from 'react-loadingg';
import { rehydrateReducer } from "./actionCreator";
import { getTicketDashboardData } from "./api";

let Ticket = props => {

    let { userId, userSupportAdmin } = props;
    const [ticketList, setTicketList] = useState([]);
    const [show, setShow] = useState(false);
    const openModal = () => setShow(true);
    const closeModal = () => setShow(false);
    const [loading, setLoading] = useState(true)
    

    useEffect(() => {
        //console.log("props", props);
        
        if (userSupportAdmin == null) {
            getTicketDashboardData(userId, 0).then(data => {
                //console.log("data", data);
                setLoading(false);
                /*let filteredData = data.filter(function (e) {
                    return e.ticketStatus.match("CLOSED");
                });*/
                setTicketList(data);

            });
        }
        else {
            getTicketDashboardData(userId, userSupportAdmin).then(data => {
                setLoading(false);
                setTicketList(data);
            });
        }

    }, []);
   

    return (

        <div className="container-fluid">
            <div className="row">
                <div className="col-md-12 ">
                    <h1>Ticket</h1>
                </div>
                <div className="col-md-12 ">
                    <div className="panel panel-default col-md-12">
                        <div className="row">
                        </div>
                        <div className="panel-body">
                            <div className="panel-title text-left">
                                Detail View
                                <Link to={`/ticket/addTicket/?id=`}>
                                    <button className="btn btn-info btn-add"> New
                                    </button>
                                </Link>
                            </div>
                            {loading ? <DisappearedLoading color="#5f6973" /> :
                                <ReactTable
                                    data={ticketList || []}
                                    columns={tableColumHeaders(userId, setTicketList)}
                                    filterable
                                    minRows={0}
                                    defaultPageSize={10}
                                    noDataText={"No Data"}
                                    showPageSizeOptions={true}
                                    defaultFilterMethod={(filter,row)=>{return row[filter.id].indexOf(filter.value)>-1;}}
                                    defaultSorted={[{ id: "ticketId", desc: true }]}
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
const mapStateToProps = state => {
    return {
        userId: getValue(state, ["loginReducer", "userId"]),
        userSupportAdmin: getValue(state, ["loginReducer", "usrSupportAdmin"])
    };
};

export default Ticket = connect(mapStateToProps)(Ticket);



