import {
    LOGIN,
    LOGIN_FAILED,
    LOGOUT,
    REHYDRATE_REDUCER,
    CREATE_OFFER
} from "../../common/actions/actionTypes";

export function doLogin(values, history) {
    const { email, password } = values;
    return {
        type: LOGIN,
        payload: { email, password, history }
    };
}

export function doLogout() {
    return {
        type: LOGOUT
    };
}

export function loginFailed() {
    return {
        type: LOGIN_FAILED
    };
}

export function rehydrateReducer(values) {
    return {
        type: REHYDRATE_REDUCER,
        payload: values
    }
}

export function createOfferAction(values) {
    const counter = values;
    return {
        type: CREATE_OFFER,
        payload: counter
    };
}

export function shouldRedirectToPath(value) {
    //console.log("inaction creator", value);
    return {
        type: "REDIRECT_TO",
        payload: value
    }

}