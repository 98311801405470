import { get } from "axios";
import fileDownload from "js-file-download";


export const downloadFile = async(url,fileName) =>{

      await get(url,{
        headers:{
            "Authorization": `Bearer ${sessionStorage.getItem("tokenId").replace(/['"]+/g, '')}`
        },
        responseType: 'blob'
      }).then(res=>{
        fileDownload(res.data,fileName);
      });

};