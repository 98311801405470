import { Modal, ModalTitle } from 'react-bootstrap';
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import { getValue } from "../../../../common/helpers/validations";
import Select from "../../../../common/components/Select";
import { fileUpload } from "./api";
import { Link, useLocation } from "react-router-dom";
import Notification from '../../../../common/notification'
import { getCompany } from "../../../../services/staticdata";
import moment from 'moment';


let DeviationUpload = props => {

    const [respId, setrespId] = useState(false);
    const [formSubmitting, setformSubmitting] = useState(false);
    const [formSubmitted, setformSubmitted] = useState(false);
    let { userId, userCompanyId} = props;
    const { show, closeModal } = props;

    const [company, setCompany] = useState([]);

    async function loadStaticData() {
        setCompany(await getCompany());
    }

    let filteredCompany = company.filter(function (e) {
        return e.key == userCompanyId;
    });

    useEffect(() => {
        loadStaticData();
    }, []);


    return (
        <Modal show={show} onHide={closeModal}>
            <Modal.Header>
                <ModalTitle>
                    BULK DEVIATION UPLOAD
                    <button onClick={closeModal} className="close" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </ModalTitle>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <Formik initialValues={{
                        file: null, inputJson: null,
                    }} onSubmit={values => {
                        let inputJson = JSON.stringify({ "type": "bulkStDeviationUpload", "userid": userId, "reportDate": moment().format('yyyyMMDD'), "companyId": values.Company });
                        setformSubmitting(true);
                        fileUpload(values.file, inputJson).then(response => {
                            if (response.data.batchId) {
                                Notification.success('Uploaded Successful.');
                                setformSubmitted(true);
                                setrespId(response.data.batchId);
                                setformSubmitting(true);
                            }
                            else {
                                setformSubmitting(false);
                            }
                        }).catch(err => {
                            setformSubmitting(false);
                            return err;
                        })
                    }}
                    >
                        {({ handleSubmit, values, setFieldValue }) => (

                            <form className={formSubmitting ? "disabled" : ""} onSubmit={handleSubmit}>
                                <div className="col-md-12 mt-15">
                                    <div className="col-md-4">
                                        <label htmlFor="Company" style={{ display: "block" }}>
                                            Company
                                        </label>
                                    </div>
                                    <div className="col-md-8">

                                        <Select
                                            id="Company"
                                            value={values.Company}
                                            options={filteredCompany}
                                            onChange={(field, value) => {
                                                setFieldValue("Company", value);
                                            }}
                                        />

                                    </div>
                                </div>


                                <div className="col-md-12 mt-15">
                                    <div className="col-md-4">
                                        <label htmlFor="file" style={{ display: "block" }}>
                                            File
                                    </label>
                                    </div>
                                    <div className="col-md-8">
                                        <input
                                            id={"file"}
                                            type="file"
                                            onChange={(e, value) => {
                                                setFieldValue("file", e.target.files[0]);
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="col-md-12 mt-20 mb-20">
                                    <div className="col-md-4">
                                    </div>
                                    <div className="col-md-8">
                                        <button type="submit" className={" btn btn-info btn-fileupload " + (formSubmitting && "disabled")}>Upload</button>
                                        <button type="button" className="btn btn-filecancle" onClick={closeModal}>Cancel</button>
                                    </div>
                                </div>

                            </form>
                        )}
                    </Formik>

                    {respId && (
                        <Link to="/fileUploadStatus">
                            <div className="alert alert-success cursor mt-15" role="alert" style={{ clear: "both", marginLeft: "15px", width: "95%" }}>
                                {'Deviation data upload job is submitted. Click here to check the status and copy batch id.'}
                                <strong id="batchId">{respId}</strong>
                            </div>
                        </Link>
                    )}

                </div>
            </Modal.Body>
        </Modal>

    );
};
const mapStateToProps = state => {
    return {
        userId: getValue(state, ["loginReducer", "userId"]),
        userCompanyId: getValue(state, ["loginReducer", "userCompanyId"])
    };
};


export default DeviationUpload = connect(
    mapStateToProps
)(DeviationUpload);
