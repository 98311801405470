import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getValue } from "../../../../common/helpers/validations";
import { DisappearedLoading } from 'react-loadingg';
import Select from "../../../../common/components/Select";
import { useHistory } from 'react-router-dom';
import { DatePicker } from 'antd';
import moment from 'moment';
import { getCompany } from "../../../../services/staticdata";
import { findDistributorListAPI } from "../../../../services/api";
import { Formik } from "formik";
import { Reprocessdata } from "./api";
import { Link } from "react-router-dom";



let SalesReprocess = props => {
    const [formSubmitting, setformSubmitting] = useState(false);
    const [respId, setrespId] = useState(false);
    const [formSubmitted, setformSubmitted] = useState(false);
    const { MonthPicker } = DatePicker;
    const [distributorList, setDistributorList] = useState(null);

    let { userId, userCompanyId } = props;
    const [company, setCompany] = useState([]);

    async function loadStaticData() {
        setCompany(await getCompany());
    }

    let filteredCompany = company.filter(function (e) {
        return e.key == userCompanyId;
    });

    useEffect(() => {
        loadStaticData();
    }, []);

    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());

    const handleFromDateChange = date => {
        setFromDate(date);
    };

    const handleToDateChange = date => {
        setToDate(date);
    };

    const history = useHistory()
    const handleCancle = () => {
        history.goBack()
    };


    useEffect(() => {
        findDistributorListAPI(userId).then(data => {
            let newDistArray = data.map(i => {
                let option = { key: i.distId, value: i.distIdhCode + " " + i.distDistName }
                return option
            })
            setDistributorList(newDistArray);
        })

    }, []);

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-12 mt-20">
                    <div className="panel panel-default">
                        <div className="panel-title text-left">Sales Reprocess</div>
                        <div className="editpanel">
                            <Formik initialValues={{
                                inputJson: null,
                            }} onSubmit={values => {
                                debugger;
                                let inputJson = JSON.stringify({
                                    "type": "reprocessdata", "distid": values.distId, "reportDate": moment().format('yyyyMMDD'), "userid": userId, "fromDate": moment(fromDate).format('yyyyMMDD'), "toDate": moment(toDate).format('yyyyMMDD')
                                });
                                setformSubmitting(true);
                                Reprocessdata(inputJson).then(response => {
                                    debugger;
                                    if (response.data.batchId) {
                                        Notification.success('Reprocessed Successful.');
                                        setrespId(response.data.batchId);
                                        setformSubmitted(true);
                                        setformSubmitting(true);
                                    }
                                    else {
                                        setformSubmitting(false);
                                    }
                                }).catch(err => {
                                    setformSubmitting(false);
                                    return err;
                                })
                            }}
                            >
                                {({ handleSubmit, values, setFieldValue }) => (

                                    <form onSubmit={handleSubmit}>
                                        {formSubmitting ?
                                            <DisappearedLoading color="#5f6973" />
                                            : ("")}
                                        <div className="col-md-12 mt-15">
                                            <div className="col-md-2">
                                                <label htmlFor="Company" style={{ display: "block" }}>
                                                    Company
                                                </label>
                                            </div>
                                            <div className="col-md-10">
                                                <Select
                                                    id="Company"
                                                    value={values.Company}
                                                    options={filteredCompany}
                                                    onChange={(field, value) => {
                                                        setFieldValue("Company", value);
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-12 mt-15">
                                            <div className="col-md-2">
                                                <label htmlFor="distId" style={{ display: "block" }}>
                                                    Distributor
                                                </label>
                                            </div>
                                            <div className="col-md-10">
                                                <Select
                                                    id="distId"
                                                    value={values.distId}
                                                    options={distributorList}
                                                    onChange={(field, value) => {
                                                        setFieldValue("distId", value);
                                                    }}
                                                />
                                            </div>
                                        </div>


                                        <div className="col-md-12 mt-15">
                                            <div className="col-md-2">
                                                <label htmlFor="FromDate" style={{ display: "block" }}>
                                                    From Date
                                                </label>
                                            </div>
                                            <div className="col-md-10">
                                                <DatePicker
                                                    id={"FromDate"}
                                                    onChange={handleFromDateChange}
                                                    format="DD-MMM-yyyy"
                                                    showMonthYearPicker={true}
                                                    placeholder="Select Date"
                                                />
                                            </div>
                                        </div>


                                        <div className="col-md-12 mt-15">
                                            <div className="col-md-2">
                                                <label htmlFor="ToDate" style={{ display: "block" }}>
                                                    To Date
                                                </label>
                                            </div>
                                            <div className="col-md-10">
                                                <DatePicker
                                                    id={"ToDate"}
                                                    onChange={handleToDateChange}
                                                    format="DD-MMM-yyyy"
                                                    showMonthYearPicker={true}
                                                    placeholder="Select Date"
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-12 mt-20 mb-20">
                                            <div className="col-md-2">
                                            </div>
                                            <div className="col-md-10">
                                                <button type="submit" className={" btn btn-info btn-fileupload " + (formSubmitting && "disabled")}>Reprocess</button>
                                                <button type="button" className="btn btn-filecancle" onClick={handleCancle}>
                                                    Cancel</button>
                                            </div>
                                        </div>

                                    </form>
                                )}
                            </Formik>
                            {respId && (
                                <Link to="/fileUploadStatus">
                                    <div className="alert alert-success cursor" role="alert" style={{ clear: "both" }}>
                                        {'Primary sales file is submitted. Click here to check the status and copy batch id.'}
                                        <strong id="batchId">{respId}</strong>
                                    </div>
                                </Link>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        userId: getValue(state, ["loginReducer", "userId"]),
        userCompanyId: getValue(state, ["loginReducer", "userCompanyId"])
    };
};
export default SalesReprocess = connect(mapStateToProps)(SalesReprocess);




