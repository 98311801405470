import React, { useEffect, useState } from "react";
import { Field, withFormik } from "formik";
import { Collapse, Row, Col } from "antd";
import { InputBox } from "../../../../common/components/InputBox";
import Select from "../../../../common/components/Select";
import { basicInformation, businessData, manPower, securityDeposite } from "./editDistributorViewFields.json"
import { findDistributorById, saveOrUpdateDistributor } from "./api";
import { useLocation, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import Notification from '../../../../common/notification'
import { getValue, validatePinCode, validateEmail, required, requiredField } from "../../../../common/helpers/validations";
import { getManager, getLegalNature, getAlliedBrands, getZone, getState, getDistributorType } from "../../../../services/staticdata";
import { WaveTopBottomLoading } from 'react-loadingg';


const formikEnhancer = withFormik({

    mapPropsToValues: ({ user, props }) => ({
        ...user,
        ...props
    }),
    handleSubmit: (payload, { setSubmitting, props }) => {
        setSubmitting(true);
        saveOrUpdateDistributor(payload).then(resp => {
            //console.log("savedData", resp);
            if (resp.status === 200) {
                Notification.success('Saved Successful.');
                props.history.goBack()
            }
            else {
                Notification.error('Error while saving data:' + resp.data.status_response);
            }
            setSubmitting(false);
        })

    },
    displayName: "MyForm"
});


let EditDistributorView = (props) => {

    let { userId } = props;
    const { handleSubmit, setFieldValue, setValues, values, isSubmitting } = props;
    const { Panel } = Collapse;

    const [zone, setZone] = useState([]);
    const [state, setState] = useState([]);
    const [manager, setManager] = useState([]);
    const [legalNature, setLegalNature] = useState([]);
    const [alliedBrands, setAlliedBrands] = useState([]);
    const [distributorType, setDistributorType] = useState([]);
    const [loading, setLoading] = useState(true);

    const initData = {
        distActive: 0,
        distAdd1: null,
        distAdd2: null,
        distAdd3: null,
        distAlied1: 0,
        distAlied2: 0,
        distAlied3: 0,
        distAlied4: 0,
        distAlied5: 0,
        distAlied6: 0,
        distAlied7: 0,
        distApproval: 0,
        distChqDt: "",
        distChqNo: "",
        distCity: "",
        distCountryId: 0,
        distCrDt: null,
        distCrUid: userId,
        distCustomerCount: 0,
        distDistName: "",
        distDistShortname: "",
        distDistType: "",
        distDistrict: "",
        distEmail: "",
        distEmailGroup: "",
        distEnggId: 0,
        distEstablishmentYear: 0,
        distGstNo: "",
        distIdhCode: "",
        distKeycontact: "",
        distKeycontactTitle: 0,
        distLandlineNo: "",
        distLegalnature: 0,
        distNofinanceEmp: 0,
        distNoofCustomer: 0,
        distNoofcountEmp: 0,
        distNoofemp: 0,
        distNoofsalesEmp: 0,
        distNooftechEmp: 0,
        distPanNo: "",
        distPhone: "",
        distPincode: "",
        distRemarks: "",
        distSeqAmount: 0,
        distStateId: 0,
        distTaluka: "",
        distTotalTurnover: 0,
        distTurnover1: 0,
        distTurnover2: 0,
        distTurnover3: 0,
        distTurnover4: 0,
        distTurnover5: 0,
        distTurnover6: 0,
        distTurnover7: 0,
        distUpDt: 0,
        distUpUid: 0,
        distUsername: "",
        distWebsite: "",
        distZoneId: ""
    }

    async function loadStaticData() {
        setZone(await getZone());
        setState(await getState());
        setManager(await getManager());
        setLegalNature(await getLegalNature());
        setAlliedBrands(await getAlliedBrands());
        setDistributorType(await getDistributorType());
    }


    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    const distId = useQuery().get("id");
    const readOnly = useQuery().get("read");
    //console.log("readOnlyMessage from query", readOnly);

    const history = useHistory()
    const handleCancle = () => {
        history.goBack()
    };


    useEffect(() => {
        loadStaticData();
        if (distId) {
            findDistributorById(distId).then(data => {
                setValues(data[0]);
                setLoading(false);
            });
        }
        else {
            setValues(initData);
            setLoading(false);
        }

    }, []);


    return (
        <>
            {loading ? <WaveTopBottomLoading color="#ef4836" /> :
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 mt-20">
                            <div className="panel panel-default">
                                <div className={readOnly === "true" ? "panel-body disabled" : "panel-body"}>
                                    <div className="panel-title text-left">Distributors
                                        <button
                                            className="btn btn-cancle"
                                            style={{ float: "right" }}
                                            type="button btn"
                                            onClick={handleCancle}>
                                            {"Cancel"}
                                        </button>
                                        <button
                                            className={isSubmitting ? " btn btn-success btn-save disabled" : "btn btn-save btn-success"}
                                            style={{ float: "right" }}
                                            type="submit"
                                            onClick={handleSubmit}>
                                            {"Save"}
                                        </button>

                                    </div>

                                    <form onSubmit={handleSubmit}>
                                        {isSubmitting ?
                                            <WaveTopBottomLoading color="#ef4836" />
                                            : ("")}
                                        <Collapse accordion defaultActiveKey={["1"]} className="text-left">

                                            <Panel header="Basic Information" key="1">
                                                <Row>
                                                    <Col>
                                                        {basicInformation.map((item, index) => {
                                                            return (
                                                                <div key={index} >
                                                                    {item.ObjectType === "Textbox" ? (

                                                                        <div className={item.Editable === "No" ? "col-md-12 display-flex disabled" : "col-md-12 display-flex"}>
                                                                            <label className="form_label w-30">
                                                                                {item.FieldName} {item.Mandatory && <span className="error">* </span>}
                                                                            </label>
                                                                            {item.backendMappingName === "distEmail" ? (
                                                                                <div className="form_input w-70">
                                                                                    <Field
                                                                                        name={item.backendMappingName}
                                                                                        component={InputBox}
                                                                                        type={"text"}

                                                                                        placeholder={item.FieldName}
                                                                                        value={values[item.backendMappingName] || ''}
                                                                                        validate={validateEmail.bind(this, item.FieldName)}
                                                                                    />
                                                                                </div>
                                                                            ) :
                                                                                (
                                                                                    <div className="form_input w-70">
                                                                                        <Field
                                                                                            name={item.backendMappingName}
                                                                                            component={InputBox}
                                                                                            type={"text"}

                                                                                            placeholder={item.FieldName}
                                                                                            value={values[item.backendMappingName] || ''}
                                                                                            validate={item.backendMappingName === "distPincode" ? (validatePinCode.bind(this, item.FieldName)) : (item.Mandatory && required.bind(this, item.FieldName))}
                                                                                        />
                                                                                    </div>
                                                                                )}
                                                                        </div>
                                                                    ) : (
                                                                            <div className="col-md-12  display-flex">
                                                                                <label
                                                                                    className="form_label w-30"
                                                                                    htmlFor={item.backendMappingName}
                                                                                    style={{ display: "block" }}
                                                                                >
                                                                                    {item.FieldName} {item.Mandatory && <span className="error">* </span>}
                                                                                </label>
                                                                                <div className=" form_input w-70">
                                                                                    <Select
                                                                                        id={item.backendMappingName}
                                                                                        name={item.backendMappingName}
                                                                                        value={values[item.backendMappingName] || ''}
                                                                                        options={eval(item.Source)}
                                                                                        onChange={(field, value) => {
                                                                                            setFieldValue(field, value);
                                                                                        }}
                                                                                        validate={item.Mandatory && requiredField(values[item.backendMappingName])}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                </div>
                                                            );
                                                        })}
                                                    </Col>
                                                </Row>
                                            </Panel>

                                            <Panel header="Bussiness Data" key="2">
                                                <Row>
                                                    <Col>
                                                        {businessData.map((item, index) => {

                                                            return (
                                                                <div key={index} >
                                                                    {item.ObjectType === "Textbox" ? (
                                                                        <div className={item.Editable === "No" ? "col-md-12 display-flex disabled" : "col-md-12 display-flex"}>
                                                                            <label className="form_label w-30">
                                                                                {item.FieldName}  {item.Mandatory && <span className="error">* </span>}
                                                                            </label>
                                                                            <Field
                                                                                name={item.backendMappingName}
                                                                                className="form_input w-70"
                                                                                component={InputBox}
                                                                                type={"text"}
                                                                                validate={item.Mandatory && required.bind(this, item.FieldName)}
                                                                                placeholder={item.FieldName}
                                                                                value={values[item.backendMappingName] || ''}
                                                                            />
                                                                        </div>
                                                                    ) : (
                                                                            <div className="col-md-12  display-flex">
                                                                                <label
                                                                                    className="form_label w-30"
                                                                                    htmlFor={item.backendMappingName}
                                                                                    style={{ display: "block" }}
                                                                                >
                                                                                    {item.FieldName}
                                                                                </label>
                                                                                <div className=" form_input w-70">
                                                                                    <Select
                                                                                        id={item.backendMappingName}
                                                                                        name={item.backendMappingName}
                                                                                        value={values[item.backendMappingName] || ''}
                                                                                        options={eval(item.Source)}
                                                                                        onChange={(field, value) => {
                                                                                            setFieldValue(field, value);
                                                                                        }}
                                                                                        validate={item.Mandatory && requiredField(values[item.backendMappingName])}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                </div>
                                                            );
                                                        })}
                                                    </Col>
                                                </Row>
                                            </Panel>

                                            <Panel header="Manpower" key="3">
                                                <Row>
                                                    <Col>
                                                        {manPower.map((item, index) => {

                                                            return (
                                                                <div key={index} >
                                                                    {item.ObjectType === "Textbox" ? (
                                                                        <div className={item.Editable === "No" ? "col-md-12 display-flex disabled" : "col-md-12 display-flex"}>
                                                                            <label className="form_label w-30">
                                                                                {item.FieldName}  {item.Mandatory && <span className="error">* </span>}
                                                                            </label>
                                                                            <Field
                                                                                name={item.backendMappingName}
                                                                                className="form_input w-70"
                                                                                component={InputBox}
                                                                                type={"text"}
                                                                                validate={item.Mandatory && required.bind(this, item.FieldName)}
                                                                                placeholder={item.FieldName}
                                                                                value={values[item.backendMappingName] || ''}
                                                                            />
                                                                        </div>
                                                                    ) : (
                                                                            <div className="col-md-12 display-flex">
                                                                                <label
                                                                                    className="form_label w-30"
                                                                                    htmlFor={item.backendMappingName}
                                                                                    style={{ display: "block" }}
                                                                                >
                                                                                    {item.FieldName}
                                                                                </label>
                                                                                <div className=" form_input w-70">
                                                                                    <Select
                                                                                        id={item.backendMappingName}
                                                                                        name={item.backendMappingName}
                                                                                        value={values[item.backendMappingName] || ''}
                                                                                        options={eval(item.Source)}
                                                                                        onChange={(field, value) => {
                                                                                            setFieldValue(field, value);
                                                                                        }}
                                                                                        validate={item.Mandatory && requiredField(values[item.backendMappingName])}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                </div>
                                                            );
                                                        })}
                                                    </Col>
                                                </Row>
                                            </Panel>

                                            <Panel header="Security Deposite" key="4">
                                                <Row>
                                                    <Col>
                                                        {securityDeposite.map((item, index) => {

                                                            return (
                                                                <div key={index} >
                                                                    {item.ObjectType === "Textbox" ? (
                                                                        <div className={item.Editable === "No" ? "col-md-12 display-flex disabled" : "col-md-12 display-flex"}>
                                                                            <label className="form_label w-30">
                                                                                {item.FieldName} {item.Mandatory && <span className="error">* </span>}
                                                                            </label>
                                                                            <Field
                                                                                name={item.backendMappingName}
                                                                                className="form_input w-70"
                                                                                component={InputBox}
                                                                                type={"text"}
                                                                                validate={item.Mandatory && required.bind(this, item.FieldName)}
                                                                                placeholder={item.FieldName}
                                                                                value={values[item.backendMappingName] || ''}
                                                                            />
                                                                        </div>
                                                                    ) : (
                                                                            <div className="col-md-12 display-flex">
                                                                                <label
                                                                                    className="form_label w-30"
                                                                                    htmlFor={item.backendMappingName}
                                                                                    style={{ display: "block" }}
                                                                                >
                                                                                    {item.FieldName}
                                                                                </label>
                                                                                <div className=" form_input w-70">
                                                                                    <Select
                                                                                        id={item.backendMappingName}
                                                                                        name={item.backendMappingName}
                                                                                        value={values[item.backendMappingName] || ''}
                                                                                        options={eval(item.Source)}
                                                                                        onChange={(field, value) => {
                                                                                            setFieldValue(field, value);
                                                                                        }}
                                                                                        validate={item.Mandatory && requiredField(values[item.backendMappingName])}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                </div>
                                                            );
                                                        })}
                                                    </Col>
                                                </Row>
                                            </Panel>
                                        </Collapse>
                                    </form>

                                    <div className="col-md-12 mt-20 row display-flex">
                                        <button
                                            className={isSubmitting ? " btn btn-success btn-save disabled" : "btn btn-save btn-success"}
                                            type="submit"
                                            onClick={handleSubmit} >
                                            {"Save"}
                                        </button>
                                        <button
                                            className="btn btn-cancle"
                                            type="button btn"
                                            onClick={handleCancle} >
                                            {"Cancel"}
                                        </button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

const mapStateToProps = state => {
    return {
        userId: getValue(state, ["loginReducer", "userId"])
    };
};

const EditDistributorViews = connect(mapStateToProps)(formikEnhancer(EditDistributorView))

export default EditDistributorViews;



