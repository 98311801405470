import React, { useState } from "react";
import { Link } from 'react-router-dom';
import moment from 'moment';

export const tableColumHeaders = () => {

    return [
        {
            Header: "From Date",
            accessor: "fpfromdt",
            sortable: true,
            maxWidth: 200,
            style: {
                textAlign: "center",
            },
            Cell: props => {

                if (props.original.fpfromdt !== null || props.original.fpfromdt !== "" || props.original.fpfromdt !== 0) {
                    return (
                        moment(props.original.fpfromdt).format('DD/MM/YYYY')
                    )
                }
                else {
                    return (
                        moment().format('DD/MM/YYYY')
                    )
                }
            }
        },
        {
            Header: "To Date",
            accessor: "fptodt",
            sortable: true,
            filterable: true,
            style: {
                textAlign: "center"
            },
            maxWidth: 400,
            Cell: props => {
                if (props.original.fptodt !== null || props.original.fptodt !== "" || props.original.fptodt !== 0) {
                    return (
                        moment(props.original.fptodt).format('DD/MM/YYYY')
                    )
                }
                else {
                    return (
                        moment().format('DD/MM/YYYY')
                    )
                }
            }
        },
        {
            Header: "IsOpen",
            accessor: "fpisopen",
            sortable: false,
            filterable: false,
            style: {
                textAlign: "left"
            },
            maxWidth: 400,
            Cell: props => {
                return (
                    <div className="col-md-10">
                        <input
                            name={"overwrite"}
                            type="checkbox"
                            id={"overwrite"}
                            className={"checkbox"}
                            checked={props.original.fpisopen}
                        />
                    </div>
                )
            }
        },
        {
            Header: "Action",
            sortable: false,
            maxWidth: 170,
            filterable: false,
            Cell: props => {
                return (
                    <div className="text-center">
                        <Link
                            className={'mr-3 btn-edit'}
                            style={{ color: "#000000" }}
                            to={`/freezingOfData/editFreezingOfData/?id=${(props && props.original) ? props.original.fpid : 0}`}
                        >
                            <span className="glyphicon glyphicon-edit"></span>
                        </Link>
                        <Link
                            className="btn-delete"
                            style={{ color: "#000000" }}
                            //onClick={() => onDelete(props.original.fpid)}
                            to="#">
                            <span className="glyphicon glyphicon-trash"></span>
                        </Link>
                    </div>
                );
            }
        }
    ]
};


