import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { connect } from "react-redux";
import { rehydrateReducer } from "../../../pages/login/actionCreator"
import { getValue } from "../../helpers/validations";
import { getMenuByUsrIdAndModuleId } from "../../../pages/dashboard/actionCreator";
import { Layout, Menu } from "antd";



function Sidebar(props) {

    const { Sider } = Layout;
    const { SubMenu } = Menu;
    const [collapsed, setCollapsed] = useState(false);
    const [openKeys, setOpenKeys] = useState([]);
    const rootSubmenuKeys = Object.keys(props.sideBarMenuOptions);
 



    const {
        getMenuByUsrIdAndModuleId,
        userId,
        history
    } = props;

    useEffect(() => {
        if (sessionStorage.getItem("moduleId") != null) {
            let moduleId = sessionStorage.getItem("moduleId")
            getMenuByUsrIdAndModuleId(moduleId, userId, history);
        }
    }, []);

    const onCollapse = (collapsed1) => {
        console.log(collapsed1);
        setCollapsed(collapsed1);
        setOpenKeys([]);
    };

    const onOpenChange1 = (openKeys1) => {
        const latestOpenKey = openKeys1.find(key => openKeys.indexOf(key) === -1);
        if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
            setOpenKeys(openKeys1);
        } else {
            setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
        }
    };

    return (
        <Sider collapsible collapsed={collapsed} onCollapse={onCollapse}>
            <div className="logo" />
            <Menu theme="dark" mode="inline" openKeys={openKeys} onOpenChange={onOpenChange1} inlineCollapsed={true}>
                {
                    Object.keys(props.sideBarMenuOptions).map(item => (
                        <SubMenu key={item} title={item}>
                            {
                                props.sideBarMenuOptions[item].map((child, i) => (
                                    <Menu.Item key={i}>
                                        <Link to={`/${child.pageDesc}`}> {child.pageName}</Link>
                                    </Menu.Item>
                                ))
                            }
                        </SubMenu>
                    ))
                }
            </Menu>
        </Sider>
    );
}

const mapStateToProps = state => {
    return {
        sideBarMenuOptions: state.permissionsReducer.sideBarOptions,
        userId: getValue(state, ["loginReducer", "userId"])
    };
};

const mapDispatchToProps = dispatch => {
    return {
        rehydrateReducer: values => dispatch(rehydrateReducer(values)),
        getMenuByUsrIdAndModuleId: (moduleId, userId, history) =>
        dispatch(getMenuByUsrIdAndModuleId(moduleId, userId, history))
    };
};

export default Sidebar = connect(mapStateToProps, mapDispatchToProps)(Sidebar);
