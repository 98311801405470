import { red } from "@material-ui/core/colors";
import React from "react";
import { Link } from 'react-router-dom'
import { deleteProductById, findProductList } from "./api";


export const tableColumHeaders = (userCompanyId, setProductList, sideBarMenuOptions) => {
    function onDelete(data) {
        deleteProductById(data).then(resp => {
            if (resp) {
                alert(`Product ${data} Deleted `);
                findProductList(userCompanyId).then(data => {
                    setProductList(data.content);
                });
            }
        })
    }


    return [
           
         
        {
            Header: "Product IDH",
            accessor: "productIDH",
            //Cell: e => <a href="product/editProduct"> {e.value} </a>,
            sortable: true,
            filterable: true,
            style: {
                textAlign: "right"
            },

            Cell: props => {
                return (
                    sideBarMenuOptions.length !== 0 ?
                        sideBarMenuOptions.Master.map((child, i) => (
                            child.pageName == "Product" ?
                                <div className="text-center">
                                    <Link
                                        className={'mr-3 btn-edit'}
                                        style={{ color: "#000000", textDecoration: "underline" }}
                                        to={`/product/editProduct/?id=${(props && props.original) ? props.original.productId : 0}&read=true`}
                                    >
                                        {props.value}
                                    </Link>
                                </div> : ""
                        )
                        ) : ""

                );
            },

            maxWidth: 110,
            
        }
    
,
        {
            Header: "Name",
            accessor: "name",
            sortable: true,
            filterable: true,
            style: {
                textAlign: "left"
            },
            maxWidth: 370,
        },
        {
            Header: "Technology",
            accessor: "technology",
            sortable: true,
            filterable: true,
            style: {
                textAlign: "left"
            },
            maxWidth: 370
        },
        {
            Header: "Product UOM",
            accessor: "uom",
            sortable: true,
            filterable: true,
            style: {
                textAlign: "left"
            },
            maxWidth: 100
        },
        {
            Header: "Minimum Order Quantity",
            accessor: "productMinOrdQty",
            sortable: true,
            filterable: true,
            style: {
                textAlign: "right"
            },
            maxWidth: 160
        },
        {
            Header: "Action",
            sortable: false,
            filterable: false,
            maxWidth: 170,
            Cell: props => {
                return (
                    sideBarMenuOptions.length !== 0 ?
                        sideBarMenuOptions.Master.map((child, i) => (
                            //console.log("rootSubmenuKeys" + rootSubmenuKeys);
                            child.pageName == "Product" ?
                                <div className="text-center">
                                    {child.rpEdit == 1 ?
                                        <Link
                                            className={'mr-3 btn-edit'}
                                            style={{ color: "#000000" }}
                                            to={`/product/editProduct/?id=${(props && props.original) ? props.original.productId : 0}&read=false`}
                                        >
                                            <span className="glyphicon glyphicon-edit"></span>
                                        </Link>
                                        :
                                        <Link
                                            className={'mr-3 btn-edit'}
                                            style={{ color: "#000000" }}                                            
                                        >
                                            <span className="glyphicon glyphicon-edit disabled"></span>
                                        </Link>
                                    }

                                    {child.rpDelete == 1 ?
                                        <Link
                                            className="btn-delete"
                                            style={{ color: "#000000" }}
                                            onClick={() => onDelete(props.original.productId)}
                                            to="#">
                                            <span className="glyphicon glyphicon-trash"></span>
                                        </Link>
                                        :
                                        <Link
                                            className="btn-delete"
                                            style={{ color: "#000000" }}
                                            to="#">
                                            <span className="glyphicon glyphicon-trash disabled"></span>
                                        </Link>
                                    }
                                </div> : ""
                        )
                        ) : ""
                );

                //};

                //if (rootSubmenuKeys != null && rootSubmenuKeys == 0) {

                //return (
                //    <div className="text-center">

                //        <Link
                //            className="btn-delete"
                //            style={{ color: "#000000" }}
                //            onClick={() => onDelete(props.original.distId)}
                //            to="#">
                //            <span className="glyphicon glyphicon-trash"></span>
                //        </Link>
                //    </div>
                //)
                //};

            }
            //Cell: props => {

            //    return (
            //        <div className="text-center">
            //            <Link
            //                className={'mr-3 btn-edit'}
            //                style={{ color: "#000000" }}
            //                to={`/product/editProduct/?id=${(props && props.original) ? props.original.productId : 0}`}
            //            >
            //                <span className="glyphicon glyphicon-edit"></span>
            //            </Link>
            //            <Link
            //                className="btn-delete"
            //                style={{ color: "#000000" }}
            //                onClick={() => onDelete(props.original.productId)}
            //                to="#">
            //                <span className="glyphicon glyphicon-trash"></span>
            //            </Link>
            //        </div>
            //    );
            //}
        }

        

    ];

    
};


