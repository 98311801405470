import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getValue } from "../../../../common/helpers/validations";
import ReactTable from "react-table";
import { Link } from 'react-router-dom'
import { BASE_URL } from "../../../../common/helpers/urlsConstants";
import { downloadFile } from "../../../../common/helpers/fileDownload";
import { findProductDRPList } from "./api";
import { tableColumHeaders } from "./tableConstants";
import { DisappearedLoading } from 'react-loadingg';
import ProductDRPUpload from "./productDRPUpload";



let ProductDRP = props => {

    let { userCompanyId } = props;
    const [productDRP, setProductDRPList] = useState([]);
    const [show, setShow] = useState(false);
    const openModal = () => setShow(true);
    const closeModal = () => setShow(false);
    const [loading, setLoading] = useState(true)


    useEffect(() => {
        findProductDRPList(userCompanyId).then(data => {
            setLoading(false);
            setProductDRPList(data[0].content);

        });
    }, []);

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-12 mt-20">
                    <div className="panel panel-default">
                        <div className="panel-body">
                            <div className="panel-title text-left">
                                Product DRP
                                 <button className="btn btn-info btn-upload" onClick={openModal} type="button" >Upload</button>
                                <ProductDRPUpload closeModal={closeModal} show={show} />
                                <button className="btn btn-info btn-download" type="button">
                                    <a onClick={()=>downloadFile(`${BASE_URL}/productdrp/dowloadProductDRPReport?companyId=${props.userCompanyId}`,"Product_drp.xlsx")} style={{
                                        color: "inherit", textDecoration: "none",
                                    }} >Download</a>
                                </button>
                                {props.sideBarMenuOptions.length !== 0 ?
                                    props.sideBarMenuOptions.Master.map((child, i) => (
                                        child.pageName == "Product DP" ?
                                            child.rpAdd == 1 ?
                                                <Link to={`/addProductDRP/?id=`}>
                                                    <button className="btn btn-info btn-add"> New
                                    </button>
                                                </Link>
                                                :
                                                <Link>
                                                    <button className="btn btn-info btn-add disabled"> New
                                    </button>
                                                </Link>
                                            : ""
                                    ))
                                    : ("")
                                }

                            </div>
                            {loading ? <DisappearedLoading color="#5f6973" /> :
                                <ReactTable
                                    data={productDRP || []}
                                    columns={tableColumHeaders(userCompanyId, setProductDRPList, props.sideBarMenuOptions)}
                                    filterable
                                    minRows={0}
                                    defaultPageSize={10}
                                    noDataText={"No Data"}
                                    showPageSizeOptions={true}
									defaultFilterMethod={filterCaseInsensitive}                                    
                                    defaultSorted={[{ id: "pdId", desc: true }]}
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
const mapStateToProps = state => {
    return {
        userCompanyId: getValue(state, ["loginReducer", "userCompanyId"]),
        sideBarMenuOptions: state.permissionsReducer.sideBarOptions
    };
};

function filterCaseInsensitive(filter, row) {
	const id = filter.pivotId || filter.id;	
	return (
		row[id] !== undefined ?
			String(row[id].toLowerCase()).indexOf(filter.value.toLowerCase())>-1
		:
			true
	);
}
export default ProductDRP = connect(mapStateToProps)(ProductDRP);



