import React from "react";
import { BASE_URL } from "../../../common/helpers/urlsConstants";
import moment from 'moment';
import { downloadFile } from "../../../common/helpers/fileDownload";

export const tableColumHeaders = () => {
    return [
        {
            Header: "Batch ID",
            accessor: "batchId",
            sortable: true,
			filterable: true,
            style: {
                textAlign: "right"
            },
            maxWidth: 80
        },
        {
            Header: "Batch Type",
            accessor: "batchType",
            sortable: true,
            filterable: true,
            style: {
                textAlign: "left"
            },
            maxWidth: 300
        },
        {
            Header: "Submit Date",
            accessor: "batchCrDt",
            sortable: true,
            filterable: false,
            style: {
                textAlign: "left"
            },
            maxWidth: 150,
            Cell: props => {
                return (
                    moment(props.original.batchCrDt).format('DD/MM/YYYY HH:mm:ss')
                )
            }
        },
        {
            Header: "Submitted By User",
            accessor: "submittedBy",
            sortable: true,
            filterable: true,
            style: {
                textAlign: "left"
            },
            maxWidth: 140
        },
        {
            Header: "Batch Status",
            accessor: "batchStatus",
            sortable: true,
            filterable: false,
            style: {
                textAlign: "left"
            },
            maxWidth: 130
        },
        {
            Header: "Input File name",
            accessor: "batchInFname",
            sortable: true,
            filterable: true,
            style: {
                textAlign: "left"
            },
            maxWidth: 300
        },
        {
            Header: "Action",
            columns: [
                {
                    Header: "Input File",
                    sortable: false,
                    style: {
                        textAlign: "center"
                    },
                    maxWidth: 150,
                    Cell: props => {
                        if (props.original.inputFilePath !== "" && props.original.inputFilePath !== null) {
                             var string = props.original.inputFilePath.substring(44);
                             let fileName = string.split("/");
                             fileName=fileName[fileName.length-1];
                            return (
                            <div className="text-center">
                                <a onClick={()=>downloadFile(`${BASE_URL}${string}`,fileName)}>
                                    <span className="glyphicon glyphicon-download-alt"></span>
                                </a>
                            </div>
                           );
                        }else{
                            return (
                                <div className="text-center">
                                </div>
                            );
                        }
                }
				},
                {
                    Header: "Error File",
                    sortable: false,
                    style: {
                        textAlign: "center"
                    },
                    maxWidth: 150,
                    Cell: props => {
                        if (props.original.errorFilePath !== "" && props.original.errorFilePath !== null) {
                            var string = props.original.errorFilePath.substring(45);
                            let fileName = string.split("/");
                            fileName=fileName[fileName.length-1];
                            return (
                                <div className="text-center">
                                    <a onClick={()=>downloadFile(`${BASE_URL}${string}`,fileName)}>
                                        <span className="glyphicon glyphicon-download-alt"></span>
                                    </a>
                                </div>
                            );
                        }
                        else {
                            return (
                                <div className="text-center">
                                </div>
                            );
                        }
                    }
                }
            ],
            sortable: false,
            filterable: false
        }
    ];
};
