import { post } from 'axios';
import { BASE_URL } from "../../common/helpers/urlsConstants";



export const getTicketDashboardData = async (userId, supportAdmin, tokenId) => {
    tokenId = sessionStorage.getItem("tokenId")
    var token = tokenId.replace(/['"]+/g, '');
    const authData = {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${token}`
        }
    };
    const url = `${BASE_URL}/support/getTicketDashboardData?distId=${userId}&supportAdmin=${supportAdmin}`;
    return fetch(url, authData).then(response => response.json()).then(resp => {
        if (!resp) {
            return [];
        } else {
            return resp;
        }
    })
};


export const findTicketById = async (ticketId, tokenId) => {
    tokenId = sessionStorage.getItem("tokenId")
    var token = tokenId.replace(/['"]+/g, '');
    const authData = {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${token}`
        }
    };
    const url = `${BASE_URL}/support/getTicketDetailsById?ticketId=${ticketId}`;
    return fetch(url, authData).then(response => response.json()).then(resp => {
        if (!resp) {
            return [];
        } else {
            return [resp];
        }
    })
};


export const saveOrUpdateTicket = async (atTicket, file, tokenId) => {
    tokenId = sessionStorage.getItem("tokenId")
    var token = tokenId.replace(/['"]+/g, '');
    const authData = {
        method: "POST",
        headers: {
            "Authorization": `Bearer ${token}`,
        }
    };
    const url = `${BASE_URL}/support/saveOrUpdateTicket`;
    const formData = new FormData();

    delete atTicket["Description"];
    delete atTicket["files"];

    if (file != undefined && file != null && file != "") {
        for (var i = 0; i < file.length; i++) {
            //files.push(file[i]);
            formData.append("files", file[i]);
            //files.slice(i, 1);
        }
    }
    formData.append("atTicket", JSON.stringify(atTicket));

    return post(url, formData, authData);
};


//export const getTicketDashboardData = async (userId, supportAdmin) => {
//    const response = await fetch(`${BASE_URL}/support/getTicketDashboardData?distId=${userId}&supportAdmin=${supportAdmin}`);
//    const resp = await response.json();
//    //console.log("resp", resp);
//    if (!resp) {
//        return [];
//    } else {
//        return resp;
//    }
//};


//export const findTicketById = async ticketId => {
//    const response = await fetch(`${BASE_URL}/support/getTicketDetailsById?ticketId=${ticketId}`);
//    const resp = await response.json();
//    if (!resp) {
//        return [];
//    } else {
//        return [resp];
//    }
//};


//export const saveOrUpdateTicket = (atTicket, file) => {
//    

//    const url = `${BASE_URL}/support/saveOrUpdateTicket`;
//    const formData = new FormData();

//    delete atTicket["Description"];
//    delete atTicket["files"];

//    if (file != undefined && file != null && file != "") {
//        for (var i = 0; i < file.length; i++) {
//            //files.push(file[i]);
//            formData.append("files", file[i]);
//            //files.slice(i, 1);
//        }
//    }

//    formData.append("atTicket", JSON.stringify(atTicket));
//    return post(url, formData);
//};
