import { GET_MENU_BY_ID, GET_MENU_BY_ID_INITIATED, GET_MENU_BY_ID_FAILED, ADD_SIDEBAR_PERMISSIONS } from "../actions/actionTypes";
import { takeLatest, call, put } from "redux-saga/effects";
import { getMenuByIdAPI } from "../apis/getMenuByIdAPI";



export function* menuSaga() {
    yield takeLatest(GET_MENU_BY_ID, workerSaga);
}

function* workerSaga(action) {
    
    try {
        yield put({ type: GET_MENU_BY_ID_INITIATED });
        const response = yield call(getMenuByIdAPI, action.payload.pageId, action.payload.id);
        const defaultRoute = sessionStorage.getItem("defaultRoute")
        if (response) {
            yield put({ type: ADD_SIDEBAR_PERMISSIONS, payload: response })
            yield call(saveInLocalStorage, action.payload.pageId)
            action.payload.history.push(`/${defaultRoute}`)
        } else {
            yield put({ type: GET_MENU_BY_ID_FAILED, payload: {} })
        }
    }
    catch (error) {
     
    }
}

const saveInLocalStorage = (response) => {
   
    sessionStorage.setItem('moduleId', response);
}

