import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import { DatePicker } from "antd";
import { getValue, requiredField } from "../../../../common/helpers/validations";
import Select from "../../../../common/components/Select";
import { fileUpload } from "./api";
import { findDistributorListAPI } from "../../../../services/api";
import { Link } from "react-router-dom";
import Notification from '../../../../common/notification'
import moment from 'moment';
import { DisappearedLoading } from 'react-loadingg';
import DistributorUpload from "../../master/distributor/distributorUpload";


let UploadSalesData = props => {
    const [distributorList, setDistributorList] = useState(null);
    const [respId, setrespId] = useState(false);
    const [formSubmitting, setformSubmitting] = useState(false);
    const [formSubmitted, setformSubmitted] = useState(false);
    const [checked, setchecked] = useState();
    const [show, setShow] = useState(false);
    const openModal = () => setShow(true);
    const closeModal = () => setShow(false);

    let { userId } = props;
    const { MonthPicker } = DatePicker;

    useEffect(() => {
        findDistributorListAPI(userId).then(data => {
            let newDistArray = data.map(i => {
                let option = { key: i.distId, value: i.distDistName }
                return option
            })
            setDistributorList(newDistArray);
        })
    }, []);

   // const [startMonth, setStartMonth] = useState(new Date());
	
	const [startMonth, setStartMonth] = useState(() => {
	var d = new Date(); 
	d.setMonth(d.getMonth() - 1); 
	return d;
	});

    const handleMonthChange = month => {
        //console.log("date", moment(month).format('MMM-yyyy'));
        setStartMonth(month);
    };

function disabledDate(current) {
  // Can not select days before today and today
  return current && current > moment().endOf('day');
}

    const handleChange = isChecked => {
        if (isChecked.target.checked) {
            setchecked("Y");
        }
        else {
            setchecked("N");
        }
    };
    //console.log("checked", checked);

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-12 mt-20">
                    <div className="panel panel-default">
                        <div className="panel-title text-left">Upload Sales Data
							<DistributorUpload closeModal={closeModal} show={show} />
							<button className="btn btn-info btn-upload" onClick={openModal} type="button" >Template</button>
						</div>
                        <div className="editpanel">
                            <Formik initialValues={{
                                file: null, inputJson: null, distid:""
                            }} onSubmit={values => {
                                let inputJson = JSON.stringify({ "type": "sales", "distid": values.distid, "overwrite": checked, "reportDate": moment(startMonth).format('yyyyMMDD'), "userid": userId });
                                //console.log("inputJson", inputJson);
                                setformSubmitting(true);
                                fileUpload(values.file, inputJson).then(response => {
                                    if (response.data.batchId) {
                                        Notification.success('Uploaded Successful.');
                                        setformSubmitted(true);
                                        setrespId(response.data.batchId);
                                        setformSubmitting(true);
                                    }
                                    else {
                                        setformSubmitting(false);
                                    }
                                }).catch(err => {
                                    setformSubmitting(false);
                                    return err;
                                })
                            }}
                            >

                                {({ handleSubmit, values, setFieldValue,  resetForm }) => (
                                    <form /*className={formSubmitting ? "disabled" : ""} */onSubmit={handleSubmit}>
                                        {formSubmitting ?
                                            <DisappearedLoading color="#5f6973" />
                                            : ("")}
                                        <div className="col-md-12 mt-15">
                                            <div className="col-md-2">
                                                <label htmlFor="distid" style={{ display: "block" }}>
                                                    Distributor Name
                                    </label>
                                            </div>
                                            <div className="col-md-10">
                                                {console.log("value", values)}
                                                <Select
                                                    id="distid"
                                                    value={values.distid}
                                                    options={distributorList}
                                                    onChange={(field, value) => {
                                                        setFieldValue("distid", value);
                                                    }}
                                                    validate={requiredField("Distributor Name",values.distid)}
                                                />

                                            </div>
                                        </div>


                                        <div className="col-md-12 mt-15">
                                            <div className="col-md-2">
                                                <label htmlFor="month" style={{ display: "block" }}>
                                                    Month
                                                </label>
                                            </div>
                                            <div className="col-md-10">
                                                <MonthPicker
                                                    id={"month"}
                                                    onChange={handleMonthChange}
                                                    format="MMM-yyyy"
                                                    showMonthYearPicker={true}
                                                    placeholder="Select Month"
													disabledDate={disabledDate}
													defaultValue={moment().subtract(1, 'months')}
                                                />
                                            </div>
                                        </div>


                                        <div className="col-md-12 mt-15">
                                            <div className="col-md-2">
                                                <label htmlFor="overwrite" style={{ display: "block" }}>
                                                    Overwrite
                                     </label>
                                            </div>
                                            <div className="col-md-10">
                                                <input
                                                    name={"overwrite"}
                                                    type="checkbox"
                                                    id={"overwrite"}
                                                    className={"checkbox"}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>


                                        <div className="col-md-12 mt-15">
                                            <div className="col-md-2">
                                                <label htmlFor="file" style={{ display: "block" }}>
                                                    File
                                    </label>
                                            </div>
                                            <div className="col-md-10">
                                                <input
                                                    id={"file"}
                                                    type="file"
                                                    onChange={(e, value) => {
                                                        setFieldValue("file", e.target.files[0]);
                                                    }}
                                                />
                                            </div>
                                        </div>


                                        <div className="col-md-12 mt-20 mb-20">
                                            <div className="col-md-2">
                                            </div>
                                            <div className="col-md-10">
                                                {props.sideBarMenuOptions.length !== 0 ?
                                                    props.sideBarMenuOptions.Upload.map((child, i) => (
                                                        child.pageName == "Upload Sales Data" ?
                                                            child.rpEdit == 1 ?
                                                                <button type="submit" className={" btn btn-info btn-fileupload " + (formSubmitting && "disabled")}>Upload</button>
                                                                :
                                                                <button type="submit" className={" btn btn-info btn-fileupload disabled " + (formSubmitting && "disabled")}>Upload</button>
                                                            : ""
                                                    ))
                                                    : ("")
                                                }
                                                <button type="button" onClick={()=>resetForm({})} className="btn btn-filecancle">Reset</button>
                                            </div>
                                        </div>

                                    </form>
                                )}
                            </Formik>

                            {respId && (
                                <Link to="/fileUploadStatus">
                                    <div className="alert alert-success cursor" role="alert" style={{ clear: "both" }}>
                                        {'Upload file is submitted. Click here to check the status and copy batch id.'}
                                        <strong id="batchId">{respId}</strong>
                                    </div>
                                </Link>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
const mapStateToProps = state => {
    return {
        userId: getValue(state, ["loginReducer", "userId"]),
          sideBarMenuOptions: state.permissionsReducer.sideBarOptions
    };
};

export default UploadSalesData = connect(mapStateToProps)(UploadSalesData);


