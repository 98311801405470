import { post } from "axios";
import { BASE_URL } from '../../../../common/helpers/urlsConstants';



export const findFreezePeriodList = async (tokenId) => {
    tokenId = sessionStorage.getItem("tokenId")
    var token = tokenId.replace(/['"]+/g, '');
    const authData = {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${token}`
        }
    };
    const url = `${BASE_URL}/FreezePeriod/findFreezePeriodList`;
    return fetch(url, authData).then(response => response.json()).then(resp => {
        if (!resp.content) {
            return [];
        } else {
            return [resp.content];
        }
    })
};


export const saveOrUpdateFreezePeriod = async (data, tokenId) => {
    tokenId = sessionStorage.getItem("tokenId")
    var token = tokenId.replace(/['"]+/g, '');
    debugger;
    const authData = {
        method: "POST",
        headers: {
            "Authorization": `Bearer ${token}`,
            "accept": "*/*",
            "Content-Type": "application/json",
        }
    };

    const url = `${BASE_URL}/FreezePeriod/saveOrUpdateFreezePeriod`;

    return post(url, data, authData);
};
