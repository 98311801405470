import React, { useState,useRef,useEffect } from "react";
import { Modal } from 'react-bootstrap';
import '../../App.css'
import { connect } from "react-redux";
import { getValue } from "../../common/helpers/validations";
import 'flexmonster/theme/orange/flexmonster.min.css';
import * as FlexmonsterReact from 'react-flexmonster/hooks';
import { DatePicker } from 'antd';
import moment from 'moment';
import { Chart_URL, License_key } from "../../common/helpers/urlsConstants";

var flex1 = null;
var flex2 = null;
var flex3 = null;
var flex4 = null;


let Dashboard2 = (props) => {

    const ref1 = useRef();
    const ref2 = useRef();
    const ref3 = useRef();
    const ref4 = useRef();

    let { userId,userType } = props;
    const { MonthPicker } = DatePicker;
    let inputJson = encodeURI(JSON.stringify({ "userId": userId, "reportDate": moment().subtract(1, 'months').format('yyyyMMDD'), "userType": userType }));

    var tokenId = sessionStorage.getItem("tokenId")
    var token = tokenId.replace(/['"]+/g, '');
	
	useEffect(()=>{
        flex1 = ref1.current.flexmonster();
        flex2 = ref2.current.flexmonster(); 
        flex3 = ref3.current.flexmonster();
        flex4 = ref4.current.flexmonster();    
    },[])

    const onReportComplete = () => {
        if (flex1 === null) {
            //flex1 = ref1.current.flexmonster();
        }
        if (flex2 === null) {
            //flex2 = ref2.current.flexmonster();
        }
        if (flex3 === null) {
            //flex3 = ref3.current.flexmonster();
        }
        if (flex4 === null) {
            //flex4 = ref4.current.flexmonster();
        }
    }

var SSD = {
        dataSource: {
            dataSourceType: "json",
            filename: Chart_URL + "?chName=SSD&chScreen=dashboard2&chartInputJson=" + inputJson,
            requestHeader: {
                "Authorization": `Bearer ${token}`
            }
        },

        "slice": {
				 "rows": [
            {
                "uniqueName": "cust_add3"
            },
            {
                "uniqueName": "cust_add2"
            },
            {
                "uniqueName": "cust_add1"
            },
            {
                "uniqueName": "sd_product_name"
            },
            {
                "uniqueName": "cust_area"
            },
            {
                "uniqueName": "sd_cust_code"
            },
            {
                "uniqueName": "cust_city"
            },
            {
                "uniqueName": "cy_1"
            },
            {
                "uniqueName": "cy_1_qty"
            },
            {
                "uniqueName": "cy_2"
            },
            {
                "uniqueName": "cy_2_qty"
            },
            {
                "uniqueName": "decval"
            },
            {
                "uniqueName": "decqty"
            },
            {
                "uniqueName": "dp"
            },
            {
                "uniqueName": "apr"
            },
            {
                "uniqueName": "aprqty"
            },
            {
                "uniqueName": "aug"
            },
            {
                "uniqueName": "augqty"
            },
            {
                "uniqueName": "cust_pincode"
            },
            {
                "uniqueName": "deviation"
            },
			{
                "uniqueName": "distidh"
            },
            {
                "uniqueName": "distname"
            },
            {
                "uniqueName": "distributor_engineer"
            },
            {
                "uniqueName": "end_customer_name"
            },
            {
                "uniqueName": "feb"
            },
            {
                "uniqueName": "febqty"
            },
            {
                "uniqueName": "henkel_engineer"
            },
            {
                "uniqueName": "jan"
            },
            {
                "uniqueName": "janqty"
            },
            {
                "uniqueName": "jul"
            },
            {
                "uniqueName": "julqty"
            },
            {
                "uniqueName": "jun"
            },
            {
                "uniqueName": "junqty"
            },
            {
                "uniqueName": "manager"
            },
            {
                "uniqueName": "mar"
            },
            {
                "uniqueName": "marqty"
            },
            {
                "uniqueName": "may"
            },
            {
                "uniqueName": "mayqty"
            },
            {
                "uniqueName": "nov"
            },
            {
                "uniqueName": "novqty"
            },
            {
                "uniqueName": "octqty"
            },
            {
                "uniqueName": "octval"
            },
            {
                "uniqueName": "price"
            },
            {
                "uniqueName": "sd_product_idh"
            },
            {
                "uniqueName": "segment"
            },
            {
                "uniqueName": "mktsegment"
            },
            {
                "uniqueName": "mktsubsegment"
            },
            {
                "uniqueName": "sep"
            },
            {
                "uniqueName": "sepqty"
            },
            {
                "uniqueName": "state"
            },
            {
                "uniqueName": "su"
            },
            {
                "uniqueName": "subsegment"
            },
            {
                "uniqueName": "ytd_qty"
            }
        ],
				"flatOrder": [
							"manager",
							"henkel_engineer",
							"distidh",
							"distname",
							"end_customer_name",
							"sd_cust_code",
							"distributor_engineer",
							"su",
							"sd_product_idh",
							"sd_product_name",
							"deviation",
							"dp",
							"janqty",
							"jan",
							"febqty",
							"feb",
							"marqty",
							"mar",
							"aprqty",
							"apr",
							"mayqty",
							"may",
							"junqty",
							"jun",
							"julqty",
							"jul",
							"augqty",
							"aug",
							"sepqty",
							"sep",
							"octqty",
							"octval",
							"novqty",
							"nov",
							"decqty",
							"decval",
							"ytd_qty",
							"price",
							"cy_1_qty",
							"cy_1",
							"cy_2_qty",
							"cy_2",
							"cust_add1",
							"cust_add2",
							"cust_add3",
							"cust_area",
							"cust_city",
							"state",
							"cust_pincode",
							"segment",
							"subsegment",
                            "mktsegment",
                            "mktsubsegment"
							]
        },
        "options": {
           "grid": {
            "type": "flat",
            "showTotals": "off",
            "showGrandTotals": "off"
        },
        "configuratorButton": false,
        "showAggregationLabels": false
			
        }
    }
	
    var salesColChartReport = {
        dataSource: {
            dataSourceType: "json",
            "data":
            {
                "distname": {
                    "type": "level",
                    "hierarchy": "distname",
                    "level": "Dist Name",
                    "caption": "Dist Name"
                },
                "engg": {
                    "type": "level",
                    "hierarchy": "distname",
                    "parent": "Dist Name",
                    "level": "engg",
                    "caption": "Engineer Name"
                },
                "abp": {
                    "type": "number"
                },
                "ytdach": {
                    "type": "number",
                    "caption": "YTD Ach"
                },
                "ytdssd": {
                    "type": "number",
                    "caption": "YTD SSD"
                },
                "baltogo": {
                    "type": "number",
                    "caption": "Bal To Go"
                },
                "prevmthnes": {
                    "type": "number",
                    "caption": "Prev Mth NES"
                },
                "prevmthssd": {
                    "type": "number",
                    "caption": "Prev Mth SSD"
                },
                "stock": {
                    "type": "number"
                },
                "stockdays": {
                    "type": "number",
                    "caption": "Stock Days"
                }
            },
            filename: Chart_URL + "?chName=SalesGrid&chScreen=dashboard2&chartInputJson=" + inputJson,
            requestHeader: {
                "Authorization": `Bearer ${token}`
            }
        },

        "slice": {
            "rows": [
                {
                    "uniqueName": "distname"
                },
                {
                    "uniqueName": "engg"
                }
            ],
            "columns": [
                {
                    "uniqueName": "[Measures]"
                }
            ],
            "measures": [
                {
                    "uniqueName": "abp",
                    "aggregation": "sum",
                    "grandTotalCaption": "ABP"
                },
                {
                    "uniqueName": "ytdach",
                    "aggregation": "sum",
                    "caption": "YTD Ach",
                    "grandTotalCaption": "YTD Ach"
                },
                {
                    "uniqueName": "ytdssd",
                    "aggregation": "sum",
                    "caption": "YTD SSD",
                    "grandTotalCaption": "YTD SSD"
                },
                {
                    "uniqueName": "baltogo",
                    "aggregation": "sum",
                    "caption": "Bal To Go",
                    "grandTotalCaption": "Bal To Go"
                },
                {
                    "uniqueName": "prevmthnes",
                    "aggregation": "sum",
                    "caption": "Prev Mth NES",
                    "grandTotalCaption": "Prev Mth NES"
                },
                {
                    "uniqueName": "prevmthssd",
                    "aggregation": "sum",
                    "caption": "Prev Mth SSD",
                    "grandTotalCaption": "Prev Mth SSD"
                },
                {
                    "uniqueName": "stock",
                    "aggregation": "sum",
                    "grandTotalCaption": "Stock"
                },
                {
                    "uniqueName": "stockdays",
                    "aggregation": "average",
                    "caption": "Stock Days",
                    "grandTotalCaption": "Stock Days",
                    "format": "-d6xv7kv923b00"
                }
            ]
        },
        "options": {
            grid: {
                showHeaders: false,
            },
            drillThrough: false,
            configuratorActive: false,
            configuratorButton: false,
            sorting: "off",
            "datePattern": "dd-MM-yyyy",
            showAggregationLabels: false			
        },
        "formats": [
            {
                "name": "-d6xv7kv923b00",
                "thousandsSeparator": " ",
                "decimalSeparator": ".",
                "decimalPlaces": 0,
                "currencySymbol": "",
                "currencySymbolAlign": "left",
                "nullValue": "",
                "textAlign": "right",
                "isPercent": false
            }
        ]
    }


    var newCustColChartReport = {
        dataSource: {
            dataSourceType: "json",
            filename: Chart_URL + "?chName=CustGrid&chScreen=dashboard2&chartInputJson=" + inputJson,
            requestHeader: {
                "Authorization": `Bearer ${token}`
            }
        },

        "slice": {
            "rows": [
                {
                    "uniqueName": "distname"
                },
                {
                    "uniqueName": "custname"
                }
            ],
            "columns": [
                {
                    "uniqueName": "[Measures]"
                }
            ],
            "measures": [
                {
                    "uniqueName": "total_cust",
                    "aggregation": "sum"
                },
                {
                    "uniqueName": "ytd_new",
                    "aggregation": "sum"
                },
                {
                    "uniqueName": "ytd_ssd_new",
                    "aggregation": "sum"
                },
                {
                    "uniqueName": "prev_mth_new_sales",
                    "aggregation": "sum"
                },
                {
                    "uniqueName": "prev_mth_new",
                    "aggregation": "sum"
                },
                {
                    "uniqueName": "last_2_mths_no_sale",
                    "aggregation": "sum"
                },
                {
                    "uniqueName": "ytd_no_sales",
                    "aggregation": "sum"
                }
            ]
        },
        options: {
            grid: {
                showHeaders: false,
            },
            drillThrough: false,
            configuratorActive: false,
            configuratorButton: false,
            sorting: "off",
            datePattern: "yyyy-MM-dd",
            showAggregationLabels: false
        },
        tableSizes: {
            columns: [
                {
                    idx: 0,
                    width: 300
                },
                {
                    tuple: [],
                    measure: {
                        uniqueName: "total_cust",
                        aggregation: "sum"
                    },
                    width: 80
                },
                {
                    tuple: [],
                    measure: {
                        uniqueName: "ytd_new",
                        aggregation: "sum"
                    },
                    width: 80
                },
                {
                    tuple: [],
                    measure: {
                        uniqueName: "ytd_ssd_new",
                        aggregation: "sum"
                    },
                    width: 80
                },
                {
                    tuple: [],
                    measure: {
                        uniqueName: "prev_mth_new",
                        aggregation: "sum"
                    },
                    width: 80
                },
                {
                    tuple: [],
                    measure: {
                        uniqueName: "prev_mth_new_sales",
                        aggregation: "sum"
                    },
                    width: 80
                },
                {
                    tuple: [],
                    measure: {
                        uniqueName: "last_2_mths_no_sale",
                        aggregation: "sum"
                    },
                    width: 81
                },
                {
                    tuple: [],
                    measure: {
                        uniqueName: "ytd_no_sales",
                        aggregation: "sum"
                    },
                    width: 85
                }
            ]
        }


    }


    var complianceReceiptDataReport = {
        dataSource: {
            dataSourceType: "json",
            filename: Chart_URL + "?chName=ComplianceReceiptData&chScreen=dashboard1&chartInputJson=" + inputJson,
            requestHeader: {
                "Authorization": `Bearer ${token}`
            }
        },

    "slice": {
            "rows": [
                {
                    "uniqueName": "distname",
                    "caption": "Distributor"
                }
            ],
            "columns": [
                {
                    "uniqueName": "[Measures]"
                },
                {
                    "uniqueName": "mnth",
                    "caption": "SSD Month"
                }
            ],
            "measures": [
                {
                    "uniqueName": "days",
                    "aggregation": "avg"
                }
            ]
        },
        "conditions": [
            {
                "formula": "#value = 0",
                "format": {
                    "backgroundColor": "#EF5350",
                    "color": "#000000",
                    "fontFamily": "Arial",
                    "fontSize": "12px"
                }
            },
            {
                "formula": "#value = 1",
                "format": {
                    "backgroundColor": "#8cb045",
                    "color": "#000000",
                    "fontFamily": "Arial",
                    "fontSize": "12px"
                }
            },
            {
                "formula": "#value = 2",
                "format": {
                    "backgroundColor": "#FFFF00",
                    "color": "#000000",
                    "fontFamily": "Arial",
                    "fontSize": "12px"
                }
            }
        ],
        "tableSizes": {
            "columns": [
                { "idx": 0, "width": 300 },
                { "idx": 1, "width": 60 },
                { "idx": 2, "width": 60 },
                { "idx": 3, "width": 60 },
                { "idx": 4, "width": 60 },
                { "idx": 5, "width": 60 },
                { "idx": 6, "width": 60 },
                { "idx": 7, "width": 60 },
                { "idx": 8, "width": 60 },
                { "idx": 9, "width": 60 },
                { "idx": 10, "width": 60 },
                { "idx": 11, "width": 60 },
                { "idx": 12, "width": 60 }
            ]
        },
        "options": {
            "configuratorButton": false,
			"drillThrough": false,
            "grid": {
                "showTotals": "off",
                "showGrandTotals": "off",
                "showHeaders": false
            }
        }


    }



    var topProdColChartReport = {
        dataSource: {
            dataSourceType: "json",
            filename: Chart_URL + "?chName=TopProdColChart&chScreen=dashboard1&chartInputJson=" + inputJson,
            requestHeader: {
                "Authorization": `Bearer ${token}`
            }
        },

        "slice": {
            "reportFilters": [
                {
                    "uniqueName": "invmonth",
                    "filter": {
                        "members": [
                            "invmonth.[jan]"
                        ]
                    }

                },
                {
                    "uniqueName": "enngname"
                },
                {
                    "uniqueName": "distname"
                }
            ],
            "rows": [
                {
                    "uniqueName": "prodname",
                    "filter": {
                        "measure": {
                            "uniqueName": "amount",
                            "aggregation": "sum"
                        },
                        "query": {
                            "top": 10
                        }
                    }
                }

            ],
            "columns": [
                {
                    "uniqueName": "[Measures]"
                },
                {
                    "uniqueName": "invmonth"
                }
            ],
            "measures": [
                {
                    "uniqueName": "amount",
                    "aggregation": "sum"
                }
            ],
            "sorting": {
                "column": {
                    "type": "desc",
                    "tuple": [],
                    "measure": {
                        "uniqueName": "amount",
                        "aggregation": "sum"
                    }
                }
            }
        },
        "options": {
            "viewType": "charts",
            configuratorButton: false,
            showFilter: false,
            showHeaders: false,
            showHierarchies: false,
            showHierarchyCaptions: false,
            showReportFiltersArea: false,
            "chart":
            {
                "showLegend": true,
                "showDataLabels": true,
                "showMeasures": false
            }
        }

    }


function customizeToolbar(toolbar) { 
    // get all tabs 
    var tabs = toolbar.getTabs(); 
    toolbar.getTabs = function () { 
        // delete the first tab 
        delete tabs[0]; 
		delete tabs[1];
		delete tabs[2];
		delete tabs[3];
		delete tabs[4];
		delete tabs[5];
		delete tabs[6];
		delete tabs[7];
		delete tabs[8];
		delete tabs[9];
		delete tabs[10];
		tabs.push({
        title: "To Excel",
        id: "fm-tab-export-excel",
        mobile: false,
        icon: this.icons.export_excel,
		handler: this.exportHandler,
		args: "excel"     	  
    });
	tabs.push({
        title: "Close",
        id: "fm-tab-close",
        mobile: false,
        icon: '<img src="https://www.flexmonster.com/flexmonster/assets/img/icons/close-n-cancel-button_large@2x.png">',
		rightGroup: true,
		handler: ()=>clsSSDReport(false)
    });		
        return tabs; 
    } 
} 

function clsSSDReport(isClose){	
	setSSDReport(isClose);
}

    const [SSDReport, setSSDReport] = useState(false);
    const openSSDReport = () => setSSDReport(true);
    const closeSSDReport = () => setSSDReport(false);

    const [salesColChart, setSalesColChart] = useState(false);
    const openSalesColChart = () => setSalesColChart(true);
    const closeSalesColChart = () => setSalesColChart(false);

    const [newCustColChart, setNewCustColChart] = useState(false);
    const openNewCustColChart = () => setNewCustColChart(true);
    const closeNewCustColChart = () => setNewCustColChart(false);

    const [topProdColChart, setTopProdColChart] = useState(false);
    const openTopProdColChart = () => setTopProdColChart(true);
    const closeTopProdColChart = () => setTopProdColChart(false);

    const [complianceReceiptData, setComplianceReceiptData] = useState(false);
    const openComplianceReceiptData = () => setComplianceReceiptData(true);
    const closeComplianceReceiptData = () => setComplianceReceiptData(false);


    const handleDateChange = date => {
        let date1 = moment(date).format('yyyyMMDD');
        //console.log("date:", date1);
        inputJson = encodeURI(JSON.stringify({ "userId": userId, "reportDate": date1, "userType": userType }));

        let filename1 = Chart_URL + "?chName=SalesGrid&chScreen=dashboard2&chartInputJson=" + inputJson;
        salesColChartReport.dataSource.filename = filename1;
        flex1.setReport(salesColChartReport)

        let filename2 = Chart_URL + "?chName=CustGrid&chScreen=dashboard2&chartInputJson=" + inputJson;
        newCustColChartReport.dataSource.filename = filename2;
        flex2.setReport(newCustColChartReport)

        let filename3 = Chart_URL + "?chName=ComplianceReceiptData&chScreen=dashboard1&chartInputJson=" + inputJson;
        complianceReceiptDataReport.dataSource.filename = filename3;
        flex3.setReport(complianceReceiptDataReport)

        let filename4 = Chart_URL + "?chName=TopProdColChart&chScreen=dashboard1&chartInputJson=" + inputJson;
        topProdColChartReport.dataSource.filename = filename4;
        flex4.setReport(topProdColChartReport)


    };

    return (
        <div className="container-fluid">
			<div className="text-left" style={{ padding: '10px 0px', display: 'flex' }}>
                <div className="col-xs-12 col-md-6">
                    <button className="btn btn-info chart-btns" type="button" onClick={openSSDReport}>
                        SSD
                </button>
                    <Modal show={SSDReport} onHide={closeSSDReport}>
                        <Modal.Header>
                            <button className="btn btn-info" type="button" style={{ marginTop: '8px', float: 'Right', backgroundColor: '#e1000f' }} onClick={closeSSDReport}>Close</button>
                        </Modal.Header>
                        <Modal.Body>
                            <FlexmonsterReact.Pivot toolbar={true}
                                width="100%"
                                report={SSD}
								beforetoolbarcreated={customizeToolbar}
                                licenseKey={License_key}
                            />
                        </Modal.Body>
                    </Modal>
                    <button className="btn btn-info chart-btns" type="button" onClick={openTopProdColChart}>
                        Stock Report
                    </button>
                    <button className="btn btn-info chart-btns" type="button" onClick={openTopProdColChart}>
                        Product Performance
                    </button>
                </div>
                <div className="col-xs-12 col-md-4">
                    <div className="text-right" style={{ padding: '9px 0px 0px 0px' }}>
                        <span style={{ fontSize: '16px' }}>Select month to view SSD up to that period</span>

                    </div>

                </div>
                <div className="col-xs-12 col-md-2">
                    <div className="text-left">
                        <MonthPicker
                            onChange={handleDateChange}
                            format="MMM-yyyy"
                            showMonthYearPicker={true}
                            placeholder="Select Month"
                            defaultValue={moment().subtract(1, 'months')}
                        />
                    </div>
                </div>
            </div>
            <div className="row" style={{ margin: '20px 0px' }}>

                <div className="row">
                    <div className="col-xs-12 col-md-12">
                        <div className="text-left">
                            <span style={{ fontSize: '16px', fontWeight: '600' }}>Sales Analysis</span>
                        </div>
                        <div style={{ padding: '5px 0px' }}>
                            <FlexmonsterReact.Pivot
                                width="100%"
                                height="300px"
                                ref={ref1}
                                report={salesColChartReport} celldoubleclick={openSalesColChart} chartclick={openSalesColChart}
                                reportcomplete={onReportComplete}
                                licenseKey={License_key}
                            />
                            <Modal show={salesColChart} onHide={closeSalesColChart}>
                                <FlexmonsterReact.Pivot toolbar={true}
                                    width="100%"
                                    report={salesColChartReport}
                                    licenseKey={License_key}
                                />
                            </Modal>
                        </div>
                    </div>
                </div>


                <div className="row mt-3">
                    <div className="col-xs-12 col-md-12">
                        <div className="text-left">
                            <span style={{ fontSize: '16px', fontWeight: '600' }}>New Customers</span>
                        </div>
                        <div style={{ padding: '5px 0px' }}>
                            <FlexmonsterReact.Pivot toolbar={false}
                                width="100%"
                                height="300px"
                                ref={ref2}
                                report={newCustColChartReport} celldoubleclick={openNewCustColChart} chartclick={openNewCustColChart}
                                licenseKey={License_key}
                                reportcomplete={onReportComplete}
                            />

                            <Modal show={newCustColChart} onHide={closeNewCustColChart}>
                                <FlexmonsterReact.Pivot toolbar={true}
                                    width="100%"
                                    report={newCustColChartReport}
                                    licenseKey={License_key} />
                            </Modal>

                        </div>
                    </div>

                </div>


                <div className="row mt-3">
                    <div className="col-xs-12 col-md-12">
                        <div className="text-left">
                            <span style={{ fontSize: '16px', fontWeight: '600' }}> Compliance-Reciept Data</span>
							<span style={{ fontSize: '10px' }}>   ( Green - uploaded before 7th of next month, </span>
							<span style={{ fontSize: '10px' }}>Yellow – uploaded after 7th of next month, </span>
							<span style={{ fontSize: '10px' }}>Red - not uploaded )</span>
                        </div>
                        <div style={{ padding: '5px 0px' }}>
                            <FlexmonsterReact.Pivot toolbar={false}
                                width="100%"
                                height="350px"
                                ref={ref3}
                                report={complianceReceiptDataReport} celldoubleclick={openComplianceReceiptData} chartclick={openComplianceReceiptData}
								customizeCell={customizeCellFunction}
								drillThrough={false}
                                licenseKey={License_key}
                                reportcomplete={onReportComplete}
                            />

                            <Modal show={complianceReceiptData} onHide={closeComplianceReceiptData}>
                                <FlexmonsterReact.Pivot toolbar={true}
                                    width="100%"
                                    report={complianceReceiptDataReport}
									customizeCell={customizeCellFunction}
									drillThrough={false}
                                    licenseKey={License_key} />
                            </Modal>

                        </div>
                    </div>                    
                </div>
				<div className="row mt-3">
					<div className="col-xs-12 col-md-12">
							<div>
								<span style={{ fontSize: '16px', fontWeight: '600' }}>Top Product Performance</span>
							</div>
							<div style={{ padding: '5px 0px' }}>
								<FlexmonsterReact.Pivot toolbar={false}
									width="100%"
									height="350px"
									ref={ref4}
									report={topProdColChartReport} celldoubleclick={openTopProdColChart} chartclick={openTopProdColChart}
									licenseKey={License_key}
									reportcomplete={onReportComplete}
								/>

								<Modal show={topProdColChart} onHide={closeTopProdColChart}>
									<FlexmonsterReact.Pivot toolbar={true}
										width="100%"
										report={topProdColChartReport} licenseKey={License_key}
									/>
								</Modal>
							</div>
						</div>
					</div>

            </div>
        </div>
    );
}
const mapStateToProps = state => {
    return {
        userId: getValue(state, ["loginReducer", "userId"]),
        userType: getValue(state, ["loginReducer", "userType"])

    };
};

function customizeCellFunction(cell, data) {
  if (data.type == "value" && !data.isDrillThrough) {
	  cell.text = ""
  }
}


export default Dashboard2 = connect(mapStateToProps)(Dashboard2);