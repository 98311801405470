import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import schema from "./editSuMappingDataViewFields.json"
import { Field, withFormik } from "formik";
import { InputBox } from "../../../../common/components/InputBox";
import Select from "../../../../common/components/Select";
import { required, requiredField } from "../../../../common/helpers/validations";
import { findSmSuMappingById, getCustomerList, findProductList,saveOrUpdateProductDrp } from "./api.js";
import "../../../../css/style.css";
import { getValue } from "../../../../common/helpers/validations";
import { getCompany, getEngineer, getDepartment } from "../../../../services/staticdata";
import { DisappearedLoading } from 'react-loadingg';
import { connect } from "react-redux";
import { findDistributorListAPI } from "../../../../services/api";
import moment from 'moment';
import { DatePicker } from "antd";
import Notification from '../../../../common/notification'


const formikEnhancer = withFormik({
    mapPropsToValues: ({ user, props }) => ({
        ...user,
        ...props
    }),
    handleSubmit: (payload, { setSubmitting, props }) => {
        setSubmitting(true);
        saveOrUpdateProductDrp(payload).then(resp => {
           // console.log("savedData", resp);
            if (resp.status === 200) {
                Notification.success('Saved Successful.');
                props.history.push('/Cust-Egg-SU-mapping');
            }
            else {
                Notification.error('Error while saving data:' + resp.data.status_response);
            }
            setSubmitting(false);
        })
    },
    displayName: "MyForm"
});


let EditSuMappingView = (props) => {
    let { userCompanyId, userId } = props;
    const { handleSubmit, setFieldValue, setValues, values, isSubmitting } = props;
    const [company, setCompany] = useState([]);
    const [department, setDepartment] = useState([]);
    const [distributorList, setDistributorList] = useState(null);
    const [customerList, setCustomerList] = useState(null);
    const [productList, setproductList] = useState(null);
    const [engineer, setEngineer] = useState([]);
    const history = useHistory()

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    const handleCancle = () => {
        history.goBack()
    }

    async function loadStaticData() {
        setCompany(await getCompany());
        setEngineer(await getEngineer());
        setDepartment(await getDepartment());
    }

    let filteredCompany = company.filter(function (e) {
        return e.key == userCompanyId;
    });

    let filteredEngineer = engineer.filter(function (e) {
        return e.key == userCompanyId;
    });

    let filteredDepartment = department.filter(function (e) {
        return e.parent.match(userCompanyId);
    });

    const suMappingId = useQuery().get("id");

    const selectOnChange = (field, value) => {
        setFieldValue(field, value);
        getcustomers(value);
    };

    function getcustomers(id) {
        if (id) {
            getCustomerList(id).then(data => {
                let newCustomerArray = data.map(i => {
                    let option = { key: i.custid, value: i.custname + " " + i.custcode }
                    return option
                })
                setCustomerList(newCustomerArray);
            })
        }
    }

    useEffect(() => {
        loadStaticData();
        if (suMappingId) {
            findSmSuMappingById(suMappingId).then(result => {
                setValues(result[0]);
                getcustomers(result[0].sumDistId);
            });
        }
        else {
            setValues(initData);
        }

        findDistributorListAPI(userId).then(data => {
            let newDistArray = data.map(i => {
                let option = { key: i.distId, value: i.distIdhCode + " " + i.distDistName }
                return option
            })
            setDistributorList(newDistArray);
        })

        findProductList(userCompanyId).then(data => {
            let newProductArray = data[0].content.map(i => {
                let option = { key: i.productId, value: i.productIDH + " " + i.name }
                return option
            })
            setproductList(newProductArray);
        })
    }, []);

    const initData = {
        sumCompanyid: 0,
        sumCrDt: "",
        sumCrUid: userId,
        sumCustId: 0,
        sumDistId: 0,
        sumEnggId: 0,
        sumFromDt: "",
        sumProductId: 0,
        sumSuId: 0,
        sumToDt: "",
        sumUpDt: "",
        sumUpUid: 0
    }

    return (
        <div className="container-fluid" >
            <div className="row">
                <div className="col-md-12 mt-20">
                    <div className="panel panel-default">
                        <div className="panel-body">
                            <div className="panel-title text-left">SU Mapping
                                <button
                                    className="btn btn-cancle"
                                    style={{ float: "right" }}
                                    type="button btn"
                                    onClick={handleCancle}
                                >
                                    {"Cancel"}
                                </button>
                                <button
                                    className={isSubmitting ? " btn btn-success btn-save disabled" : "btn btn-save btn-success"}
                                    style={{ float: "right" }}
                                    type="submit"
                                    onClick={handleSubmit}
                                >
                                    {"Save"}
                                </button>
                            </div>
                            <div className="editpanel">

                                <form onSubmit={handleSubmit}/* className={isSubmitting ? "disabled" : ""}*/>
                                    {isSubmitting ?
                                        <DisappearedLoading color="#5f6973" />
                                        : ("")}
                                    {schema.map((item, index) => {
                                        return (
                                            <div key={index} >
                                                {item.ObjectType === "Textbox" ? (
                                                    <div className={item.Editable === "No" ? "col-md-12 display-flex disabled" : "col-md-12 display-flex"} >
                                                        <label className="form_label w-30">
                                                            {item.FieldName} {item.Mandatory && <span className="error">* </span>}
                                                        </label>

                                                        <div className="form_input w-70">
                                                            <DatePicker
                                                                id={item.backendMappingName}
                                                                placeholder="Select Date"
                                                                value={values[item.backendMappingName] ? moment(values[item.backendMappingName]) : moment()}
                                                                onChange={
                                                                    item.backendMappingName === "sumFromDt" ? ((field, value) => {
                                                                        setFieldValue("sumFromDt", value);
                                                                    }) : ((field, value) => {
                                                                        setFieldValue("sumToDt", value);
                                                                    })}
                                                                validate={item.Mandatory && required.bind(this, item.FieldName)}
                                                            />
                                                        </div>
                                                    </div>
                                                ) : (
                                                        <div className="col-md-12  display-flex">
                                                            <label
                                                                className="form_label w-30"
                                                                htmlFor={item.backendMappingName}
                                                                style={{ display: "block" }}>
                                                                {item.FieldName} {item.Mandatory && <span className="error">* </span>}
                                                            </label>
                                                            <div className=" form_input w-70">
                                                                <Select
                                                                    id={item.backendMappingName}
                                                                    name={item.backendMappingName}
                                                                    value={values[item.backendMappingName] || ''}
                                                                    options={eval(item.Source)}
                                                                    onChange={item.backendMappingName === "sumDistId" ? (field, value) => selectOnChange(field, value)
                                                                        : ((field, value) => {
                                                                            setFieldValue(field, value);
                                                                        })}
                                                                    validate={item.Mandatory && requiredField(values[item.backendMappingName])}
                                                                />
                                                            </div>
                                                        </div>
                                                    )}
                                            </div>
                                        );
                                    })}
                                </form>

                                <div className="col-md-12 mt-20 row display-flex">
                                    <button
                                        className={isSubmitting ? " btn btn-success btn-save disabled" : "btn btn-save btn-success"}
                                        type="submit"
                                        onClick={handleSubmit} >
                                        {"Save"}
                                    </button>
                                    <button
                                        className="btn btn-cancle"
                                        type="button btn"
                                        onClick={handleCancle}
                                    >
                                        {"Cancel"}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

const mapStateToProps = state => {
    return {
        userId: getValue(state, ["loginReducer", "userId"]),
        userCompanyId: getValue(state, ["loginReducer", "userCompanyId"])
    };
};

const EditSuMappingViews = connect(mapStateToProps)(formikEnhancer(EditSuMappingView))

export default EditSuMappingViews;

