import {
  ADD_DASHBOARD_PERMISSIONS,
  ADD_SIDEBAR_PERMISSIONS,
  REHYDRATE_SIDEBAR_PERMISSIONS,
  REHYDRATE_DASHBOARD_PERMISSIONS
} from "../actions/actionTypes";
const mockdata = [
  {
    moduleName: "SSA",
    moduleId: 1
  },
  {
    moduleName: "DMT",
    moduleId: 2
  },
  {
    moduleName: "Market Data",
    moduleId: 3
  },
  {
    moduleName: "Admin",
    moduleId: 4
  }
];
const sideBarOptionsMockData = {
  Master: [
    {
      usrLoginid: null,
      usrId: null,
      usrFirstName: null,
      usrLastName: null,
      pageName: "Distributor",
      pageParentId: 5,
      pageId: 11,
      pageDesc: "distributor",
      rpAdd: 1,
      rpEdit: 1,
      rpDelete: 1,
      rpView: 1
    },
    {
      usrLoginid: null,
      usrId: null,
      usrFirstName: null,
      usrLastName: null,
      pageName: "Distributor's Customer",
      pageParentId: 5,
      pageId: 12,
      pageDesc: "distributorscustomer",
      rpAdd: 1,
      rpEdit: 1,
      rpDelete: 1,
      rpView: 1
    },
    {
      usrLoginid: null,
      usrId: null,
      usrFirstName: null,
      usrLastName: null,
      pageName: "Product",
      pageParentId: 5,
      pageId: 13,
      pageDesc: "product",
      rpAdd: 1,
      rpEdit: 1,
      rpDelete: 1,
      rpView: 1
    },
    {
      usrLoginid: null,
      usrId: null,
      usrFirstName: null,
      usrLastName: null,
      pageName: "Product Deviation",
      pageParentId: 5,
      pageId: 14,
      pageDesc: "deviation",
      rpAdd: 1,
      rpEdit: 1,
      rpDelete: 1,
      rpView: 1
    },
    {
      usrLoginid: null,
      usrId: null,
      usrFirstName: null,
      usrLastName: null,
      pageName: "Product DP",
      pageParentId: 5,
      pageId: 15,
      pageDesc: "ProductDRP",
      rpAdd: 1,
      rpEdit: 1,
      rpDelete: 1,
      rpView: 1
    },
    {
      usrLoginid: null,
      usrId: null,
      usrFirstName: null,
      usrLastName: null,
      pageName: "Dashboard",
      pageParentId: 5,
      pageId: 32,
      pageDesc: "Home",
      rpAdd: 1,
      rpEdit: 1,
      rpDelete: 1,
      rpView: 1
    },
    {
      usrLoginid: null,
      usrId: null,
      usrFirstName: null,
      usrLastName: null,
      pageName: "Dashboard SE",
      pageParentId: 5,
      pageId: 46,
      pageDesc: "Home3",
      rpAdd: 1,
      rpEdit: 1,
      rpDelete: 1,
      rpView: 1
    },
    {
      usrLoginid: null,
      usrId: null,
      usrFirstName: null,
      usrLastName: null,
      pageName: "Dashboard SM",
      pageParentId: 5,
      pageId: 47,
      pageDesc: "Home4",
      rpAdd: 1,
      rpEdit: 1,
      rpDelete: 1,
      rpView: 1
    }
  ],
  Report: [
    {
      usrLoginid: null,
      usrId: null,
      usrFirstName: null,
      usrLastName: null,
      pageName: "Distributor Dashboard",
      pageParentId: 7,
      pageId: 33,
      pageDesc: "distributor-report",
      rpAdd: 1,
      rpEdit: 1,
      rpDelete: 1,
      rpView: 1
    },
    {
      usrLoginid: null,
      usrId: null,
      usrFirstName: null,
      usrLastName: null,
      pageName: "Incentive Report",
      pageParentId: 7,
      pageId: 34,
      pageDesc: "distributor-incentive-report",
      rpAdd: 1,
      rpEdit: 1,
      rpDelete: 1,
      rpView: 1
    },
    {
      usrLoginid: null,
      usrId: null,
      usrFirstName: null,
      usrLastName: null,
      pageName: "Progression Report",
      pageParentId: 7,
      pageId: 35,
      pageDesc: "progressionreport1",
      rpAdd: 1,
      rpEdit: 1,
      rpDelete: 1,
      rpView: 1
    },
    {
      usrLoginid: null,
      usrId: null,
      usrFirstName: null,
      usrLastName: null,
      pageName: "Stock Summary Report",
      pageParentId: 7,
      pageId: 36,
      pageDesc: "stock-summary-report",
      rpAdd: 1,
      rpEdit: 1,
      rpDelete: 1,
      rpView: 1
    }
  ],
  Upload: [
    {
      usrLoginid: null,
      usrId: null,
      usrFirstName: null,
      usrLastName: null,
      pageName: "Upload Sales Data",
      pageParentId: 6,
      pageId: 20,
      pageDesc: "uploadedSalesData",
      rpAdd: 1,
      rpEdit: 1,
      rpDelete: 1,
      rpView: 1
    },
    {
      usrLoginid: null,
      usrId: null,
      usrFirstName: null,
      usrLastName: null,
      pageName: "Upload Primary Sales",
      pageParentId: 6,
      pageId: 21,
      pageDesc: "uploaded-dist-product-purchase-data",
      rpAdd: 1,
      rpEdit: 1,
      rpDelete: 1,
      rpView: 1
    },
    {
      usrLoginid: null,
      usrId: null,
      usrFirstName: null,
      usrLastName: null,
      pageName: "Upload Sales Return Data",
      pageParentId: 6,
      pageId: 22,
      pageDesc: "uploadedSalesReturnData",
      rpAdd: 1,
      rpEdit: 1,
      rpDelete: 1,
      rpView: 1
    },
    {
      usrLoginid: null,
      usrId: null,
      usrFirstName: null,
      usrLastName: null,
      pageName: "Distributor Activity List",
      pageParentId: 6,
      pageId: 30,
      pageDesc: "activity-list",
      rpAdd: 1,
      rpEdit: 1,
      rpDelete: 1,
      rpView: 1
    },
    {
      usrLoginid: null,
      usrId: null,
      usrFirstName: null,
      usrLastName: null,
      pageName: "Upload Activity List",
      pageParentId: 6,
      pageId: 31,
      pageDesc: "activity-list-import",
      rpAdd: 1,
      rpEdit: 1,
      rpDelete: 1,
      rpView: 1
    },
    {
      usrLoginid: null,
      usrId: null,
      usrFirstName: null,
      usrLastName: null,
      pageName: "File Upload Status",
      pageParentId: 6,
      pageId: 52,
      pageDesc: "FileUploadStatus",
      rpAdd: 1,
      rpEdit: 1,
      rpDelete: 1,
      rpView: 1
    }
  ]
};

const initialState = {
  // dashboardOptions: mockdata,
  // sideBarOptions: sideBarOptionsMockData
  dashboardOptions: [],
  sideBarOptions: []
};

export function permissionsReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_DASHBOARD_PERMISSIONS:
      return { ...state, dashboardOptions: action.payload };

    case ADD_SIDEBAR_PERMISSIONS:
      return { ...state, sideBarOptions: action.payload };

    case REHYDRATE_DASHBOARD_PERMISSIONS:
      return { ...state, dashboardOptions: action.payload };

    case REHYDRATE_SIDEBAR_PERMISSIONS:
      return { ...state, sideBarOptions: action.payload };
    default:
      return state;
  }
}
