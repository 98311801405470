import React, { useEffect, useState } from "react";
import ReactTable from "react-table";
import { tableColumHeaders } from "./constants";
import { getFileUploadedByUsr } from "./apis";
import { connect } from "react-redux";
import { getValue } from "../../../common/helpers/validations";

let FileUploadStatus = props => {
    let { userId } = props;
    const [fileUploadData, setFileUploadData] = useState([]);

    useEffect(() => {
        getFileUploadedByUsr(userId).then(data => {
            
            setFileUploadData(data[0]);
        });
    }, []);
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-12 mt-20">
                    <div className="panel panel-default">
                        <div className="panel-body table-responsive">
                            <div className="panel-title text-left">File Upload Status </div>
                            <ReactTable
                                data={fileUploadData}
                                columns={tableColumHeaders()}
                                filterable
                                minRows={1}
                                defaultPageSize={10}
                                noDataText={"No Data"}
                                showPageSizeOptions={true}
                                defaultFilterMethod={filterCaseInsensitive}
                                defaultSorted={[{ id: "batchId", desc: true }]}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        userId: getValue(state, ["loginReducer", "userId"])
    };
};

function filterCaseInsensitive(filter, row) {
	
    const id = filter.pivotId || filter.id;
    return (
        row[id] !== undefined ?
            String(row[id].toString().toLowerCase()).indexOf(filter.value.toLowerCase()) > -1
            :
            true
    );
}

export default FileUploadStatus = connect(mapStateToProps)(FileUploadStatus);
