import React, { useEffect, useState } from "react";
import { Field, withFormik } from "formik";
import { InputBox } from "../../../../common/components/InputBox";
import Select from "../../../../common/components/Select";
import schema from "./editProductViewFields.json"
import { findProductById, saveOrUpdateProduct } from "./api";
import { useLocation, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import Notification from '../../../../common/notification'
import { getValue, required, requiredField } from "../../../../common/helpers/validations";
import { DisappearedLoading } from 'react-loadingg';

import { getCompany, getAppLevel1, getAppLevel2, getProductGroup, getUom, getSteeringUName } from "../../../../services/staticdata";


const formikEnhancer = withFormik({

    mapPropsToValues: ({ user, props }) => ({
        ...user,
        ...props
    }),
    handleSubmit: (payload, { setSubmitting, props }) => {
        setSubmitting(true);
        saveOrUpdateProduct(payload).then(resp => {
            //console.log("savedData", resp);
            if (resp.status === 200) {
                Notification.success('Saved Successful.');
                props.history.goBack()
            }
            else {
                Notification.error('Error while saving data:' + resp.data.status_response);
            }
            setSubmitting(false);
        })

    },
    displayName: "MyForm"
});

let EditProductView = (props) => {

    let { userId, userCompanyId, errorMsg } = props;
    const { handleSubmit, setFieldValue, setValues, values, isSubmitting } = props;

    const [company, setCompany] = useState([]);
    const [filterCompany, setFilterCompany] = useState([]);
    const [appLevel1, setAppLevel1] = useState([]);
    const [appLevel2, setAppLevel2] = useState([]);
    const [productGroup, setProductGroup] = useState([]);
    const [uom, setUom] = useState([]);
    const [steeringUName, setSteeringUName] = useState([]);

    const initData = {
        productApplevel1: 0,
        productApplevel2: 0,
        productApplevel3: 0,
        productCompanyid: 0,
        productCrDt: 0,
        productCrUid: userId,
        productGroup: 0,
        productIbcode: 0,
        productId: null,
        productIdh: "",
        productMinOrdQty: 0,
        productMrp: "",
        productName: "",
        productSu: 0,
        productUom: 0,
        productUpDt: 0,
        productUpUid: 0,
    }

    async function loadStaticData() {
        setCompany(await getCompany());
        setAppLevel1(await getAppLevel1());
        setAppLevel2(await getAppLevel2());
        setProductGroup(await getProductGroup());
        setUom(await getUom());
        setSteeringUName(await getSteeringUName());
    }

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    const productId = useQuery().get("id");
    const readOnly = useQuery().get("read");

    const history = useHistory()

    const handleCancle = () => {
        history.goBack()
    };

    let filteredCompany = company.filter(function (e) {
        return e.key == userCompanyId;
    });

    useEffect(() => {
        loadStaticData();

        if (productId) {
            findProductById(productId).then(data => {
                setValues(data[0]);
            });
        }
        else {
            setValues(initData);
        }
    }, []);


    return (

        <div className="container-fluid">
            <div className="row">
                <div className="col-md-12 mt-20">
                    <div className="panel panel-default">
                        <div className={readOnly === "true" ? "panel-body disabled" : "panel-body"}>
                            <div className="panel-title text-left">Product
                              <button
                                    className="btn btn-cancle"
                                    style={{ float: "right" }}
                                    type="button btn"
                                    onClick={handleCancle}>
                                    {"Cancel"}
                                </button>
                                <button
                                    className={isSubmitting ? " btn btn-success btn-save disabled" : "btn btn-save btn-success"}
                                    style={{ float: "right" }}
                                    type="submit"
                                    onClick={handleSubmit}>
                                    {"Save"}
                                </button>
                            </div>
                            <div className="editpanel">
                                <form onSubmit={handleSubmit} /*className={isSubmitting ? "disabled" : ""}*/>
                                    {isSubmitting ?
                                        <DisappearedLoading color="#5f6973" />
                                        : ("")}
                                    {schema.map((item, index) => {
                                        return (
                                            <div key={index} >
                                                {item.ObjectType === "Textbox" ? (
                                                    <div className={item.Editable === "No" ? "col-md-12 display-flex disabled" : "col-md-12 display-flex"}>
                                                        <label className="form_label w-30">
                                                            {item.FieldName} {item.Mandatory && <span className="error">* </span>}
                                                        </label>
                                                        <div className=" form_input w-70">
                                                            <Field
                                                                name={item.backendMappingName}
                                                                component={InputBox}
                                                                type={"text"}
                                                                validate={item.Mandatory && required.bind(this, item.FieldName)}
                                                                placeholder={item.FieldName}
                                                                value={values[item.backendMappingName] || ''}
                                                            /> </div>
                                                    </div>
                                                ) : (
                                                        <div className="col-md-12  display-flex">
                                                            <label className="form_label w-30">
                                                                {item.FieldName} {item.Mandatory && <span className="error">* </span>}
                                                            </label>
                                                            <div className=" form_input w-70">
                                                                <Select
                                                                    id={item.backendMappingName}
                                                                    name={item.backendMappingName}
                                                                    value={values[item.backendMappingName] || ''}
                                                                    options={eval(item.Source)}
                                                                    onChange={(field, value) => {
                                                                        setFieldValue(field, value);
                                                                    }}
                                                                    validate={item.Mandatory && requiredField(values[item.backendMappingName])}
                                                                />
                                                            </div>
                                                        </div>
                                                    )}
                                            </div>
                                        );
                                    })}
                                </form>
                                <div className="col-md-12  display-flex">
                                    <button
                                        className={isSubmitting ? " btn btn-success btn-save disabled" : "btn btn-save btn-success"}
                                        type="submit"
                                        onClick={handleSubmit}>
                                        {"Save"}
                                    </button>
                                    <button
                                        className="btn btn-cancle"
                                        type="button btn"
                                        onClick={handleCancle}>
                                        {"Cancel"}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        userId: getValue(state, ["loginReducer", "userId"]),
        userCompanyId: getValue(state, ["loginReducer", "userCompanyId"])
    };
};

const EditProductViews = connect(mapStateToProps)(formikEnhancer(EditProductView))

export default EditProductViews;






