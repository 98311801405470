import { Modal, ModalTitle } from 'react-bootstrap';
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import { getValue } from "../../../../common/helpers/validations";
import Select from "../../../../common/components/Select";
import { fileUpload } from "./api";
import { findDistributorListAPI } from "../../../../services/api";
import { Link } from "react-router-dom";
import Notification from '../../../../common/notification'
import moment from 'moment';


let CustomerUpload = props => {
    const [distributorList, setDistributorList] = useState(null);
    const [respId, setrespId] = useState(false);
    const [formSubmitting, setformSubmitting] = useState(false);
    const [formSubmitted, setformSubmitted] = useState(false);
    let { userId } = props;

    const { show, closeModal } = props;

    useEffect(() => {
        findDistributorListAPI(userId).then(data => {
            let newDistArray = data.map(i => {
                let option = { key: i.distId, value: i.distDistName }
                return option
            })
            setDistributorList(newDistArray);
        })
    }, []);

    return (
        <Modal show={show} onHide={closeModal}>
            <Modal.Header>
                <ModalTitle>
                    BULK CUSTOMER UPLOAD
                    <button onClick={closeModal} className="close" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </ModalTitle>
            </Modal.Header>
            <Modal.Body>
                <div className="row">

                    <Formik initialValues={{
                        file: null, inputJson: null,
                    }} onSubmit={values => {
                        let inputJson = JSON.stringify({ "type": "bulkCustomerUpload", "distid": values.distid, "reportDate": "", "userid": userId });
                        //console.log("values", inputJson);
                        setformSubmitting(true);
                        fileUpload(values.file, inputJson).then(response => {
                            if (response.data.batchId) {
                                Notification.success('Uploaded Successful.');
                                setformSubmitted(true);
                                setrespId(response.data.batchId);
                                setformSubmitting(true);
                            }
                            else {
                                setformSubmitting(false);
                            }
                        }).catch(err => {
                            setformSubmitting(false);
                            return err;
                        })
                    }}
                    >

                        {({ handleSubmit, values, setFieldValue }) => (

                            <form className={formSubmitting ? "disabled" : ""} onSubmit={handleSubmit}>

                                <div className="col-md-12 mt-15">
                                    <div className="col-md-4">
                                        <label htmlFor="distid" style={{ display: "block" }}>
                                            Distributor Name
                                    </label>
                                    </div>
                                    <div className="col-md-8">

                                        <Select
                                            id="distid"
                                            value={values.distid}
                                            options={distributorList}
                                            onChange={(field, value) => {
                                                setFieldValue("distid", value);
                                            }}
                                        />

                                    </div>
                                </div>

                                <div className="col-md-12 mt-15">
                                    <div className="col-md-4">
                                        <label htmlFor="file" style={{ display: "block" }}>
                                            File
                                    </label>
                                    </div>
                                    <div className="col-md-8">
                                        <input
                                            id={"file"}
                                            type="file"
                                            onChange={(e, value) => {
                                                setFieldValue("file", e.target.files[0]);
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="col-md-12 mt-20 mb-20">
                                    <div className="col-md-4">
                                    </div>
                                    <div className="col-md-8">
                                        <button type="submit" className={" btn btn-info btn-fileupload " + (formSubmitting && "disabled")}>Upload</button>
                                        <button type="button" className="btn btn-filecancle" onClick={closeModal}>Cancel</button>
                                    </div>
                                </div>


                            </form>
                        )}
                    </Formik>

                    {respId && (
                        <Link to="/fileUploadStatus">
                            <div className="alert alert-success cursor mt-15" role="alert" style={{ clear: "both", marginLeft: "15px", width: "95%" }}>
                                {'Customer data upload job is submitted. Click here to check the status and copy batch id.'}
                                <strong id="batchId">{respId}</strong>
                            </div>
                        </Link>
                    )}

                </div>
            </Modal.Body>
        </Modal>

    );
};
const mapStateToProps = state => {
    return {
        userId: getValue(state, ["loginReducer", "userId"])
    };
};


export default CustomerUpload = connect(
    mapStateToProps
)(CustomerUpload);