import { post } from "axios";
import { BASE_URL } from '../../../../common/helpers/urlsConstants';




export const getRoleList = async (tokenId) => {

    tokenId = sessionStorage.getItem("tokenId")
    var token = tokenId.replace(/['"]+/g, '');
    const authData = {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${token}`
        }
    };
    const url = `${BASE_URL}/getRoleList`;
    return fetch(url, authData).then(response => response.json()).then(resp => {
   
        if (!resp) {
            return [];
        } else {
            return [resp];
        }
    })
};

export const getUserRolePage = async (roleID, tokenId) => {
    tokenId = sessionStorage.getItem("tokenId")
    var token = tokenId.replace(/['"]+/g, '');
    const authData = {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${token}`
        }
    };
    const url = `${BASE_URL}/getUserRolePage?roleID=${roleID}`;
    return fetch(url, authData).then(response => response.json()).then(resp => {
        if (!resp) {
            return [];
        } else {
            return [resp];
        }
    })
};


export const saveOrUpdateUserRolePage = async (data, tokenId) => {
   // debugger;
    tokenId = sessionStorage.getItem("tokenId")
    var token = tokenId.replace(/['"]+/g, '');
    const authData = {
        method: "POST",
        headers: {
            "Authorization": `Bearer ${token}`,
            "accept": "*/*",
            "Content-Type": "application/json",
        }
    };

    const url = `${BASE_URL}/mergeUserRolePage`;

    return post(url, data, authData);
};



