import { post } from 'axios';
import { BASE_URL } from "../../../../common/helpers/urlsConstants";



export const forgotPassword = async (user, tokenId) => {
    //tokenId = sessionStorage.getItem("tokenId")
    //var token = tokenId.replace(/['"]+/g, '');
    const authData = {
        method: "POST",
    };
    const url = `${BASE_URL}/forgotPassword?user=${user}`;
    return post(url, authData);
};


//export const forgotPassword = async user => {
//    
//    const url = `${BASE_URL}/forgotPassword?user=${user}`;
//    return post(url);
//};

