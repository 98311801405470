import React, { useState } from "react";
import { BrowserRouter, Link } from 'react-router-dom';
import { connect } from "react-redux";
import ChangePassword from "../../../../pages/sideBarNavPages/master/changePassword";
import { Layout, Menu } from "antd";

let CustomHeader = props => {

    const { isLoggedIn, fullName, userId } = props.userDetails || {};
    const [showDropdown, setShowDropdown] = useState(false);
    const [supportDropdown, setSupportDropdown] = useState(false);
    const [show, setShow] = useState(false);
    const { Header } = Layout;

    const handleClick = () => {
        setShow(true);
        setShowDropdown(!showDropdown);

    }

    const openModal = () => setShow(true);
    const closeModal = () => setShow(false);
    //console.log("props.userDetails", props.userDetails);

    return (
        <React.Fragment>
            {isLoggedIn && (
                <Header id="top">
                        <a  href='/dashboard'>
                            <img src={"/img/henkel-logo.png"} alt="logo-img" style={{ height: "40px" ,width: "100px", margin: "5px 0px 0px -10px" }} />
                        </a>

                    <ul className="top-right">
                        <li className="dropdown link">
                            <a onClick={() => setShowDropdown(!showDropdown)} data-toggle="dropdown" className="dropdown-toggle profilebox" >
                                <b>{`${fullName}`}</b>
                                <span className="caret"></span>
                            </a>
                            <ul className={`dropdown-menu dropdown-menu-list dropdown-menu-right ${showDropdown && "show"}`} >
                                <li>
                                    <Link to={`/user/editUser/?id=${userId}&read=true`}>
                                        <span className="glyphicon glyphicon-user" style={{ margin: "0px 8px 0px -8px" }}></span>My Profile
                                    </Link>

                                </li>
                                <li>
                                    <Link to="#" onClick={handleClick}>
                                        <span className="glyphicon glyphicon-th" style={{ margin: "0px 5px 0px -8px" }}></span> Change Password
                                    </Link>
                                    <ChangePassword closeModal={closeModal} show={show} />
                                </li>
                                <li onClick={() => {
                                    sessionStorage.removeItem('userData')
                                }}>
                                    <a href='/login'>
                                        <span className="glyphicon glyphicon-off" style={{ margin: "0px 4px 0px -8px" }}></span> Logout
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>


                    <span className="glyphicon glyphicon-home top-right">
                        <a className="home-btn" href='/dashboard'>Home</a>
                    </span>

                    <ul className="top-right">
                        <li className="dropdown link">
                            <a onClick={() => setSupportDropdown(!supportDropdown)} data-toggle="dropdown" className="dropdown-toggle profilebox" style={{ marginTop: "-2px" }}>
                                <span className="glyphicon glyphicon-wrench" ><b className="home-btn"> Support </b></span>
                                <span className="caret"></span>
                            </a>
                            <ul className={`dropdown-menu dropdown-menu-list dropdown-menu-right ${supportDropdown && "show"}`} >

                                <li>
                                    <Link to='/ticket' onClick={() => setSupportDropdown(!supportDropdown)}>
                                        <span className="glyphicon glyphicon-exclamation-sign" style={{ margin: "0px 4px 0px -8px" }}></span> Ticket
                                     </Link>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </Header>
            )}
        </React.Fragment>
    );
};
const mapStateToProps = state => {
    //! we will need this later
    return {
        userDetails: state.loginReducer
    };
};

export default CustomHeader = connect(mapStateToProps)(CustomHeader);
