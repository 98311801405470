import React, { useEffect, useState } from "react";
import { Field, withFormik } from "formik";
import { InputBox } from "../../../../common/components/InputBox";
import Select from "../../../../common/components/Select";
import schema from "./editDeviationViewFields.json"
import { findProductList, findDeviationById, saveOrUpdateDeviation, getCustomerList } from "./api";
import { findDistributorListAPI } from "../../../../services/api";
import { useLocation, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import Notification from '../../../../common/notification'
import { getValue, required, requiredField } from "../../../../common/helpers/validations";
import { DatePicker } from "antd";
import moment from 'moment';
import { DisappearedLoading } from 'react-loadingg';



const formikEnhancer = withFormik({

    mapPropsToValues: ({ user, props }) => ({
        ...user,
        ...props
    }),
    handleSubmit: (payload, { setSubmitting, props }) => {
        setSubmitting(true);
        saveOrUpdateDeviation(payload).then(resp => {
            //console.log("savedData", resp);
            if (resp.status === 200) {
                Notification.success('Saved Successful.');
                props.history.goBack()
            }
            else {
                Notification.error('Error while saving data:' + resp.data.status_response);
            }
            setSubmitting(false);
        })

    },
    displayName: "MyForm"
});

let EditDeviationView = (props) => {
    let { userId, userCompanyId } = props;
    const { handleSubmit, setFieldValue, setValues, values, isSubmitting } = props;
    const [distributorList, setDistributorList] = useState(null);
    const [customerList, setCustomerList] = useState(null);
    const [productList, setproductList] = useState(null);


    const initData = {
        stdCrUdt: null,
        stdCrUid: userId,
        stdCustId: "",
        stdDistId: 0,
        stdFromDt: "",
        stdId: 0,
        stdProductId: "",
        stdToDt: "",
        stdUpDt: 0,
        stdUpUid: 0,
        stdValue: ""
    }

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    const deviationId = useQuery().get("id");
    const readOnly = useQuery().get("read");


    const history = useHistory()
    const handleCancle = () => {
        history.goBack()
    };

    const selectOnChange = (field, value) => {
        setFieldValue(field, value);
        getcustomers(value);
    };

    function getcustomers(id) {
        getCustomerList(id).then(data => {
            let newCustomerArray = data.map(i => {
                let option = { key: i.custid, value: i.custname + " " + i.custcode }
                return option
            })
            setCustomerList(newCustomerArray);
        })
    }

    useEffect(() => {

        if (deviationId) {
            
            findDeviationById(deviationId).then(data => {
               
                setValues(data[0]);
                getcustomers(data[0].stdDistId);
            });
        }
        else {
            setValues(initData);
        }

        findDistributorListAPI(userId).then(data => {
            
            let newDistArray = data.map(i => {
                let option = { key: i.distId, value: i.distIdhCode + " " + i.distDistName }
                return option
            })
            setDistributorList(newDistArray);
        })

        findProductList(userCompanyId).then(data => {
            
            let newProductArray = data.map(i => {
                let option = { key: i.productId, value: i.productIDH + " " + i.name }
                return option
            })
            setproductList(newProductArray);
        })

    }, []);


    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-12 mt-20">
                    <div className="panel panel-default">
                        <div className={readOnly === "true" ? "panel-body disabled" : "panel-body"}>
                            <div className="panel-title text-left">Deviation
                             <button
                                    className="btn btn-cancle"
                                    style={{ float: "right" }}
                                    type="button btn"
                                    onClick={handleCancle}>
                                    {"Cancel"}
                                </button>
                                <button
                                    className={isSubmitting ? " btn btn-success btn-save disabled" : "btn btn-save btn-success"}
                                    style={{ float: "right" }}
                                    type="submit"
                                    onClick={handleSubmit}>
                                    {"Save"}
                                </button>
                            </div>
                            <div className="editpanel">
                                <form onSubmit={handleSubmit}>
                                    {isSubmitting ?
                                        <DisappearedLoading color="#5f6973" />
                                        : ("")}
                                    {schema.map((item, index) => {
                                        return (
                                            <div key={index} >
                                                {item.ObjectType === "Textbox" ? (
                                                    <div className={item.Editable === "No" ? "col-md-12 display-flex disabled" : "col-md-12 display-flex"}>
                                                        <label className="form_label w-30">
                                                            {item.FieldName} {item.Mandatory && <span className="error">* </span>}
                                                        </label>
                                                        {item.backendMappingName === "stdFromDt" || item.backendMappingName === "stdToDt" ? (
                                                            <div className="form_input">
                                                                <DatePicker
                                                                    id={item.backendMappingName}
                                                                    placeholder="Select Date"
                                                                    value={values[item.backendMappingName] ? moment(values[item.backendMappingName]) : moment()}
                                                                    onChange={
                                                                        item.backendMappingName === "stdFromDt" ? ((field, value) => {
                                                                            setFieldValue("stdFromDt", value);
                                                                        }) : ((field, value) => {
                                                                            setFieldValue("stdToDt", value);
                                                                        })}
                                                                    validate={item.Mandatory && required.bind(this, item.FieldName)}
                                                                />
                                                            </div>
                                                        ) : (
                                                                <div className="form_input">
                                                                    <Field
                                                                        name={item.backendMappingName}
                                                                        component={InputBox}
                                                                        type={"text"}
                                                                        validate={item.Mandatory && required.bind(this, item.FieldName)}
                                                                        placeholder={item.FieldName}
                                                                        value={values[item.backendMappingName] || ''}
                                                                    />

                                                                </div>

                                                            )}
                                                    </div>
                                                ) : (
                                                        <div className="col-md-12  display-flex">
                                                            <label
                                                                className="form_label w-30"
                                                                htmlFor={item.backendMappingName}
                                                                style={{ display: "block" }}
                                                            >
                                                                {item.FieldName} {item.Mandatory && <span className="error">* </span>}
                                                            </label>
                                                            <div className="form_input">
                                                                <Select
                                                                    id={item.backendMappingName}
                                                                    name={item.backendMappingName}
                                                                    value={values[item.backendMappingName] || ''}
                                                                    options={eval(item.Source)}
                                                                    onChange={item.backendMappingName === "stdDistId" ? (field, value) => selectOnChange(field, value)
                                                                        : ((field, value) => {
                                                                            setFieldValue(field, value);
                                                                        })}
                                                                    validate={item.Mandatory && requiredField.bind(this, values[item.backendMappingName], item.FieldName)}
                                                                />
                                                            </div>
                                                        </div>
                                                    )}

                                            </div>
                                        );
                                    })}
                                </form>
                                <div className="col-md-12  display-flex">
                                    <button
                                        className={isSubmitting ? " btn btn-success btn-save disabled" : "btn btn-save btn-success"}
                                        type="submit"
                                        onClick={handleSubmit}>
                                        {"Save"}
                                    </button>
                                    <button
                                        className="btn btn-cancle"
                                        type="button btn"
                                        onClick={handleCancle}>
                                        {"Cancel"}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        userId: getValue(state, ["loginReducer", "userId"]),
        userCompanyId: getValue(state, ["loginReducer", "userCompanyId"])
    };
};

const EditDeviationViews = connect(mapStateToProps)(formikEnhancer(EditDeviationView))

export default EditDeviationViews;



